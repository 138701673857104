import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {
    fetchProductRelation,
    createProductRelation,
    updateProductRelation,
    deleteProductRelation
} from "../../../actions/quiz/productrelations";
import ConfirmButton from "../../buttons/ConfirmButton";
import Select from "react-select";


class ProductRelationEditCreateModal extends React.Component {

    static propTypes = {
        productRelation: PropTypes.any,
        fetchProductRelation: PropTypes.func,
        createProductRelation: PropTypes.func,
        updateProductRelation: PropTypes.func,
        deleteProductRelation: PropTypes.func,
        products: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            productRelation: props.productRelation,
            message: undefined,
        }
    }

    componentDidMount() {
        if (this.props.productRelation.id) {
            this.props.fetchProductRelation(this.props.productRelation);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {productrelations: {detail, isFetching, isCreating, isDeleting, isUpdating, message, error, retry}} = this.props;

        if (!isFetching && prevProps.productrelations.isFetching && !error && !retry) {
            this.setState({productRelation: detail});
        }
        if (!isUpdating && prevProps.productrelations.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.productrelations.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.productrelations.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.productrelations.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.productrelations.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleSaveProductRelation = () => {
        if (this.state.productRelation.id) {
            this.props.updateProductRelation(this.state.productRelation);
        } else {
            this.props.createProductRelation({...this.state.productRelation});
        }
    };

    handleDeleteProductRelation = () => {
        if (this.state.productRelation.id) {
            this.props.deleteProductRelation(this.state.productRelation);
        }
    };

    handleProductRelationChange = (productRelation) => {
        this.setState({productRelation});
    };

    render() {
        let {show, products, handleClose} = this.props;
        let {productRelation, message} = this.state;

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Relation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Display Product</Form.Label>
                        <Select options={products.list.map(p => ({label: `${p.product_id} - ${p.title}`, value: p.id}))}
                                className="bot-field-item-select"
                                value={products.list.map(p => ({
                                    label: p.product_id,
                                    value: p.id
                                })).filter(p => p.value == productRelation.display_product)}
                                onChange={(obj) => this.handleProductRelationChange({
                                    ...productRelation,
                                    display_product: obj.value,
                                })}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.display_product}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.display_product}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Related Product</Form.Label>
                        <Select options={products.list.map(p => ({label: `${p.product_id} - ${p.title}`, value: p.id}))}
                                className="bot-field-item-select"
                                value={products.list.map(p => ({
                                    label: p.product_id,
                                    value: p.id
                                })).filter(p => p.value == productRelation.related_product)}
                                onChange={(obj) => this.handleProductRelationChange({
                                    ...productRelation,
                                    related_product: obj.value,
                                })}/>
                        <Form.Control type="hidden" isInvalid={!!message && !!message.related_product}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.related_product}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text"
                                      value={productRelation.title}
                                      onChange={(e) => this.setState({
                                          productRelation: {
                                              ...productRelation,
                                              title: e.target.value
                                          }
                                      })}
                                      isInvalid={!!message && !!message.title}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.title}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      value={productRelation.description}
                                      onChange={(e) => this.setState({
                                          productRelation: {
                                              ...productRelation,
                                              description: e.target.value
                                          }
                                      })}
                                      isInvalid={!!message && !!message.description}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.description}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveProductRelation}>Save</Button>
                    {
                        this.state.productRelation.id &&
                        <ConfirmButton onClick={this.handleDeleteProductRelation}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    products: state.quiz.products,
    productrelations: state.quiz.productrelations,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchProductRelation,
    createProductRelation,
    updateProductRelation,
    deleteProductRelation,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ProductRelationEditCreateModal);
