import {call, put} from 'redux-saga/effects';
import {
    CREATE_PRODUCT_RESULT,
    CREATE_PRODUCT_ERROR,
    CREATE_PRODUCT_RETRY,
    FETCH_PRODUCTS_RESULT,
    FETCH_PRODUCTS_ERROR,
    FETCH_PRODUCTS_RETRY,
    FETCH_PRODUCT_RESULT,
    FETCH_PRODUCT_ERROR,
    FETCH_PRODUCT_RETRY,
    UPDATE_PRODUCT_RESULT,
    UPDATE_PRODUCT_ERROR,
    UPDATE_PRODUCT_RETRY,
    DELETE_PRODUCT_RESULT,
    DELETE_PRODUCT_ERROR,
    DELETE_PRODUCT_RETRY
} from '../../actions/quiz/products';
import api from "../api";
import {toast} from "react-toastify";


export function* createProduct(action) {
    try {
        const response = yield call(api.quiz.products.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createProduct', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_PRODUCT_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_PRODUCT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_PRODUCT_RETRY, retry: true, message: e.message});
    }
}


export function* fetchProducts(action) {
    try {
        const response = yield call(api.quiz.products.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchProducts', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PRODUCTS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_PRODUCTS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PRODUCTS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchProduct(action) {
    try {
        const response = yield call(api.quiz.products.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchProduct', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PRODUCT_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_PRODUCT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PRODUCT_RETRY, retry: true, message: e.message});
    }
}


export function* updateProduct(action) {
    try {
        const response = yield call(api.quiz.products.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateProduct', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_PRODUCT_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_PRODUCT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_PRODUCT_RETRY, retry: true, message: e.message});
    }
}


export function* deleteProduct(action) {
    try {
        const response = yield call(api.quiz.products.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteProduct', action);

        if (response.status === 204) {
            yield put({type: DELETE_PRODUCT_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_PRODUCT_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_PRODUCT_RETRY, retry: true, message: e.message});
    }
}
