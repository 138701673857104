import {takeEvery} from 'redux-saga/effects';

import {USER_LOGIN, UPDATE_USER_PROFILE, FETCH_USER_PROFILE} from '../../actions/accounts/profile';
import {userLogin, updateUserProfile, fetchUserProfile} from './profile';


export default [
    takeEvery(USER_LOGIN, userLogin),
    takeEvery(UPDATE_USER_PROFILE, updateUserProfile),
    takeEvery(FETCH_USER_PROFILE, fetchUserProfile),
];
