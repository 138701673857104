/* global require */

export const DEBUG = process.env.REACT_APP_DEBUG == "true";
export const HOST = process.env.REACT_APP_HOST;
export const NODE_SERVER_HOST = process.env.REACT_APP_NODE_SERVER_HOST;
export const WEBSITE_HOST = "https://misters.in";


export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const getMessages = (message) => {
    let messages = [];
    if (typeof message === 'string' && message.length > 0) {
        messages.push(message);
    }
    if (typeof message === 'object' && message) {
        let objectMessages = Object.values(message);
        objectMessages.map(
            msg => {
                if (typeof msg === "string") {
                    messages = [...messages, msg];
                }
                else {
                    messages = [...messages, ...msg];
                }
            }
        );
    }
    return messages;
};

export const getRupeeFromPaisa = (paisa) => parseFloat(paisa) / 100;

export const fieldTypes = {
    TEXT: 'text',
    EMAIL: 'email',
    INTEGER: 'integer',
    FLOAT: 'float',
    BOOL: 'bool',
    FILE: 'file',
    CHOICE: 'choice',
    MULTIPLE_CHOICE: 'multiple_choice',
    READ_ONLY_TEXT: 'read_only_text',
    DATE: 'date',
};

export const documentTypes = {
    REPORT: 'report',
    PRESCRIPTION: 'prescription',
    VITAL: 'vital',
    NOTE: 'note',
};

export const Images = {
    iconNote: '/assets/images/icon_note.png',
    iconPrescription: '/assets/images/icon_prescription.png',
    iconReport: '/assets/images/icon_report.png',
    iconVital: '/assets/images/icon_vital.png',
    logo: '/assets/images/logo.jpeg',
    phone: '/assets/images/phone.png',
    shoppingCart: '/assets/images/shopping-cart.png',
    language: '/assets/images/internet.png',
    backArrow: '/assets/images/back-arrow.svg',
    user: '/assets/images/user.png',
    rupee: '/assets/images/rupee.png',
    emptyCart: '/assets/images/empty_cart.png',
    pdGraph: '/assets/images/pd.png',
    stamp: '/assets/images/stamp.png',
    closedBox: '/assets/images/closed_box.png',
    discreteBox: '/assets/images/discrete_box.png',
    bottles: '/assets/images/bottles.jpg',
    package: '/assets/images/package.png',
    freeDelivery: '/assets/images/free-delivery.png',
    stethoscope: '/assets/images/stethoscope.png',
    dayCalender: '/assets/images/day-fifteen-calendar.png',
    error404: '/assets/images/error404.png',
};

export const scrollToTop = (scrollDuration) => {
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
            scrollCount = scrollCount + 1;
            scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
            window.scrollTo(0, (scrollHeight - scrollMargin));
        } else clearInterval(scrollInterval);
    }, 15);
};

export const TEXT = 'text';
export const EMAIL = 'email';
export const INTEGER = 'integer';
export const FLOAT = 'float';
export const BOOL = 'bool';
export const FILE = 'file';
export const CHOICE = 'choice';
export const MULTIPLE_CHOICE = 'multiple_choice';
export const READ_ONLY_TEXT = 'read_only_text';
export const DATE = 'date';
export const LOADING = 'loading';
export const HEIGHT = 'height';
export const STAR = 'star';
export const RATING = 'rating';

export const FIELD_LABELS = {
    [TEXT]: 'Text',
    [EMAIL]: 'Email',
    [INTEGER]: 'Integer',
    [FLOAT]: 'Float',
    [BOOL]: 'Boolean',
    [FILE]: 'File',
    [CHOICE]: 'Choice',
    [MULTIPLE_CHOICE]: 'Multiple Choice',
    [READ_ONLY_TEXT]: 'Read Only Text',
    [DATE]: 'Date',
    [LOADING]: 'Loading',
    [HEIGHT]: 'Height',
    [STAR]: 'Star',
    [RATING]: 'Rating',
};

export const FIELD_TYPES = [
    {label: 'Text', value: TEXT},
    {label: 'Email', value: EMAIL},
    {label: 'Integer', value: INTEGER},
    {label: 'Float', value: FLOAT},
    {label: 'Boolean', value: BOOL},
    {label: 'File', value: FILE},
    {label: 'Choice', value: CHOICE},
    {label: 'Multiple Choice', value: MULTIPLE_CHOICE},
    {label: 'Read Only Text', value: READ_ONLY_TEXT},
    {label: 'Date', value: DATE},
    {label: 'Loading', value: LOADING},
    {label: 'Height', value: HEIGHT},
    {label: 'Star', value: STAR},
    {label: 'Rating', value: RATING},
];

export const languageOptions = [
    {label: 'English', value: 'english', code: 'en', db_label: ''},
    {label: 'Hindi', value: 'hindi', code: 'hi', db_label: '_hi'},
    {label: 'Bengali', value: 'bengali', code: 'be', db_label: '_be'},
    {label: 'Marathi', value: 'marathi', code: 'ma', db_label: '_ma'},
    {label: 'Tamil', value: 'tamil', code: 'ta', db_label: '_ta'},
    {label: 'Telugu', value: 'telugu', code: 'te', db_label: '_te'},
    {label: 'Gujarati', value: 'gujarati', code: 'gu', db_label: '_gu'},
];

export const analysisCardType = [
    {label: 'Total Score', value: 'total_score'},
    {label: 'Problem Score', value: 'problem_score'},
    {label: 'Desire Score', value: 'desire_score'},
    {label: 'BMI Score', value: 'bmi_score'},
    {label: 'Ayurvedic BMI Score', value: 'bmi2_score'},
    {label: 'Ejaculation Score', value: 'ejaculation_score'},
    {label: 'Lifestyle Score', value: 'lifestyle_score'},
    {label: 'Erection Score', value: 'erection_score'},
];


export const messageEventTypes = [
    {label: 'Abandoned Cart', value: 'abandoned_cart'},
    {label: 'Failed Online Payment', value: 'failed_online_payment'},
    {label: 'No action after verifying otp', value: 'no_action_after_verifying_otp'},
    {label: 'Purchase', value: 'purchase'},
    {label: 'Dispatch', value: 'dispatch'},
    {label: 'Receive', value: 'receive'},
];

export const messageTypes = [
    {label: 'SMS', value: 'sms'},
    {label: 'Promotional SMS', value: 'promotional_sms'},
    {label: 'Call', value: 'call'},
    {label: 'Email', value: 'email'},
    {label: 'NOTIFICATION', value: 'notification'},
];

export const messageUnitTypes = [
    {label: 'Min', value: 'min'},
    {label: 'Day', value: 'day'},
];

export const ctaTypes = [
    {label: 'Take Quiz', value: 'take_quiz'},
    {label: 'Shop Now', value: 'shop_now'},
];


export const zTableX = [-3.4, -3.3, -3.2, -3.1, -3, -2.9, -2.8, -2.7, -2.6, -2.5, -2.4, -2.3, -2.2, -2.1, -2, -1.9, -1.8, -1.7, -1.6, -1.5, -1.4, -1.3, -1.2, -1.1, -1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3, 3.1, 3.2, 3.3, 3.4];
