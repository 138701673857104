import {HOST} from '../constants'
import makeRequest from "./makeRequest";

export {HOST};


export const jsonToForm = (data) => {
    const form = new FormData();
    const keys = Object.keys(data);
    const values = Object.values(data);

    keys.map((key, i) => {
        form.append(key, values[i])
    });
    return form;
};


export const addFileToForm = ({form, key, file}) => {
    // form.append(key, {
    //     uri: file.uri,
    //     name: file.name,
    //     type: file.type ? file.type: 'image/jpg',
    // });
    form.append(key, file, file.name);
    return form;
};


export default {
    accounts: {
        postUserLogin: data => (makeRequest(
            `${HOST}/api/v1/accounts/login/username/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        getUser: data => (makeRequest(
            `${HOST}/api/v1/accounts/profile/`,
            {method: 'get'}
        )),
        patchUser: data => (makeRequest(
            `${HOST}/api/v1/accounts/profile/`,
            {method: 'PATCH', body: jsonToForm(data)}
        )),
        requestOtp: data => (makeRequest(
            `${HOST}/api/v1/accounts/otp/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        tokenVerify: data => (makeRequest(
            `${HOST}/api/v1/accounts/token/verify/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        tokenRefresh: data => (fetch(
            `${HOST}/api/v1/accounts/token/refresh/`,
            {method: 'post', body: jsonToForm(data)}
        )),
    },
    cms: {
        reviews: {
            create: (data) => makeRequest(`${HOST}/api/v1/cms/reviews/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            list: () => makeRequest(`${HOST}/api/v1/cms/reviews/`),
            get: ({id}) => makeRequest(`${HOST}/api/v1/cms/reviews/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/cms/reviews/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(`${HOST}/api/v1/cms/reviews/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',}),
        },
        coupons: {
            create: (data) => makeRequest(`${HOST}/api/v1/cms/coupons/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            list: () => makeRequest(`${HOST}/api/v1/cms/coupons/`),
            get: ({id}) => makeRequest(`${HOST}/api/v1/cms/coupons/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/cms/coupons/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(`${HOST}/api/v1/cms/coupons/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',}),
        },
        productquantityoffers: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/cms/productquantityoffers/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            get: ({id}) => makeRequest(`${HOST}/api/v1/cms/productquantityoffers/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/cms/productquantityoffers/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/cms/productquantityoffers/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
    },
    quiz: {
        botForms: {
            clone: data => makeRequest(`${HOST}/api/v1/quiz/botforms/${data.id}/clone/`, {method: 'POST'}),
            create: data => makeRequest(`${HOST}/api/v1/quiz/botforms/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            list: data => makeRequest(`${HOST}/api/v1/quiz/botforms/`,),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/botforms/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/botforms/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(`${HOST}/api/v1/quiz/botforms/${data.id}/`, {
                method: 'PATCH',
                body: JSON.stringify(data),
                bodyType: 'json',
            }),
        },
        botFields: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/quiz/botfields/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            copy: (data) => makeRequest(
                `${HOST}/api/v1/quiz/botfields/copy/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            list: ({bot_form_id}) => makeRequest(`${HOST}/api/v1/quiz/botfields/?bot_form_id=${bot_form_id}`,),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/botfields/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/botfields/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/botfields/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        connections: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/quiz/connections/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            list: ({bot_form_id}) => makeRequest(`${HOST}/api/v1/quiz/connections/?bot_form_id=${bot_form_id}`,),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/connections/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/connections/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/connections/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        botfieldfiles: {
            create: ({file, name, bot_field, file_type, bot_form}) => {
                let form = jsonToForm({name, bot_field, file_type, bot_form});
                let body = addFileToForm({form, key: 'file', file});
                return makeRequest(
                    `${HOST}/api/v1/quiz/botfieldfiles/`,
                    {method: 'POST', body,}
                )
            },
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/botfieldfiles/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/botfieldfiles/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/botfieldfiles/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        expressions: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/quiz/expressions/create/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            list: ({bot_form_id = '', ...data}) => makeRequest(
                `${HOST}/api/v1/quiz/expressions/?bot_form_id=${bot_form_id}`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/expressions/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/expressions/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/expressions/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
            check: (data) => makeRequest(
                `${HOST}/api/v1/quiz/expressions/check/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            )
        },
        answers: {
            create: ({bot_field_id}) => makeRequest(
                `${HOST}/api/v1/quiz/answers/${bot_field_id}/create/`,
                {method: 'POST'}
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/answers/${data.id}/update/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        products: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/quiz/products/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            list: () => makeRequest(`${HOST}/api/v1/quiz/products/`,),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/products/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/products/${id}/`, {method: 'DELETE'}),
            update: ({image, ...data}) => {
                if (image && typeof image === 'object') {
                    let body = addFileToForm({form: jsonToForm({}), key: 'image', file: image});
                    return makeRequest(
                        `${HOST}/api/v1/quiz/products/${data.id}/`,
                        {method: 'PATCH', body,}
                    );
                } else {
                    return makeRequest(
                        `${HOST}/api/v1/quiz/products/${data.id}/`,
                        {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
                    );
                }
            },
        },
        productimages: {
            create: ({file, name, product, file_type}) => {
                let form = jsonToForm({name, product, file_type});
                let body = addFileToForm({form, key: 'image', file});
                return makeRequest(
                    `${HOST}/api/v1/quiz/productimages/`,
                    {method: 'POST', body,}
                )
            },
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productimages/${id}/`, {method: 'DELETE'}),
        },
        productitemconnections: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/quiz/productitemconnections/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            list: () => makeRequest(`${HOST}/api/v1/quiz/productitemconnections/`,),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productitemconnections/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productitemconnections/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/productitemconnections/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        productfeatures: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/quiz/productfeatures/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productfeatures/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productfeatures/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/productfeatures/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        productrelations: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/quiz/productrelations/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productrelations/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productrelations/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/productrelations/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        productlanguages: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/quiz/productlanguages/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            get: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productlanguages/${id}/`),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/quiz/productlanguages/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/quiz/productlanguages/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
    },

    events: {
        messagetypes: {
            create: (data) => makeRequest(
                `${HOST}/api/v1/events/messagetypes/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            get: ({id}) => makeRequest(`${HOST}/api/v1/events/messagetypes/${id}/`),
            list: () => makeRequest(`${HOST}/api/v1/events/messagetypes/`,),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/events/messagetypes/${id}/`, {method: 'DELETE'}),
            update: (data) => makeRequest(
                `${HOST}/api/v1/events/messagetypes/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        }
    },

    report: {
        reportsheets: {
            get: ({id}) => makeRequest(`${HOST}/api/v1/report/reportsheets/${id}/`,),
            list: ({bot_form_id}) => makeRequest(`${HOST}/api/v1/report/reportsheets/?bot_form_id=${bot_form_id}`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/report/reportsheets/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/report/reportsheets/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',}
            ),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/report/reportsheets/${id}/`, {method: 'DELETE'}),
        },
        redflags: {
            get: ({id}) => makeRequest(`${HOST}/api/v1/report/redflags/${id}/`,),
            list: (data) => makeRequest(`${HOST}/api/v1/report/redflags/`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/report/redflags/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/report/redflags/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',}
            ),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/report/redflags/${id}/`, {method: 'DELETE'}),
        },
        analysisscores: {
            get: ({id}) => makeRequest(`${HOST}/api/v1/report/analysisscores/${id}/`,),
            list: (data) => makeRequest(`${HOST}/api/v1/report/analysisscores/`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/report/analysisscores/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/report/analysisscores/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',}
            ),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/report/analysisscores/${id}/`, {method: 'DELETE'}),
        },
        analysiscards: {
            get: ({id}) => makeRequest(`${HOST}/api/v1/report/analysiscards/${id}/`,),
            list: (data) => makeRequest(`${HOST}/api/v1/report/analysiscards/`,),
            create: ({file, ...data}) => {
                let form = jsonToForm(data);
                let body = form;
                if (!!file) {
                    body = addFileToForm({form, key: 'icon', file});
                }
                return makeRequest(
                    `${HOST}/api/v1/report/analysiscards/`,
                    {method: 'POST', body}
                )
            },
            update: ({icon, file, ...data}) => {
                let form = jsonToForm(data);
                let body = form;
                if (file) {
                    body = addFileToForm({form, key: 'icon', file});
                }
                return makeRequest(
                    `${HOST}/api/v1/report/analysiscards/${data.id}/`,
                    {method: 'PATCH', body}
                )
            },
            remove: ({id}) => makeRequest(`${HOST}/api/v1/report/analysiscards/${id}/`, {method: 'DELETE'}),
        },
        analysiscardtexts: {
            get: ({id}) => makeRequest(`${HOST}/api/v1/report/analysiscardtexts/${id}/`,),
            list: (data) => makeRequest(`${HOST}/api/v1/report/analysiscardtexts/`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/report/analysiscardtexts/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/report/analysiscardtexts/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',}
            ),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/report/analysiscardtexts/${id}/`, {method: 'DELETE'}),
        },
        reportsheetproducts: {
            get: ({id}) => makeRequest(`${HOST}/api/v1/report/reportsheetproducts/${id}/`,),
            list: (data) => makeRequest(`${HOST}/api/v1/report/reportsheetproducts/`,),
            create: (data) => makeRequest(
                `${HOST}/api/v1/report/reportsheetproducts/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/report/reportsheetproducts/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',}
            ),
            remove: ({id}) => makeRequest(`${HOST}/api/v1/report/reportsheetproducts/${id}/`, {method: 'DELETE'}),
        },
    }
}
