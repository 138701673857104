import {call, put} from 'redux-saga/effects';
import {
    CREATE_BOT_FIELD_CONNECTION_RESULT,
    CREATE_BOT_FIELD_CONNECTION_ERROR,
    CREATE_BOT_FIELD_CONNECTION_RETRY,
    FETCH_BOT_FIELD_CONNECTIONS_RESULT,
    FETCH_BOT_FIELD_CONNECTIONS_ERROR,
    FETCH_BOT_FIELD_CONNECTIONS_RETRY,
    FETCH_BOT_FIELD_CONNECTION_RESULT,
    FETCH_BOT_FIELD_CONNECTION_ERROR,
    FETCH_BOT_FIELD_CONNECTION_RETRY,
    UPDATE_BOT_FIELD_CONNECTION_RESULT,
    UPDATE_BOT_FIELD_CONNECTION_ERROR,
    UPDATE_BOT_FIELD_CONNECTION_RETRY,
    DELETE_BOT_FIELD_CONNECTION_RESULT,
    DELETE_BOT_FIELD_CONNECTION_ERROR, DELETE_BOT_FIELD_CONNECTION_RETRY,
} from '../../actions/quiz/connections';
import api from "../api";
import {toast} from "react-toastify";


export function* createBotFieldConnection(action) {
    try {
        const response = yield call(api.quiz.connections.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createBotFieldConnection', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_BOT_FIELD_CONNECTION_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_BOT_FIELD_CONNECTION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_BOT_FIELD_CONNECTION_RETRY, retry: true, message: e.message});
    }
}


export function* fetchBotFieldConnections(action) {
    try {
        const response = yield call(api.quiz.connections.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchBotFieldConnections', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BOT_FIELD_CONNECTIONS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BOT_FIELD_CONNECTIONS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BOT_FIELD_CONNECTIONS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchBotFieldConnection(action) {
    try {
        const response = yield call(api.quiz.connections.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchBotFieldConnection', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BOT_FIELD_CONNECTION_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BOT_FIELD_CONNECTION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BOT_FIELD_CONNECTION_RETRY, retry: true, message: e.message});
    }
}


export function* updateBotFieldConnection(action) {
    try {
        const response = yield call(api.quiz.connections.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateBotFieldConnection', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_BOT_FIELD_CONNECTION_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_BOT_FIELD_CONNECTION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_BOT_FIELD_CONNECTION_RETRY, retry: true, message: e.message});
    }
}


export function* deleteBotFieldConnection(action) {
    try {
        const response = yield call(api.quiz.connections.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteBotFieldConnection', action);

        if (response.status === 204) {
            yield put({type: DELETE_BOT_FIELD_CONNECTION_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_BOT_FIELD_CONNECTION_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_BOT_FIELD_CONNECTION_RETRY, retry: true, message: e.message});
    }
}
