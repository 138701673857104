import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


class SidebarHeader extends PureComponent {

    static propTypes = {
        // type: PropTypes.oneOf(['Dashboard', 'Forms', 'Reports', 'Settings']),
        type: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func,
    };
    render() {
        const {type, icon, onClick} = this.props;

        return (
            <li className='sidebar-header' onClick={onClick}>
                <i className="material-icons">{icon}</i>
                <span className='side-item-text text-capitalize'>{type}</span>
            </li>
        )
    }
}

export default SidebarHeader;