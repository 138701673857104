export const CREATE_BOT_FIELD_FILE = 'CREATE_BOT_FIELD_FILE';
export const CREATE_BOT_FIELD_FILE_RESULT = 'CREATE_BOT_FIELD_FILE_RESULT';
export const CREATE_BOT_FIELD_FILE_ERROR = 'CREATE_BOT_FIELD_FILE_ERROR';
export const CREATE_BOT_FIELD_FILE_RETRY = 'CREATE_BOT_FIELD_FILE_RETRY';


export const FETCH_BOT_FIELD_FILES = 'FETCH_BOT_FIELD_FILES';
export const FETCH_BOT_FIELD_FILES_RESULT = 'FETCH_BOT_FIELD_FILES_RESULT';
export const FETCH_BOT_FIELD_FILES_ERROR = 'FETCH_BOT_FIELD_FILES_ERROR';
export const FETCH_BOT_FIELD_FILES_RETRY = 'FETCH_BOT_FIELD_FILES_RETRY';


export const FETCH_BOT_FIELD_FILE = 'FETCH_BOT_FIELD_FILE';
export const FETCH_BOT_FIELD_FILE_RESULT = 'FETCH_BOT_FIELD_FILE_RESULT';
export const FETCH_BOT_FIELD_FILE_ERROR = 'FETCH_BOT_FIELD_FILE_ERROR';
export const FETCH_BOT_FIELD_FILE_RETRY = 'FETCH_BOT_FIELD_FILE_RETRY';


export const UPDATE_BOT_FIELD_FILE = 'UPDATE_BOT_FIELD_FILE';
export const UPDATE_BOT_FIELD_FILE_RESULT = 'UPDATE_BOT_FIELD_FILE_RESULT';
export const UPDATE_BOT_FIELD_FILE_ERROR = 'UPDATE_BOT_FIELD_FILE_ERROR';
export const UPDATE_BOT_FIELD_FILE_RETRY = 'UPDATE_BOT_FIELD_FILE_RETRY';


export const DELETE_BOT_FIELD_FILE = 'DELETE_BOT_FIELD_FILE';
export const DELETE_BOT_FIELD_FILE_RESULT = 'DELETE_BOT_FIELD_FILE_RESULT';
export const DELETE_BOT_FIELD_FILE_ERROR = 'DELETE_BOT_FIELD_FILE_ERROR';
export const DELETE_BOT_FIELD_FILE_RETRY = 'DELETE_BOT_FIELD_FILE_RETRY';


export const createBotFieldFile = (data) => ({
    type: CREATE_BOT_FIELD_FILE,
    data,
});

export const fetchBotFieldFiles = (data) => ({
    type: FETCH_BOT_FIELD_FILES,
    data,
});


export const fetchBotFieldFile = (data) => ({
    type: FETCH_BOT_FIELD_FILE,
    data,
});

export const updateBotFieldFile = (data) => ({
    type: UPDATE_BOT_FIELD_FILE,
    data,
});

export const deleteBotFieldFile = (data) => ({
    type: DELETE_BOT_FIELD_FILE,
    data,
});
