export const CREATE_PRODUCT_IMAGE = 'CREATE_PRODUCT_IMAGE';
export const CREATE_PRODUCT_IMAGE_RESULT = 'CREATE_PRODUCT_IMAGE_RESULT';
export const CREATE_PRODUCT_IMAGE_ERROR = 'CREATE_PRODUCT_IMAGE_ERROR';
export const CREATE_PRODUCT_IMAGE_RETRY = 'CREATE_PRODUCT_IMAGE_RETRY';

export const DELETE_PRODUCT_IMAGE = 'DELETE_PRODUCT_IMAGE';
export const DELETE_PRODUCT_IMAGE_RESULT = 'DELETE_PRODUCT_IMAGE_RESULT';
export const DELETE_PRODUCT_IMAGE_ERROR = 'DELETE_PRODUCT_IMAGE_ERROR';
export const DELETE_PRODUCT_IMAGE_RETRY = 'DELETE_PRODUCT_IMAGE_RETRY';


export const createProductImage = (data) => ({
    type: CREATE_PRODUCT_IMAGE,
    data,
});

export const deleteProductImage = (data) => ({
    type: DELETE_PRODUCT_IMAGE,
    data,
});
