import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import QuillHelper from "../../others/QuillHelper";
import Select from "react-select";

import './styles.scss';

import {
    fetchProductLanguage,
    createProductLanguage,
    updateProductLanguage,
    deleteProductLanguage
} from "../../../actions/quiz/productlanguages";
import ConfirmButton from "../../buttons/ConfirmButton";
import {languageOptions} from "../../../constants";


class ProductLanguageEditCreateModal extends React.Component {

    static propTypes = {
        productLanguage: PropTypes.any,
        fetchProductLanguage: PropTypes.func,
        createProductLanguage: PropTypes.func,
        updateProductLanguage: PropTypes.func,
        deleteProductLanguage: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            productLanguage: props.productLanguage,
            message: undefined,
        };
    }

    componentDidMount() {
        if (this.props.productLanguage.id) {
            this.props.fetchProductLanguage(this.props.productLanguage);
        }

        let quill = new QuillHelper('#product-language-description-editor', this.handleContentChange);
        this.setState({quill});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {productlanguages: {detail, isFetching, isCreating, isDeleting, isUpdating, message, error, retry}} = this.props;

        if (!isFetching && prevProps.productlanguages.isFetching && !error && !retry) {
            this.setState({productLanguage: detail});
            this.state.quill.setHtmlText(detail.description);
        }
        if ((!isUpdating && prevProps.productlanguages.isUpdating && !error && !retry) ||
            (!isCreating && prevProps.productlanguages.isCreating && !error && !retry) ||
            (!isDeleting && prevProps.productlanguages.isDeleting && !error && !retry) ) {
            this.props.handleClose();
        }
        if ((!isCreating && prevProps.productlanguages.isCreating && error && !retry) ||
            (!isUpdating && prevProps.productlanguages.isUpdating && error && !retry) ) {
            this.setState({message});
        }
    }

    handleContentChange = () => {
        let {productLanguage} = this.state;
        this.setState({
            productLanguage: {
                ...productLanguage,
                description: this.state.quill.getHtmlText()
            }
        });
    };

    handleSaveProductLanguage = () => {
        if (this.state.productLanguage.id) {
            this.props.updateProductLanguage(this.state.productLanguage);
        } else {
            this.props.createProductLanguage({...this.state.productLanguage});
        }
    };

    handleDeleteProductLanguage = () => {
        if (this.state.productLanguage.id) {
            this.props.deleteProductLanguage(this.state.productLanguage);
        }
    };

    render() {
        let {show, handleClose} = this.props;
        let {productLanguage, message} = this.state;

        return (
            <Modal className="product-language-modal" show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Language</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Language</Form.Label>
                        <Select options={languageOptions}
                                value={languageOptions.filter(lang => lang.value === productLanguage.language)}
                                onChange={(lang) => {
                                    this.setState({
                                        productLanguage: {
                                            ...productLanguage,
                                            language: lang.value
                                        }
                                    })
                                }}/>
                        <Form.Control type="hidden" isInvalid={!!message && !!message.language}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.language}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text"
                                      value={productLanguage.title || ''}
                                      onChange={(e) => this.setState({
                                          productLanguage: {
                                              ...productLanguage,
                                              title: e.target.value
                                          }
                                      })}
                                      isInvalid={!!message && !!message.title}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.title}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Short Description</Form.Label>
                        <Form.Control as="textarea" rows="2"
                                      value={productLanguage.short_description || ''}
                                      onChange={(e) => this.setState({
                                          productLanguage: {
                                              ...productLanguage,
                                              short_description: e.target.value
                                          }
                                      })}
                                      isInvalid={!!message && !!message.short_description}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.short_description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <div id="product-language-description-editor" className="content"/>

                        <Form.Control.Feedback type="invalid">
                            {!!message && message.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveProductLanguage}>Save</Button>
                    {
                        this.state.productLanguage.id &&
                        <ConfirmButton onClick={this.handleDeleteProductLanguage}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    productlanguages: state.quiz.productlanguages,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchProductLanguage,
    createProductLanguage,
    updateProductLanguage,
    deleteProductLanguage,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ProductLanguageEditCreateModal);
