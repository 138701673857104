import {call, put} from 'redux-saga/effects';
import {
    CREATE_BOT_FIELD_FILE_RESULT,
    CREATE_BOT_FIELD_FILE_ERROR,
    CREATE_BOT_FIELD_FILE_RETRY,
    FETCH_BOT_FIELD_FILES_RESULT,
    FETCH_BOT_FIELD_FILES_ERROR,
    FETCH_BOT_FIELD_FILES_RETRY,
    FETCH_BOT_FIELD_FILE_RESULT,
    FETCH_BOT_FIELD_FILE_ERROR,
    FETCH_BOT_FIELD_FILE_RETRY,
    UPDATE_BOT_FIELD_FILE_RESULT,
    UPDATE_BOT_FIELD_FILE_ERROR,
    UPDATE_BOT_FIELD_FILE_RETRY,
    DELETE_BOT_FIELD_FILE_RESULT,
    DELETE_BOT_FIELD_FILE_ERROR, DELETE_BOT_FIELD_FILE_RETRY,
} from '../../actions/quiz/botfieldfiles';
import api from "../api";
import {toast} from "react-toastify";


export function* createBotFieldFile(action) {
    try {
        const response = yield call(api.quiz.botfieldfiles.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createBotFieldFile', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_BOT_FIELD_FILE_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_BOT_FIELD_FILE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_BOT_FIELD_FILE_RETRY, retry: true, message: e.message});
    }
}


export function* fetchBotFieldFiles(action) {
    try {
        const response = yield call(api.quiz.botfieldfiles.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchBotFieldFiles', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BOT_FIELD_FILES_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BOT_FIELD_FILES_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BOT_FIELD_FILES_RETRY, retry: true, message: e.message});
    }
}


export function* fetchBotFieldFile(action) {
    try {
        const response = yield call(api.quiz.botfieldfiles.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchBotFieldFile', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BOT_FIELD_FILE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BOT_FIELD_FILE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BOT_FIELD_FILE_RETRY, retry: true, message: e.message});
    }
}


export function* updateBotFieldFile(action) {
    try {
        const response = yield call(api.quiz.botfieldfiles.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateBotFieldFile', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_BOT_FIELD_FILE_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_BOT_FIELD_FILE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_BOT_FIELD_FILE_RETRY, retry: true, message: e.message});
    }
}


export function* deleteBotFieldFile(action) {
    try {
        const response = yield call(api.quiz.botfieldfiles.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteBotFieldFile', action);

        if (response.status === 204) {
            yield put({type: DELETE_BOT_FIELD_FILE_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_BOT_FIELD_FILE_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_BOT_FIELD_FILE_RETRY, retry: true, message: e.message});
    }
}
