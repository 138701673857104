import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {fetchReview, updateReview, createReview, deleteReview} from "../../../actions/cms/reviews";
import {connect} from "react-redux";
import ConfirmButton from "../../buttons/ConfirmButton";
import {fetchProducts} from "../../../actions/quiz/products";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";


class ReviewEditCreateModal extends React.Component {

    static propTypes = {
        reviews: PropTypes.any,
        fetchReview: PropTypes.func,
        updateReview: PropTypes.func,
        createReview: PropTypes.func,
        deleteReview: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            review: props.review,
            message: undefined,
        };
    }

    componentDidMount() {
        if (this.props.review.id) {
            this.props.fetchReview(this.props.review);
        }
        this.props.fetchProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {reviews: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;

        if (!isFetching && prevProps.reviews.isFetching && !error && !retry) {
            this.setState({review: detail});
        }

        if ((!isCreating && prevProps.reviews.isCreating && !error && !retry) ||
            (!isUpdating && prevProps.reviews.isUpdating && !error && !retry) ||
            (!isDeleting && prevProps.reviews.isDeleting && !error && !retry)) {
            this.props.handleClose();
        }

        if ((!isCreating && prevProps.reviews.isCreating && error && !retry) ||
            (!isUpdating && prevProps.reviews.isUpdating && error && !retry) ||
            (!isDeleting && prevProps.reviews.isDeleting && error && !retry)) {
            this.setState({message});
        }
    }


    handleReviewChange = (review) => {
        this.setState({review});
    };

    handleSaveReview = () => {
        if (this.state.review.id) {
            this.props.updateReview(this.state.review);
        } else {
            this.props.createReview(this.state.review);
        }
    };

    handleDeleteReview = () => {
        if (this.state.review.id) {
            this.props.deleteReview(this.state.review);
        }
    };

    render() {
        let {show, handleClose, products} = this.props;
        let {review, message} = this.state;

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{review.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="reviewName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Name"
                                      value={review.name || ''}
                                      isInvalid={!!message && !!message.name}
                                      onChange={(e) => this.handleReviewChange({
                                          ...review,
                                          name: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="reviewText">
                        <Form.Label>Text</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Text"
                                      value={review.text || ''}
                                      isInvalid={!!message && !!message.text}
                                      onChange={(e) => this.handleReviewChange({
                                          ...review,
                                          text: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.text}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="reviewStar">
                        <Form.Label>Star</Form.Label>
                        <Form.Control type="number" min="1" max="5"
                                      placeholder="Star Rating Only (1-5)"
                                      value={review.star || ''}
                                      isInvalid={!!message && !!message.star}
                                      onChange={(e) => this.handleReviewChange({
                                          ...review,
                                          star: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.star}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Product</Form.Label>
                        <Select options={products.list.map(p => ({label: `${p.product_id} - ${p.title}`, value: p.id}))}
                                value={products.list.map(p => ({
                                    label: p.product_id,
                                    value: p.id
                                })).filter(p => p.value == review.product)}
                                onChange={(obj) => this.handleReviewChange({
                                    ...review,
                                    product: obj.value,
                                })}/>
                        <Form.Control type="hidden" isInvalid={!!message && !!message.product}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.product}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="dateTime">
                        <Form.Label>Date / time</Form.Label>
                        <DatePicker showYearDropdown showMonthDropdown
                                    className='form-control'
                                    placeholderText='Select Date / time'
                                    dateFormat="dd MMM yyyy, hh:mm a"
                                    showTimeSelect
                                    timeIntervals={1}
                                    selected={!!review.created_at ? new Date(review.created_at) : ''}
                                    onChange={(date) => this.handleReviewChange({
                                        ...review,
                                        created_at: moment(date).format(),
                                    })}
                        />
                    </Form.Group>

                    <Form.Group controlId="reviewVerified">
                        <Form.Check type="checkbox" label="Verified Purchase"
                                    checked={review.verified_purchase || ''}
                                    isInvalid={!!message && !!message.verified_purchase}
                                    onChange={(e) => this.handleReviewChange({
                                        ...review,
                                        verified_purchase: !review.verified_purchase,
                                    })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.verified_purchase}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveReview}>Save</Button>
                    {
                        this.state.review.id &&
                        <ConfirmButton onClick={this.handleDeleteReview}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    reviews: state.cms.reviews,
    products: state.quiz.products,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchReview,
    updateReview,
    createReview,
    deleteReview,
    fetchProducts,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ReviewEditCreateModal);
