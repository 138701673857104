import {
    CREATE_BOT_FIELD,
    CREATE_BOT_FIELD_RESULT,
    CREATE_BOT_FIELD_ERROR,
    CREATE_BOT_FIELD_RETRY,

    FETCH_BOT_FIELDS,
    FETCH_BOT_FIELDS_RESULT,
    FETCH_BOT_FIELDS_ERROR,
    FETCH_BOT_FIELDS_RETRY,

    FETCH_BOT_FIELD,
    FETCH_BOT_FIELD_RESULT,
    FETCH_BOT_FIELD_ERROR,
    FETCH_BOT_FIELD_RETRY,

    UPDATE_BOT_FIELD,
    UPDATE_BOT_FIELD_RESULT,
    UPDATE_BOT_FIELD_ERROR,
    UPDATE_BOT_FIELD_RETRY,

    DELETE_BOT_FIELD,
    DELETE_BOT_FIELD_RESULT,
    DELETE_BOT_FIELD_ERROR,
    DELETE_BOT_FIELD_RETRY,
} from '../../actions/quiz/botfields';
import {mergeConnectionWithFields, mergeFieldLists, removeConnectionFromFields} from "../../utils";
import {CREATE_BOT_FIELD_CONNECTION_RESULT, DELETE_BOT_FIELD_CONNECTION_RESULT} from "../../actions/quiz/connections";


const initialState = {
    detail: {},
    list: [],
    isCreating: false,
    isUpdating: false,
    isFetching: false,
    isDeleting: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_BOT_FIELD:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_BOT_FIELD_RESULT:
            let new_list = mergeFieldLists({list1: state.list, field: action.data});
            return {...state, isCreating: false, detail: action.data, list: new_list};
        case CREATE_BOT_FIELD_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_BOT_FIELD_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_BOT_FIELDS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_BOT_FIELDS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_BOT_FIELDS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_BOT_FIELDS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_BOT_FIELD:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_BOT_FIELD_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_BOT_FIELD_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_BOT_FIELD_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_BOT_FIELD:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_BOT_FIELD_RESULT:
            let updated_list = mergeFieldLists({list1: state.list, field: action.data});
            return {...state, isUpdating: false, detail: action.data, list: updated_list};
        case UPDATE_BOT_FIELD_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_BOT_FIELD_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_BOT_FIELD:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_BOT_FIELD_RESULT:
            return {...state, isDeleting: false, list: mergeFieldLists({list1: state.list, field: action.data, remove: true})};
        case DELETE_BOT_FIELD_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_BOT_FIELD_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};

        case CREATE_BOT_FIELD_CONNECTION_RESULT:
            return {...state, list: mergeConnectionWithFields({connection: action.data, fields: state.list})};

        case DELETE_BOT_FIELD_CONNECTION_RESULT:
            return {...state, list: removeConnectionFromFields({connection: action.data, fields: state.list})};
        default:
            return state;
    }
};

export default reducer;
