// External Imports
import {combineReducers} from 'redux';
import {connectRouter, routerMiddleware, } from 'connected-react-router';
// My Imports
import accounts from './accounts';
import cms from './cms';
import quiz from './quiz';
import report from './report';
import events from './events';


const reducers = (history) => combineReducers({
    router: connectRouter(history),
    accounts,
    cms,
    quiz,
    report,
    events,
});


export default reducers;
