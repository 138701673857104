import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {fetchBotForm, updateBotForm, createBotForm, deleteBotForm} from "../../../actions/quiz/botforms";
import {connect} from "react-redux";
import ConfirmButton from "../../buttons/ConfirmButton";
import Col from "react-bootstrap/Col";


class BotFormEditCreateModal extends React.Component {

    static propTypes = {
        botform: PropTypes.any,
        botforms: PropTypes.any,
        createBotForm: PropTypes.func,
        fetchBotForm: PropTypes.func,
        updateBotForm: PropTypes.func,
        deleteBotForm: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            botform: props.botform,
            message: undefined,
            field_child: undefined,
        }
    }

    componentDidMount() {
        if (this.props.botform.id) {
            this.props.fetchBotForm(this.props.botform);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {botforms: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;

        if (!isFetching && prevProps.botforms.isFetching && !error && !retry) {
            this.setState({botform: detail});
        }
        if (!isUpdating && prevProps.botforms.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.botforms.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.botforms.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.botforms.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.botforms.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleBotFormChange = (botform) => {
        this.setState({botform});
    };

    handleSaveBotForm = () => {
        if (this.state.botform.id) {
            this.props.updateBotForm(this.state.botform);
        } else {
            this.props.createBotForm({...this.state.botform});
        }
    };

    handleDeleteBotForm = () => {
        if (this.state.botform.id) {
            this.props.deleteBotForm(this.state.botform);
        }
    };

    handleCloseBotFormChildModal = () => {
        this.setState({field_child: undefined});
    };

    render() {
        let {show, botforms, handleConfirm, handleClose} = this.props;
        let {botform, message} = this.state;

        botform.root_bot_fields = botform.root_bot_fields || [];
        botform.bot_fields = botform.bot_fields || [];

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{botform.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} controlId="fieldId">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Name"
                                          value={botform.name}
                                          isInvalid={!!message && !!message.name}
                                          onChange={(e) => this.handleBotFormChange({
                                              ...botform,
                                              name: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="fieldId">
                            <Form.Label>Bot Field ID</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Bot Field ID"
                                          value={botform.bot_form_id}
                                          isInvalid={!!message && !!message.bot_form_id}
                                          onChange={(e) => this.handleBotFormChange({
                                              ...botform,
                                              bot_form_id: e.target.value,
                                          })}/>
                            <Form.Text className="text-muted">
                                Space not allowed
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.bot_form_id}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="fieldId">
                        <Form.Label>Starting Question</Form.Label>
                        <Select options={botform.bot_fields.map(o => ({label: o[1], value: o[0]}))}
                                className="bot-form-bot-fields-select"
                                isMulti={true}
                                value={botform.bot_fields.filter(o => botform.root_bot_fields.indexOf(o[0]) > -1).map(o => ({
                                    label: o[1],
                                    value: o[0]
                                }))}
                                onChange={(value) => this.handleBotFormChange({
                                    ...botform,
                                    root_bot_fields: value.map(o => o.value),
                                })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.root_bot_fields}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveBotForm}>Save</Button>
                    {/*{
                        this.state.botform.id &&
                        <ConfirmButton onClick={this.handleDeleteBotForm}/>
                    }*/}
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    botforms: state.quiz.botforms,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createBotForm,
    fetchBotForm,
    updateBotForm,
    deleteBotForm,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(BotFormEditCreateModal);
