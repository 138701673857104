export const CREATE_ANSWER = 'CREATE_ANSWER';
export const CREATE_ANSWER_RESULT = 'CREATE_ANSWER_RESULT';
export const CREATE_ANSWER_ERROR = 'CREATE_ANSWER_ERROR';
export const CREATE_ANSWER_RETRY = 'CREATE_ANSWER_RETRY';

export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_ANSWER_RESULT = 'UPDATE_ANSWER_RESULT';
export const UPDATE_ANSWER_ERROR = 'UPDATE_ANSWER_ERROR';
export const UPDATE_ANSWER_RETRY = 'UPDATE_ANSWER_RETRY';



export const createAnswer = (data) => ({
    type: CREATE_ANSWER,
    data,
});

export const updateAnswer = (data) => ({
    type: UPDATE_ANSWER,
    data,
});
