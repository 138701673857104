import {takeEvery, all} from 'redux-saga/effects';

import accounts from './accounts';
import cms from './cms';
import quiz from './quiz';
import report from './report';
import events from './events';


export default function* sagas() {
    yield all([
        ...accounts,
        ...cms,
        ...quiz,
        ...report,
        ...events,
    ]);
}
