export const CREATE_RED_FLAG = 'CREATE_RED_FLAG';
export const CREATE_RED_FLAG_RESULT = 'CREATE_RED_FLAG_RESULT';
export const CREATE_RED_FLAG_ERROR = 'CREATE_RED_FLAG_ERROR';
export const CREATE_RED_FLAG_RETRY = 'CREATE_RED_FLAG_RETRY';


export const FETCH_RED_FLAGS = 'FETCH_RED_FLAGS';
export const FETCH_RED_FLAGS_RESULT = 'FETCH_RED_FLAGS_RESULT';
export const FETCH_RED_FLAGS_ERROR = 'FETCH_RED_FLAGS_ERROR';
export const FETCH_RED_FLAGS_RETRY = 'FETCH_RED_FLAGS_RETRY';


export const FETCH_RED_FLAG = 'FETCH_RED_FLAG';
export const FETCH_RED_FLAG_RESULT = 'FETCH_RED_FLAG_RESULT';
export const FETCH_RED_FLAG_ERROR = 'FETCH_RED_FLAG_ERROR';
export const FETCH_RED_FLAG_RETRY = 'FETCH_RED_FLAG_RETRY';


export const UPDATE_RED_FLAG = 'UPDATE_RED_FLAG';
export const UPDATE_RED_FLAG_RESULT = 'UPDATE_RED_FLAG_RESULT';
export const UPDATE_RED_FLAG_ERROR = 'UPDATE_RED_FLAG_ERROR';
export const UPDATE_RED_FLAG_RETRY = 'UPDATE_RED_FLAG_RETRY';


export const DELETE_RED_FLAG = 'DELETE_RED_FLAG';
export const DELETE_RED_FLAG_RESULT = 'DELETE_RED_FLAG_RESULT';
export const DELETE_RED_FLAG_ERROR = 'DELETE_RED_FLAG_ERROR';
export const DELETE_RED_FLAG_RETRY = 'DELETE_RED_FLAG_RETRY';


export const createRedFlag = (data) => ({
    type: CREATE_RED_FLAG,
    data,
});

export const fetchRedFlags = (data) => ({
    type: FETCH_RED_FLAGS,
    data,
});


export const fetchRedFlag = (data) => ({
    type: FETCH_RED_FLAG,
    data,
});

export const updateRedFlag = (data) => ({
    type: UPDATE_RED_FLAG,
    data,
});

export const deleteRedFlag = (data) => ({
    type: DELETE_RED_FLAG,
    data,
});
