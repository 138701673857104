import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {
    fetchAnalysisCardText,
    updateAnalysisCardText,
    createAnalysisCardText,
    deleteAnalysisCardText
} from "../../../actions/report/analysiscardtexts";
import {connect} from "react-redux";
import Select from "react-select";
import ExpressionBuilder from "../ExpressionBuilder";
import ConfirmButton from "../../buttons/ConfirmButton";
import QuillHelper from "../../others/QuillHelper";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";


class AnalysisCardTextTextEditCreateModal extends React.Component {

    static propTypes = {
        analysiscardtext: PropTypes.any,
        analysiscardtexts: PropTypes.any,
        createAnalysisCardText: PropTypes.func,
        fetchAnalysisCardText: PropTypes.func,
        updateAnalysisCardText: PropTypes.func,
        deleteAnalysisCardText: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            analysiscardtext: props.analysiscardtext,
            message: undefined,
            field_child: undefined,
            showBuilder: false,
        }
    }

    componentDidMount() {
        if (this.props.analysiscardtext.id) {
            this.props.fetchAnalysisCardText(this.props.analysiscardtext);
        }
        let quill = new QuillHelper('#analysis-card-text-editor', this.handleContentChange);
        this.setState({quill});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {analysiscardtexts: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;
        let {language} = this.state;

        if (!isFetching && prevProps.analysiscardtexts.isFetching && !error && !retry) {
            this.setState({analysiscardtext: detail});
            let textKey = 'text' + (language ? language.db_label: '');
            this.state.quill.setHtmlText(detail[textKey]);
        }
        if (!isUpdating && prevProps.analysiscardtexts.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.analysiscardtexts.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.analysiscardtexts.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.analysiscardtexts.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.analysiscardtexts.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleContentChange = () => {
        let {analysiscardtext, language} = this.state;
        let textKey = 'text' + (language ? language.db_label: '');

        this.setState({
            analysiscardtext: {
                ...analysiscardtext,
                [textKey]: this.state.quill.getHtmlText()
            }
        });
    };

    handleAnalysisCardTextChange = (analysiscardtext) => {
        this.setState({analysiscardtext});
    };

    handleSaveAnalysisCardText = () => {
        if (this.state.analysiscardtext.id) {
            this.props.updateAnalysisCardText(this.state.analysiscardtext);
        } else {
            this.props.createAnalysisCardText({...this.state.analysiscardtext});
        }
    };

    handleDeleteAnalysisCardText = () => {
        if (this.state.analysiscardtext.id) {
            this.props.deleteAnalysisCardText(this.state.analysiscardtext);
        }
    };

    handleLanguageChange = (language) => {
        let {analysiscardtext} = this.state;
        let textKey = 'text' + (language ? language.db_label: '');
        this.setState({language});
        this.state.quill.setHtmlText(analysiscardtext[textKey]);
    };


    render() {
        let {show, botfields, reportsheets, handleClose} = this.props;
        let {analysiscardtext, message, language} = this.state;
        let identifiers = botfields.list.map(o => [o.field_id, ...o.field_children.map(i => i.field_id)]);
        identifiers = [].concat(...identifiers) || [];

        let textKey = 'text' + (language ? language.db_label: '');

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{analysiscardtext.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LanguageSelectInput onLanguageChange={this.handleLanguageChange}/>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Text</Form.Label>
                        <div id="analysis-card-text-editor"/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message[textKey]}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldText">
                        <Form.Label>Expression</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      disabled={true}
                                      value={analysiscardtext.expression || ""}/>
                        <Button onClick={() => this.setState({showBuilder: true})}>Change Value</Button>
                        <ExpressionBuilder identifiers={identifiers}
                                           expression={analysiscardtext.expression || ""}
                                           handleExpressionChange={(expression => this.handleAnalysisCardTextChange({
                                               ...analysiscardtext,
                                               expression
                                           }))}
                                           show={this.state.showBuilder}
                                           handleClose={() => this.setState({showBuilder: false})}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveAnalysisCardText}>Save</Button>
                    {
                        this.state.analysiscardtext.id &&
                        <ConfirmButton onClick={this.handleDeleteAnalysisCardText}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    analysiscardtexts: state.report.analysiscardtexts,
    reportsheets: state.report.reportsheets,
    botfields: state.quiz.botfields,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createAnalysisCardText,
    fetchAnalysisCardText,
    updateAnalysisCardText,
    deleteAnalysisCardText,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(AnalysisCardTextTextEditCreateModal);
