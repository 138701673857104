import {PermissionEnum, RoleEnum} from "../../permisions";

export const routeList = [
    {
        name: 'Message Types',
        url: '/messagetypes',
        icon: 'list_alt',
        permissions: [
            PermissionEnum.EVENTS_MESSAGE_TYPE_READ, PermissionEnum.EVENTS_MESSAGE_TYPE_CREATE,
            PermissionEnum.EVENTS_MESSAGE_TYPE_DELETE, PermissionEnum.EVENTS_MESSAGE_TYPE_UPDATE,
        ],
        roles: [],
    },
    {
        name: 'Bot',
        url: '/bot',
        icon: 'list_alt',
        permissions: [],
        roles: [RoleEnum.QUIZ_WRITER],
    },
    {
        name: 'Products',
        url: '/products',
        icon: 'list_alt',
        permissions: [],
        roles: [RoleEnum.QUIZ_WRITER],
    },
    {
        name: 'Reviews',
        url: '/reviews',
        icon: 'list_alt',
        permissions: [],
        roles: [RoleEnum.QUIZ_WRITER],
    },
    {
        name: 'Coupons',
        url: '/coupons',
        icon: 'list_alt',
        permissions: [],
        roles: [RoleEnum.QUIZ_WRITER],
    },
    {
        name: 'Logout',
        url: '/logout',
        icon: 'lock',
        permissions: [],
        roles: [],
    },
];
