export const CREATE_REVIEW = 'CREATE_REVIEW';
export const CREATE_REVIEW_RESULT = 'CREATE_REVIEW_RESULT';
export const CREATE_REVIEW_ERROR = 'CREATE_REVIEW_ERROR';
export const CREATE_REVIEW_RETRY = 'CREATE_REVIEW_RETRY';


export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const FETCH_REVIEWS_RESULT = 'FETCH_REVIEWS_RESULT';
export const FETCH_REVIEWS_ERROR = 'FETCH_REVIEWS_ERROR';
export const FETCH_REVIEWS_RETRY = 'FETCH_REVIEWS_RETRY';


export const FETCH_REVIEW = 'FETCH_REVIEW';
export const FETCH_REVIEW_RESULT = 'FETCH_REVIEW_RESULT';
export const FETCH_REVIEW_ERROR = 'FETCH_REVIEW_ERROR';
export const FETCH_REVIEW_RETRY = 'FETCH_REVIEW_RETRY';


export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const UPDATE_REVIEW_RESULT = 'UPDATE_REVIEW_RESULT';
export const UPDATE_REVIEW_ERROR = 'UPDATE_REVIEW_ERROR';
export const UPDATE_REVIEW_RETRY = 'UPDATE_REVIEW_RETRY';


export const DELETE_REVIEW = 'DELETE_REVIEW';
export const DELETE_REVIEW_RESULT = 'DELETE_REVIEW_RESULT';
export const DELETE_REVIEW_ERROR = 'DELETE_REVIEW_ERROR';
export const DELETE_REVIEW_RETRY = 'DELETE_REVIEW_RETRY';


export const createReview = (data) => ({
    type: CREATE_REVIEW,
    data,
});

export const fetchReviews = () => ({
    type: FETCH_REVIEWS,
});

export const fetchReview = (data) => ({
    type: FETCH_REVIEW,
    data,
});

export const updateReview = (data) => ({
    type: UPDATE_REVIEW,
    data,
});

export const deleteReview = (data) => ({
    type: DELETE_REVIEW,
    data,
});
