import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchExpressions, createExpression} from "../../actions/quiz/expressions";
import './styles.scss';
import ExpressionEditCreateModal from "../../components/modals/ExpressionEditCreateModal";


class Expressions extends React.Component {

    static propTypes = {
        expressions: PropTypes.object,
        fetchExpressions: PropTypes.func,
    };

    state = {
        expression: undefined,
    };

    componentDidMount() {
        this.props.fetchExpressions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleCreateExpression = () => {
        this.setState({expression: {name: '', expression: ''}});
        // this.props.createExpression({name: });
    };

    render() {
        let {expressions} = this.props;
        // console.warn(list);

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Bot'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pt-2 text-right">
                            <Button size={'sm'} onClick={this.handleCreateExpression}>Create Expression</Button>
                        </div>
                        <div className="col-12 pt-3">
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Expression</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    expressions.list.map(
                                        (expression, index) => (
                                            <tr key={expression.id}>
                                                <td>{index + 1}</td>
                                                <td>{expression.name}</td>
                                                <td className="expression-table-expression">{expression.expression}</td>
                                                <td>
                                                    <Button size={'sm'}
                                                            onClick={() => this.setState({expression})}>edit</Button>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        {
                            !!this.state.expression &&
                            <ExpressionEditCreateModal show={true}
                                                       expression={this.state.expression}
                                                       handleClose={() => this.setState({expression: undefined})}/>
                        }
                    </div>
                </div>

            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    expressions: state.quiz.expressions,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchExpressions,
    createExpression,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Expressions);
