//External Imports
import {combineReducers} from 'redux';
//My Imports
import botforms from './botforms';
import botfields from './botfields';
import connections from './connections';
import expressions from './expressions';
import answers from './answers';
import botfieldfiles from './botfieldfiles';
import products from './products';
import productitemconnections from './productitemconnections';
import productimages from './productimages';
import productfeatures from './productfeatures';
import productrelations from './productrelations';
import productlanguages from './productlanguages';


export default combineReducers({
    botforms,
    botfields,
    connections,
    expressions,
    answers,
    botfieldfiles,
    products,
    productitemconnections,
    productimages,
    productfeatures,
    productrelations,
    productlanguages,
});
