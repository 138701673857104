import {call, put} from 'redux-saga/effects';
import {
    CREATE_BOT_FIELD_RESULT,
    CREATE_BOT_FIELD_ERROR,
    CREATE_BOT_FIELD_RETRY,
    FETCH_BOT_FIELDS_RESULT,
    FETCH_BOT_FIELDS_ERROR,
    FETCH_BOT_FIELDS_RETRY,
    FETCH_BOT_FIELD_RESULT,
    FETCH_BOT_FIELD_ERROR,
    FETCH_BOT_FIELD_RETRY,
    UPDATE_BOT_FIELD_RESULT,
    UPDATE_BOT_FIELD_ERROR,
    UPDATE_BOT_FIELD_RETRY,
    DELETE_BOT_FIELD_RESULT,
    DELETE_BOT_FIELD_ERROR, DELETE_BOT_FIELD_RETRY, COPY_BOT_FIELD_RESULT, COPY_BOT_FIELD_ERROR, COPY_BOT_FIELD_RETRY,
} from '../../actions/quiz/botfields';
import api from "../api";
import {toast} from "react-toastify";


export function* createBotField(action) {
    try {
        const response = yield call(api.quiz.botFields.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createBotField', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_BOT_FIELD_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_BOT_FIELD_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_BOT_FIELD_RETRY, retry: true, message: e.message});
    }
}


export function* fetchBotFields(action) {
    try {
        const response = yield call(api.quiz.botFields.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchBotFields', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BOT_FIELDS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BOT_FIELDS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BOT_FIELDS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchBotField(action) {
    try {
        const response = yield call(api.quiz.botFields.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchBotField', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BOT_FIELD_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BOT_FIELD_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BOT_FIELD_RETRY, retry: true, message: e.message});
    }
}


export function* updateBotField(action) {
    try {
        const response = yield call(api.quiz.botFields.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateBotField', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_BOT_FIELD_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_BOT_FIELD_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_BOT_FIELD_RETRY, retry: true, message: e.message});
    }
}


export function* deleteBotField(action) {
    try {
        const response = yield call(api.quiz.botFields.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteBotField', action);

        if (response.status === 204) {
            yield put({type: DELETE_BOT_FIELD_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_BOT_FIELD_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_BOT_FIELD_RETRY, retry: true, message: e.message});
    }
}


export function* copyBotField(action) {
    try {
        const response = yield call(api.quiz.botFields.copy, action.data);
        const result = yield response.json();
        console.warn('SAGA:copyBotField', action, result);

        if (response.status === 200) {
            yield put({type: COPY_BOT_FIELD_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: COPY_BOT_FIELD_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: COPY_BOT_FIELD_RETRY, retry: true, message: e.message});
    }
}
