import React from 'react';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";

import {fetchUserProfile} from "../../../actions/accounts/profile";


class LoginRedirect extends React.Component {

    static propTypes = {
        profile: PropTypes.object,
        fetchUserProfile: PropTypes.func,
    };

    componentDidMount() {
        // console.warn(this.props.history.location.pathname);
        if (!this.props.history.location.pathname.startsWith('/logout')) {
            this.props.fetchUserProfile();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {profile: {isFetching, error, retry}, history} = this.props;

        if (!isFetching && prevProps.profile.isFetching && !error && !retry) {
            if (history.location.pathname.startsWith('/login')) {
                history.push('/');
            }
        }
        if (!isFetching && prevProps.profile.isFetching && error && !retry) {
            history && history.push('/login/');
        }
    }

    render() {
        return null;
    }
}



const mapStateToProps = (state) => ({
    profile: state.accounts.profile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserProfile,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginRedirect));
