import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FIELD_LABELS, FIELD_TYPES} from "../../../constants";
import {
    createBotFieldConnection, fetchBotFieldConnection, updateBotFieldConnection, deleteBotFieldConnection
} from "../../../actions/quiz/connections";
import ExpressionBuilder from "../ExpressionBuilder";
import ConfirmButton from "../../buttons/ConfirmButton";


class BotFieldConnectionEditCreateModal extends React.Component {

    static propTypes = {
        connection: PropTypes.any,
        fetchBotFieldConnection: PropTypes.func,
        createBotFieldConnection: PropTypes.func,
        updateBotFieldConnection: PropTypes.func,
        deleteBotFieldConnection: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            connection: props.connection,
            message: undefined,
            showBuilder: false,
        }
    }

    componentDidMount() {
        if (this.props.connection.id) {
            this.props.fetchBotFieldConnection(this.props.connection);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {connections: {detail, isFetching, isCreating, isDeleting, isUpdating, message, error, retry}} = this.props;

        if (!isFetching && prevProps.connections.isFetching && !error && !retry) {
            this.setState({connection: detail});
        }
        if (!isUpdating && prevProps.connections.isUpdating && !error && !retry) {
            // this.setState({edit: false});
            this.props.handleClose();
        }
        if (!isCreating && prevProps.connections.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.connections.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.connections.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.connections.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    getBotFieldFromId = (botfield_id) => {
        let {botfields} = this.props;
        let filteredFields = botfields.list.filter(obj => obj.id === botfield_id);
        if (filteredFields.length) {
            return filteredFields[0];
        }
        return undefined;
    };

    handleBotFieldConnectionChange = (connection) => {
        this.setState({connection});
    };

    handleShowWhenChange = (value) => {
        this.setState(prevState => ({
            connection: {
                ...prevState.connection,
                show_when: value
            },
        }));
    };

    handleSaveBotFieldConnection = () => {
        if (this.state.connection.id) {
            this.props.updateBotFieldConnection(this.state.connection);
        } else {
            this.props.createBotFieldConnection({...this.state.connection});
        }
    };

    handleDeleteBotFieldConnection = () => {
        if (this.state.connection.id) {
            this.props.deleteBotFieldConnection(this.state.connection);
        }
    };

    render() {
        let {show, botfields, handleClose} = this.props;
        let {connection, message} = this.state;

        let fieldOptions = botfields.list.map(obj => ({label: obj.field_id, value: obj.id}));
        let child = this.getBotFieldFromId(connection.child_id);
        let parent = this.getBotFieldFromId(connection.parent_id);
        let childValue = child ? [{label: child.field_id, value: connection.child_id}] : undefined;
        let parentValue = parent ? [{label: parent.field_id, value: connection.parent_id}] : undefined;
        // console.warn(fieldOptions, child, parent);

        let identifiers = botfields.list.map(o => [o.field_id, ...o.field_children.map(i => i.field_id)]);
        identifiers = [].concat(...identifiers) || [];
        // console.warn(identifiers);

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Connection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Predecessor</Form.Label>
                        <Select options={fieldOptions}
                                className="bot-field-item-select"
                                value={parentValue}
                                onChange={(obj) => this.handleBotFieldConnectionChange({
                                    ...connection,
                                    parent_id: obj.value,
                                })}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.parent_id}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.parent_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Successor</Form.Label>
                        <Select options={fieldOptions}
                                className="bot-field-item-select"
                                value={childValue}
                                onChange={(obj) => this.handleBotFieldConnectionChange({
                                    ...connection,
                                    child_id: obj.value,
                                })}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.child_id}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.child_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldText">
                        <Form.Label>Show When</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      disabled={true}
                                      value={connection.show_when}/>
                        <Button onClick={() => this.setState({showBuilder: true})}>Change Show When</Button>
                        <ExpressionBuilder identifiers={identifiers}
                                           expression={connection.show_when}
                                           handleExpressionChange={this.handleShowWhenChange}
                                           show={this.state.showBuilder}
                                           handleClose={() => this.setState({showBuilder: false})}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveBotFieldConnection}>Save</Button>
                    {
                        this.state.connection.id &&
                        <ConfirmButton onClick={this.handleDeleteBotFieldConnection}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    botfields: state.quiz.botfields,
    connections: state.quiz.connections,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchBotFieldConnection,
    createBotFieldConnection,
    updateBotFieldConnection,
    deleteBotFieldConnection,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(BotFieldConnectionEditCreateModal);
