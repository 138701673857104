import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    createProductItemConnection, fetchProductItemConnection, updateProductItemConnection, deleteProductItemConnection
} from "../../../actions/quiz/productitemconnections";
import ConfirmButton from "../../buttons/ConfirmButton";


class ProductItemConnectionEditCreateModal extends React.Component {

    static propTypes = {
        productitemconnection: PropTypes.any,
        fetchProductItemConnection: PropTypes.func,
        createProductItemConnection: PropTypes.func,
        updateProductItemConnection: PropTypes.func,
        deleteProductItemConnection: PropTypes.func,
        products: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            productitemconnection: props.productitemconnection,
            message: undefined,
        }
    }

    componentDidMount() {
        if (this.props.productitemconnection.id) {
            this.props.fetchProductItemConnection(this.props.productitemconnection);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {productitemconnections: {detail, isFetching, isCreating, isDeleting, isUpdating, message, error, retry}} = this.props;

        if (!isFetching && prevProps.productitemconnections.isFetching && !error && !retry) {
            this.setState({productitemconnection: detail});
        }
        if (!isUpdating && prevProps.productitemconnections.isUpdating && !error && !retry) {
            // this.setState({edit: false});
            this.props.handleClose();
        }
        if (!isCreating && prevProps.productitemconnections.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.productitemconnections.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.productitemconnections.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.productitemconnections.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleProductItemConnectionChange = (productitemconnection) => {
        this.setState({productitemconnection});
    };

    handleShowWhenChange = (value) => {
        this.setState(prevState => ({
            productitemconnection: {
                ...prevState.productitemconnection,
                show_when: value
            },
        }));
    };

    handleSaveProductItemConnection = () => {
        if (this.state.productitemconnection.id) {
            this.props.updateProductItemConnection(this.state.productitemconnection);
        } else {
            this.props.createProductItemConnection({...this.state.productitemconnection});
        }
    };

    handleDeleteProductItemConnection = () => {
        if (this.state.productitemconnection.id) {
            this.props.deleteProductItemConnection(this.state.productitemconnection);
        }
    };

    render() {
        let {show, products, handleClose} = this.props;
        let {productitemconnection, message} = this.state;

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Connection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Product</Form.Label>
                        <Select options={products.list.map(p => ({label: `${p.product_id} - ${p.title}`, value: p.id}))}
                                className="bot-field-item-select"
                                value={products.list.map(p => ({
                                    label: p.product_id,
                                    value: p.id
                                })).filter(p => p.value == productitemconnection.product)}
                                onChange={(obj) => this.handleProductItemConnectionChange({
                                    ...productitemconnection,
                                    product: obj.value,
                                })}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.product}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.product}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Item</Form.Label>
                        <Select options={products.list.map(p => ({label: `${p.product_id} - ${p.title}`, value: p.id}))}
                                className="bot-field-item-select"
                                value={products.list.map(p => ({
                                    label: p.product_id,
                                    value: p.id
                                })).filter(p => p.value == productitemconnection.item)}
                                onChange={(obj) => this.handleProductItemConnectionChange({
                                    ...productitemconnection,
                                    item: obj.value,
                                })}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.item}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.item}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control type="integer"
                                      value={productitemconnection.quantity}
                                      onChange={(e) => this.setState({
                                          productitemconnection: {
                                              ...productitemconnection,
                                              quantity: e.target.value
                                          }
                                      })}
                                      isInvalid={!!message && !!message.quantity}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.quantity}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveProductItemConnection}>Save</Button>
                    {
                        this.state.productitemconnection.id &&
                        <ConfirmButton onClick={this.handleDeleteProductItemConnection}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    products: state.quiz.products,
    productitemconnections: state.quiz.productitemconnections,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchProductItemConnection,
    createProductItemConnection,
    updateProductItemConnection,
    deleteProductItemConnection,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ProductItemConnectionEditCreateModal);
