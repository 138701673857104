import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


class UpDownArrowIcon extends PureComponent {

    static propTypes = {
        onClick: PropTypes.func,
        active: PropTypes.bool,
        size: PropTypes.number,
    };

    static defaultProps = {
        active: false,
        size: 30
    };

    render() {
        return (
            <div className={'up-down-arrow-icon ' + (this.props.active ? 'active': '')}
                 onClick={this.props.onClick}>
                <i className="material-icons" style={{fontSize: this.props.size}}>keyboard_arrow_down</i>
            </div>
        );
    }
}


export default UpDownArrowIcon;

