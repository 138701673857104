import {call, put} from 'redux-saga/effects';
import api from "../api";
import {toast} from "react-toastify";

import {
    CREATE_PRODUCT_LANGUAGE_RESULT,
    CREATE_PRODUCT_LANGUAGE_ERROR,
    CREATE_PRODUCT_LANGUAGE_RETRY,

    FETCH_PRODUCT_LANGUAGE_RESULT,
    FETCH_PRODUCT_LANGUAGE_ERROR,
    FETCH_PRODUCT_LANGUAGE_RETRY,

    UPDATE_PRODUCT_LANGUAGE_RESULT,
    UPDATE_PRODUCT_LANGUAGE_ERROR,
    UPDATE_PRODUCT_LANGUAGE_RETRY,

    DELETE_PRODUCT_LANGUAGE_RESULT,
    DELETE_PRODUCT_LANGUAGE_ERROR,
    DELETE_PRODUCT_LANGUAGE_RETRY
} from '../../actions/quiz/productlanguages';


export function* createProductLanguage(action) {
    try {
        const response = yield call(api.quiz.productlanguages.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createProductLanguage', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_PRODUCT_LANGUAGE_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_PRODUCT_LANGUAGE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_PRODUCT_LANGUAGE_RETRY, retry: true, message: e.message});
    }
}

export function* fetchProductLanguage(action) {
    try {
        const response = yield call(api.quiz.productlanguages.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchProductLanguage', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PRODUCT_LANGUAGE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_PRODUCT_LANGUAGE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PRODUCT_LANGUAGE_RETRY, retry: true, message: e.message});
    }
}


export function* updateProductLanguage(action) {
    try {
        const response = yield call(api.quiz.productlanguages.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateProductLanguage', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_PRODUCT_LANGUAGE_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_PRODUCT_LANGUAGE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_PRODUCT_LANGUAGE_RETRY, retry: true, message: e.message});
    }
}


export function* deleteProductLanguage(action) {
    try {
        const response = yield call(api.quiz.productlanguages.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteProductLanguage', action);

        if (response.status === 204) {
            yield put({type: DELETE_PRODUCT_LANGUAGE_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_PRODUCT_LANGUAGE_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_PRODUCT_LANGUAGE_RETRY, retry: true, message: e.message});
    }
}
