import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {
    fetchAnalysisCard,
    updateAnalysisCard,
    createAnalysisCard,
    deleteAnalysisCard
} from "../../../actions/report/analysiscards";
import {connect} from "react-redux";
import Select from "react-select";
import AnalysisCardTextTextEditCreateModal from "../AnalysisCardTextEditCreateModal";
import ListGroup from "react-bootstrap/ListGroup";
import {getHttpsImageUrl} from "../../../utils";
import ConfirmButton from "../../buttons/ConfirmButton";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";


class AnalysisCardEditCreateModal extends React.Component {

    static propTypes = {
        analysiscard: PropTypes.any,
        analysiscards: PropTypes.any,
        createAnalysisCard: PropTypes.func,
        fetchAnalysisCard: PropTypes.func,
        updateAnalysisCard: PropTypes.func,
        deleteAnalysisCard: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            analysiscard: props.analysiscard,
            analysiscardtext: undefined,
            message: undefined,
            field_child: undefined,
            showBuilder: false,
        }
    }

    componentDidMount() {
        if (this.props.analysiscard.id) {
            this.props.fetchAnalysisCard(this.props.analysiscard);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            analysiscards: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry},
            analysiscardtexts
        } = this.props;

        if (!analysiscardtexts.isUpdating && prevProps.analysiscardtexts.isUpdating && !analysiscardtexts.error && !analysiscardtexts.retry) {
            this.props.fetchAnalysisCard(this.props.analysiscard);
        }
        if (!analysiscardtexts.isDeleting && prevProps.analysiscardtexts.isDeleting && !analysiscardtexts.error && !analysiscardtexts.retry) {
            this.props.fetchAnalysisCard(this.props.analysiscard);
        }
        if (!analysiscardtexts.isCreating && prevProps.analysiscardtexts.isCreating && !analysiscardtexts.error && !analysiscardtexts.retry) {
            this.props.fetchAnalysisCard(this.props.analysiscard);
        }

        if (!isFetching && prevProps.analysiscards.isFetching && !error && !retry) {
            this.setState({analysiscard: detail});
        }
        if (!isUpdating && prevProps.analysiscards.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.analysiscards.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.analysiscards.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.analysiscards.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.analysiscards.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleCreateAnalysisCardText = () => {
        this.setState({analysiscardtext: {text: '', expression: '', analysis_card: this.state.analysiscard.id}});
    };

    handleAnalysisCardChange = (analysiscard) => {
        this.setState({analysiscard});
    };

    handleSaveAnalysisCard = () => {
        if (this.state.analysiscard.id) {
            this.props.updateAnalysisCard(this.state.analysiscard);
        } else {
            this.props.createAnalysisCard({...this.state.analysiscard});
        }
    };

    handleDeleteAnalysisCard = () => {
        if (this.state.analysiscard.id) {
            this.props.deleteAnalysisCard(this.state.analysiscard);
        }
    };

    handleAnalysisFileChange = (e) => {
        let {analysiscard} = this.state;
        let file = e.target.files[0];
        this.setState({analysiscard: {...analysiscard, file}});
    };

    handleLanguageChange = (language) => {
        this.setState({language});
    };

    analysisCardType = [
        {label: 'SMALL', value: 'small'},
        {label: 'LARGE', value: 'large'},
        {label: 'SMALL GROUP', value: 'small_group'},
        {label: 'SUMMARY', value: 'summary'},
        {label: 'SUMMARY_FRAME_1', value: 'summary_frame_1'},
        {label: 'SUMMARY_FRAME_2', value: 'summary_frame_2'},
        {label: 'SUMMARY_DOSHA', value: 'summary_dosha'}
    ];

    render() {
        let {show, reportsheets, handleClose} = this.props;
        let {analysiscard, language, message} = this.state;

        let nameKey = 'name' + (language ? language.db_label : '');

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{analysiscard.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LanguageSelectInput onLanguageChange={this.handleLanguageChange}/>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Name"
                                      value={analysiscard[nameKey] || ''}
                                      isInvalid={!!message && !!message[nameKey]}
                                      onChange={(e) => this.handleAnalysisCardChange({
                                          ...analysiscard,
                                          [nameKey]: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message[nameKey]}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Report Sheet</Form.Label>
                        <Select options={reportsheets.list.map(o => ({label: o.name, value: o.id}))}
                                value={reportsheets.list.filter(o => o.id == analysiscard.report_sheet).map(o => ({
                                    label: o.name,
                                    value: o.id
                                }))}
                                onChange={(o) => {
                                    this.handleAnalysisCardChange({
                                        ...analysiscard,
                                        report_sheet: o.value,
                                    })
                                }}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.components}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldType">
                        <Form.Label>Type</Form.Label>
                        <Select options={this.analysisCardType.map(t => ({label: t.label, value: t.value}))}
                                value={this.analysisCardType.filter(t => t.value == analysiscard.type).map(t => ({
                                    label: t.label,
                                    value: t.value
                                }))}
                                onChange={(t) => {
                                    this.handleAnalysisCardChange({
                                        ...analysiscard,
                                        type: t.value,
                                    })
                                }}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.type}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="price">
                        <Form.Label>Icon</Form.Label>
                        <Form.Row>
                            {analysiscard.icon && <img src={getHttpsImageUrl(analysiscard.icon)}
                                                       style={{maxWidth: 200}}
                                                       className="productitem-image" alt=""/>}
                        </Form.Row>
                        <Form.Control type="file"
                                      onChange={this.handleAnalysisFileChange}/>
                    </Form.Group>
                    <h5 className="mt-4 pb-2">
                        Texts
                        {
                            !!analysiscard.id &&
                            <Button size={'sm'} className={'ml-2 float-right'}
                                    onClick={this.handleCreateAnalysisCardText}>Create Analysis Card Text</Button>
                        }
                    </h5>
                    <ListGroup>
                        {
                            !!analysiscard.analysiscardtexts &&
                            analysiscard.analysiscardtexts.map(
                                obj => (
                                    <ListGroup.Item key={obj.id} className="d-flex">
                                        <strong className="flex-fill" dangerouslySetInnerHTML={{__html: obj.text}}/>
                                        <div>
                                            <Button size={'sm'} className={'ml-2 float-right'}
                                                    onClick={() => this.setState({analysiscardtext: obj})}>Edit</Button>
                                        </div>
                                    </ListGroup.Item>
                                )
                            )
                        }
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveAnalysisCard}>Save</Button>
                    {
                        this.state.analysiscard.id &&
                        <ConfirmButton onClick={this.handleDeleteAnalysisCard}/>
                    }
                </Modal.Footer>
                {
                    !!this.state.analysiscardtext &&
                    <AnalysisCardTextTextEditCreateModal show={true}
                                                         analysiscardtext={this.state.analysiscardtext}
                                                         handleClose={() => this.setState({analysiscardtext: undefined})}/>
                }
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    analysiscards: state.report.analysiscards,
    analysiscardtexts: state.report.analysiscardtexts,
    reportsheets: state.report.reportsheets,
    botfields: state.quiz.botfields,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createAnalysisCard,
    fetchAnalysisCard,
    updateAnalysisCard,
    deleteAnalysisCard,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(AnalysisCardEditCreateModal);
