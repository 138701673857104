import {call, put} from 'redux-saga/effects';
import {
    CREATE_MESSAGE_TYPE_RESULT,
    CREATE_MESSAGE_TYPE_ERROR,
    CREATE_MESSAGE_TYPE_RETRY,
    FETCH_MESSAGE_TYPES_RESULT,
    FETCH_MESSAGE_TYPES_ERROR,
    FETCH_MESSAGE_TYPES_RETRY,
    FETCH_MESSAGE_TYPE_RESULT,
    FETCH_MESSAGE_TYPE_ERROR,
    FETCH_MESSAGE_TYPE_RETRY,
    UPDATE_MESSAGE_TYPE_RESULT,
    UPDATE_MESSAGE_TYPE_ERROR,
    UPDATE_MESSAGE_TYPE_RETRY,
    DELETE_MESSAGE_TYPE_RESULT,
    DELETE_MESSAGE_TYPE_ERROR,
    DELETE_MESSAGE_TYPE_RETRY
} from '../../actions/events/messagetypes';
import api from "../api";
import {toast} from "react-toastify";


export function* createMessageType(action) {
    try {
        const response = yield call(api.events.messagetypes.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createMessageType', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_MESSAGE_TYPE_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_MESSAGE_TYPE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_MESSAGE_TYPE_RETRY, retry: true, message: e.message});
    }
}


export function* fetchMessageTypes(action) {
    try {
        const response = yield call(api.events.messagetypes.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchMessageTypes', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_MESSAGE_TYPES_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_MESSAGE_TYPES_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_MESSAGE_TYPES_RETRY, retry: true, message: e.message});
    }
}


export function* fetchMessageType(action) {
    try {
        const response = yield call(api.events.messagetypes.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchMessageType', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_MESSAGE_TYPE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_MESSAGE_TYPE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_MESSAGE_TYPE_RETRY, retry: true, message: e.message});
    }
}


export function* updateMessageType(action) {
    try {
        const response = yield call(api.events.messagetypes.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateMessageType', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_MESSAGE_TYPE_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_MESSAGE_TYPE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_MESSAGE_TYPE_RETRY, retry: true, message: e.message});
    }
}


export function* deleteMessageType(action) {
    try {
        const response = yield call(api.events.messagetypes.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteMessageType', action);

        if (response.status === 204) {
            yield put({type: DELETE_MESSAGE_TYPE_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_MESSAGE_TYPE_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_MESSAGE_TYPE_RETRY, retry: true, message: e.message});
    }
}
