import {call, put} from 'redux-saga/effects';
import {toast} from "react-toastify";

import {
    CREATE_BOT_FORM_RESULT,
    CREATE_BOT_FORM_ERROR,
    CREATE_BOT_FORM_RETRY,

    FETCH_BOT_FORMS_RESULT,
    FETCH_BOT_FORMS_ERROR,
    FETCH_BOT_FORMS_RETRY, FETCH_BOT_FORMS,

    DELETE_BOT_FORM_ERROR,
    DELETE_BOT_FORM_RESULT, DELETE_BOT_FORM_RETRY,
    FETCH_BOT_FORM_ERROR,
    FETCH_BOT_FORM_RESULT,
    FETCH_BOT_FORM_RETRY, UPDATE_BOT_FORM_ERROR,
    UPDATE_BOT_FORM_RESULT, UPDATE_BOT_FORM_RETRY, CLONE_BOT_FORM_RESULT, CLONE_BOT_FORM_ERROR, CLONE_BOT_FORM_RETRY
} from '../../actions/quiz/botforms';
import api from "../api";


export function* createBotForm(action) {
    try {
        const response = yield call(api.quiz.botForms.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createBotForm', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_BOT_FORM_RESULT, data: result});
            yield put({type: FETCH_BOT_FORMS});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_BOT_FORM_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_BOT_FORM_RETRY, retry: true, message: e.message});
    }
}


export function* cloneBotForm(action) {
    try {
        const response = yield call(api.quiz.botForms.clone, action.data);
        const result = yield response.json();
        console.warn('SAGA:cloneBotForm', action, result);

        if (response.status === 200) {
            yield put({type: CLONE_BOT_FORM_RESULT, data: result});
            yield put({type: FETCH_BOT_FORMS});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CLONE_BOT_FORM_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CLONE_BOT_FORM_RETRY, retry: true, message: e.message});
    }
}


export function* fetchBotForms(action) {
    try {
        const response = yield call(api.quiz.botForms.list);
        const result = yield response.json();
        console.warn('SAGA:fetchBotForms', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BOT_FORMS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BOT_FORMS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BOT_FORMS_RETRY, retry: true, message: e.message});
    }
}

export function* fetchBotForm(action) {
    try {
        const response = yield call(api.quiz.botForms.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchBotForm', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_BOT_FORM_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_BOT_FORM_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_BOT_FORM_RETRY, retry: true, message: e.message});
    }
}


export function* updateBotForm(action) {
    try {
        const response = yield call(api.quiz.botForms.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateBotForm', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_BOT_FORM_RESULT, data: result});
            yield put({type: FETCH_BOT_FORMS});
        }
        else {
            yield put({type: UPDATE_BOT_FORM_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_BOT_FORM_RETRY, retry: true, message: e.message});
    }
}


export function* deleteBotForm(action) {
    try {
        const response = yield call(api.quiz.botForms.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteBotForm', action);

        if (response.status === 204) {
            yield put({type: DELETE_BOT_FORM_RESULT, data: action.data});
            yield put({type: FETCH_BOT_FORMS});
        }
        else {
            yield put({type: DELETE_BOT_FORM_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_BOT_FORM_RETRY, retry: true, message: e.message});
    }
}
