export const CREATE_BOT_FORM = 'CREATE_BOT_FORM';
export const CREATE_BOT_FORM_RESULT = 'CREATE_BOT_FORM_RESULT';
export const CREATE_BOT_FORM_ERROR = 'CREATE_BOT_FORM_ERROR';
export const CREATE_BOT_FORM_RETRY = 'CREATE_BOT_FORM_RETRY';

export const CLONE_BOT_FORM = 'CLONE_BOT_FORM';
export const CLONE_BOT_FORM_RESULT = 'CLONE_BOT_FORM_RESULT';
export const CLONE_BOT_FORM_ERROR = 'CLONE_BOT_FORM_ERROR';
export const CLONE_BOT_FORM_RETRY = 'CLONE_BOT_FORM_RETRY';

export const FETCH_BOT_FORMS = 'FETCH_BOT_FORMS';
export const FETCH_BOT_FORMS_RESULT = 'FETCH_BOT_FORMS_RESULT';
export const FETCH_BOT_FORMS_ERROR = 'FETCH_BOT_FORMS_ERROR';
export const FETCH_BOT_FORMS_RETRY = 'FETCH_BOT_FORMS_RETRY';

export const FETCH_BOT_FORM = 'FETCH_BOT_FORM';
export const FETCH_BOT_FORM_RESULT = 'FETCH_BOT_FORM_RESULT';
export const FETCH_BOT_FORM_ERROR = 'FETCH_BOT_FORM_ERROR';
export const FETCH_BOT_FORM_RETRY = 'FETCH_BOT_FORM_RETRY';


export const UPDATE_BOT_FORM = 'UPDATE_BOT_FORM';
export const UPDATE_BOT_FORM_RESULT = 'UPDATE_BOT_FORM_RESULT';
export const UPDATE_BOT_FORM_ERROR = 'UPDATE_BOT_FORM_ERROR';
export const UPDATE_BOT_FORM_RETRY = 'UPDATE_BOT_FORM_RETRY';


export const DELETE_BOT_FORM = 'DELETE_BOT_FORM';
export const DELETE_BOT_FORM_RESULT = 'DELETE_BOT_FORM_RESULT';
export const DELETE_BOT_FORM_ERROR = 'DELETE_BOT_FORM_ERROR';
export const DELETE_BOT_FORM_RETRY = 'DELETE_BOT_FORM_RETRY';



export const cloneBotForm = (data) => ({
    type: CLONE_BOT_FORM,
    data,
});


export const createBotForm = (data) => ({
    type: CREATE_BOT_FORM,
    data,
});

export const fetchBotForms = (data) => ({
    type: FETCH_BOT_FORMS,
    data,
});


export const fetchBotForm = (data) => ({
    type: FETCH_BOT_FORM,
    data,
});

export const updateBotForm = (data) => ({
    type: UPDATE_BOT_FORM,
    data,
});

export const deleteBotForm = (data) => ({
    type: DELETE_BOT_FORM,
    data,
});

