import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import './styles.scss';
import ProductEditCreateModal from "../../components/modals/ProductEditCreateModal";
import {fetchProducts} from "../../actions/quiz/products";
import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";


class Products extends React.Component {

    static propTypes = {
        products: PropTypes.object,
        fetchProducts: PropTypes.func,
    };

    static defaultProps = {
        products: {},
    };

    state = {
        product: undefined,
    };

    componentDidMount() {
        this.props.fetchProducts();
    }

    handleCreateProduct = () => {
        let {params} = this.props.match;
        this.setState({
            product: {
                bot_form_id: params.botFormId,
                product_id: '',
                title: '',
                description: '',
                price: '',
                sale_price: '',
                components: [],
                consultation_required: false,
                select_expression: '',
            }
        });
    };

    render() {
        let {products} = this.props;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Products'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pt-2 text-right">
                            <Button size={'sm'}
                                    onClick={this.handleCreateProduct}>Create Box</Button>
                        </div>
                        <div className="col-12 pt-3">
                            <Table responsive striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product Id</th>
                                    <th>Title</th>
                                    <th>Brand Name</th>
                                    <th>Brand Id</th>
                                    <th>Generic Name</th>
                                    <th>Generic Id</th>
                                    <th>Company Name</th>
                                    <th>Pack Size</th>
                                    <th>Price</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    products.list.map(
                                        (product, index) => (
                                            <tr key={product.id}>
                                                <td>{index + 1}</td>
                                                <td>{product.product_id}</td>
                                                <td>{product.title}</td>
                                                <td>{product.brand_name}</td>
                                                <td>{product.brand_id}</td>
                                                <td>{product.generic_name}</td>
                                                <td>{product.generic_id}</td>
                                                <td>{product.company_name}</td>
                                                <td>{product.pack_size}</td>
                                                <td>{product.price}</td>
                                                <td>
                                                    <Button size={'sm'}
                                                            onClick={() => this.setState({product})}>edit</Button>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        {
                            !!this.state.product &&
                            <ProductEditCreateModal show={true}
                                                    product={this.state.product}
                                                    handleClose={() => this.setState({product: undefined})}/>
                        }
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.quiz.products,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchProducts,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Products);
