import React from 'react';
import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import CardContainer from "../../components/containers/CardContainer";
import {checkPermissions, checkRoles} from "../../permisions";
import {routeList} from "./routes";


export default class Home extends React.Component {

    render() {

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Home'}/>
                <div className="container-fluid">
                    <div className="row">
                        {
                            routeList.filter(r => checkPermissions(r.permissions)).filter(r => checkRoles(r.roles)).map(
                                (route) => (
                                    <div key={route.name} className="col-md-3 p-3">
                                        <CardContainer to={route.url}
                                                       className="text-center d-flex flex-column justify-content-center">
                                            <p className="material-icons">{route.icon}</p>
                                            <p>{route.name}</p>
                                        </CardContainer>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}
