import React from 'react';
import Button from "react-bootstrap/Button";
import './styles.scss';
import * as PropTypes from "prop-types";


export default class TitleEditListItem extends React.Component {

    static propTypes = {
        buttonText: PropTypes.string,
        title: PropTypes.string,
        onEditClick: PropTypes.func,
    };

    static defaultProps = {
        buttonText: 'Edit',
        title: '',
    };

    render() {
        let {title, onEditClick, buttonText} = this.props;
        return (
            <div className="product-item-connection-item">
                <Button className="float-right" size="sm" onClick={onEditClick}>{buttonText}</Button>
                <p>{title}</p>
            </div>
        );
    }
}
