import {
    CREATE_PRODUCT_ITEM_CONNECTION,
    CREATE_PRODUCT_ITEM_CONNECTION_RESULT,
    CREATE_PRODUCT_ITEM_CONNECTION_ERROR,
    CREATE_PRODUCT_ITEM_CONNECTION_RETRY,

    FETCH_PRODUCT_ITEM_CONNECTIONS,
    FETCH_PRODUCT_ITEM_CONNECTIONS_RESULT,
    FETCH_PRODUCT_ITEM_CONNECTIONS_ERROR,
    FETCH_PRODUCT_ITEM_CONNECTIONS_RETRY,

    FETCH_PRODUCT_ITEM_CONNECTION,
    FETCH_PRODUCT_ITEM_CONNECTION_RESULT,
    FETCH_PRODUCT_ITEM_CONNECTION_ERROR,
    FETCH_PRODUCT_ITEM_CONNECTION_RETRY,

    UPDATE_PRODUCT_ITEM_CONNECTION,
    UPDATE_PRODUCT_ITEM_CONNECTION_RESULT,
    UPDATE_PRODUCT_ITEM_CONNECTION_ERROR,
    UPDATE_PRODUCT_ITEM_CONNECTION_RETRY,

    DELETE_PRODUCT_ITEM_CONNECTION,
    DELETE_PRODUCT_ITEM_CONNECTION_RESULT,
    DELETE_PRODUCT_ITEM_CONNECTION_ERROR,
    DELETE_PRODUCT_ITEM_CONNECTION_RETRY,
} from '../../actions/quiz/productitemconnections';
import {removeDuplicateFields} from "../../utils";


const initialState = {
    detail: {},
    list: [],
    isCreating: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_PRODUCT_ITEM_CONNECTION:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_PRODUCT_ITEM_CONNECTION_RESULT:
            let newList = [action.data, ...state.list];
            return {...state, isCreating: false, detail: action.data, list: newList};
        case CREATE_PRODUCT_ITEM_CONNECTION_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_PRODUCT_ITEM_CONNECTION_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_PRODUCT_ITEM_CONNECTIONS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_PRODUCT_ITEM_CONNECTIONS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_PRODUCT_ITEM_CONNECTIONS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_PRODUCT_ITEM_CONNECTIONS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_PRODUCT_ITEM_CONNECTION:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_PRODUCT_ITEM_CONNECTION_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_PRODUCT_ITEM_CONNECTION_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_PRODUCT_ITEM_CONNECTION_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_PRODUCT_ITEM_CONNECTION:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_PRODUCT_ITEM_CONNECTION_RESULT:
            return {
                ...state, isUpdating: false, detail: action.data,
                list: removeDuplicateFields([action.data, ...state.list])
            };
        case UPDATE_PRODUCT_ITEM_CONNECTION_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_PRODUCT_ITEM_CONNECTION_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_PRODUCT_ITEM_CONNECTION:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_PRODUCT_ITEM_CONNECTION_RESULT:
            return {...state, isDeleting: false, list: state.list.filter(o => o.id !== action.data.id)};
        case DELETE_PRODUCT_ITEM_CONNECTION_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_PRODUCT_ITEM_CONNECTION_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
