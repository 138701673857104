import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import {NavLink} from "react-router-dom";


export default class CardContainer extends PureComponent {

    static propTypes = {
        to: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        to: '',
        className: '',
    };

    render() {
        let {to, className, ...props} = this.props;
        if (to) {
            return (
                <NavLink className={`card-container ${className}`} to={to} {...props}>
                    {this.props.children}
                </NavLink>
            )
        }

        return (
            <div className={`card-container ${className}`} {...props} >
                {this.props.children}
            </div>
        )
    }
}
