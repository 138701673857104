import React from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import ReactJson from "react-json-view";


export default class ResponseModal extends React.Component {

    static propTypes = {
        response: PropTypes.object,
        handleCheckOutput: PropTypes.func,
    };

    static defaultProps = {
        response: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            expandResponse: false,
            response: props.response,
        };
    }

    handleResponseChange = (e) => {
        try {
            this.handleUpdateResponse(JSON.parse(e.target.value))
        } catch (e) {
        }
    };

    handleUpdateResponse = (response) => {
        this.setState({response});
    };

    render() {

        return (
            <div className={`expression-response-container ${this.state.expandResponse ? 'expand': ''}`}>
                <Button className="expression-response-ab-1"
                        size="sm"
                        onClick={() => this.setState({expandResponse: !this.state.expandResponse})}>
                    {this.state.expandResponse ? 'Collapse': 'Expand'}
                </Button>
                <Button className="expression-response-ab-2"
                        size="sm"
                        onClick={() => this.props.handleCheckOutput(this.state.response)}>Check output</Button>
                <InputGroup className="mb-3">
                    <FormControl type="text"
                                 value={JSON.stringify(this.state.response)}
                                 onChange={this.handleResponseChange} />
                </InputGroup>
                <ReactJson src={this.state.response}
                           onAdd={(e) => console.warn(e)}
                           onDelete={({updated_src}) => this.handleUpdateResponse(updated_src)}
                           onEdit={({updated_src}) => this.handleUpdateResponse(updated_src)}/>
            </div>
        )
    }
}
