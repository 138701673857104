export const CREATE_EXPRESSION = 'CREATE_EXPRESSION';
export const CREATE_EXPRESSION_RESULT = 'CREATE_EXPRESSION_RESULT';
export const CREATE_EXPRESSION_ERROR = 'CREATE_EXPRESSION_ERROR';
export const CREATE_EXPRESSION_RETRY = 'CREATE_EXPRESSION_RETRY';


export const FETCH_EXPRESSIONS = 'FETCH_EXPRESSIONS';
export const FETCH_EXPRESSIONS_RESULT = 'FETCH_EXPRESSIONS_RESULT';
export const FETCH_EXPRESSIONS_ERROR = 'FETCH_EXPRESSIONS_ERROR';
export const FETCH_EXPRESSIONS_RETRY = 'FETCH_EXPRESSIONS_RETRY';


export const FETCH_EXPRESSION = 'FETCH_EXPRESSION';
export const FETCH_EXPRESSION_RESULT = 'FETCH_EXPRESSION_RESULT';
export const FETCH_EXPRESSION_ERROR = 'FETCH_EXPRESSION_ERROR';
export const FETCH_EXPRESSION_RETRY = 'FETCH_EXPRESSION_RETRY';


export const UPDATE_EXPRESSION = 'UPDATE_EXPRESSION';
export const UPDATE_EXPRESSION_RESULT = 'UPDATE_EXPRESSION_RESULT';
export const UPDATE_EXPRESSION_ERROR = 'UPDATE_EXPRESSION_ERROR';
export const UPDATE_EXPRESSION_RETRY = 'UPDATE_EXPRESSION_RETRY';


export const DELETE_EXPRESSION = 'DELETE_EXPRESSION';
export const DELETE_EXPRESSION_RESULT = 'DELETE_EXPRESSION_RESULT';
export const DELETE_EXPRESSION_ERROR = 'DELETE_EXPRESSION_ERROR';
export const DELETE_EXPRESSION_RETRY = 'DELETE_EXPRESSION_RETRY';


export const createExpression = (data) => ({
    type: CREATE_EXPRESSION,
    data,
});

export const fetchExpressions = (data) => ({
    type: FETCH_EXPRESSIONS,
    data,
});


export const fetchExpression = (data) => ({
    type: FETCH_EXPRESSION,
    data,
});

export const updateExpression = (data) => ({
    type: UPDATE_EXPRESSION,
    data,
});

export const deleteExpression = (data) => ({
    type: DELETE_EXPRESSION,
    data,
});

