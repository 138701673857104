import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';

import './styles.scss';
import {getInnerText, truncChars} from "../../../utils";
import ConnectionItem from "../ConnectionItem";
import ConnectionItemCreate from "../ConnectionItem/ConnectionItemCreate";
import {CHOICE, MULTIPLE_CHOICE} from "../../../constants";
import Button from "react-bootstrap/Button";


export default class BotFieldItem extends PureComponent {

    static propTypes = {
        botfield: PropTypes.object,
        getBotFieldFromId: PropTypes.func,
        handleBotFieldClick: PropTypes.func,
        handleBotFieldConnectionClick: PropTypes.func,
        handleAddConnectionClick: PropTypes.func,
        handleBotFieldCreate: PropTypes.func,
        handleBotFieldTestClick: PropTypes.func,
    };

    getBotFieldName = (botfield_id) => {
        let botfield = this.props.getBotFieldFromId(botfield_id);
        if (botfield)
            return botfield.field_id;
        return `${botfield_id}`;
    };

    render() {
        const {botfield, getBotFieldFromId, index} = this.props;
        return (
            <div className="bot-field-item">
                <div className="bot-field-item-num">{index + 1}</div>
                <div className="bot-field-item-field-id-container">
                    <span className="bot-field-item-field-id"
                          onClick={() => this.props.handleBotFieldClick(botfield.id)}>
                      {botfield.field_id} {!!botfield.show_selection_percentage && !!botfield.selection_percentage && ` --- ${botfield.selection_percentage}%`}
                    </span>
                    <ul>
                        {botfield.field_children.sort((a, b)=>{return a.priority - b.priority}).map(o => (
                            <li key={o.id}
                                className="bot-field-item-field-id-children"
                                onClick={() => this.props.handleBotFieldClick(o.id)}>
                              {o.field_id} {!!o.show_selection_percentage && !!o.selection_percentage && ` --- ${o.selection_percentage}%`}
                            </li>
                        ))}
                        {
                            (botfield.type === CHOICE || botfield.type === MULTIPLE_CHOICE) &&
                            <li className="bot-field-item-field-id-children"
                                onClick={() => this.props.handleBotFieldCreate({field_parent_id: botfield.id})}>add child</li>
                        }
                    </ul>
                </div>
                <div className="bot-field-item-type">{botfield.type}</div>
                <div className="bot-field-item-text">{truncChars(getInnerText(botfield.text), 50)}</div>
                <div className="bot-field-item-predecessors">
                    {
                        botfield.predecessors.map(connection => (
                            <ConnectionItem key={connection.id}
                                            name={this.getBotFieldName(connection.parent_id)}
                                            handleBotFieldClick={() => this.props.handleBotFieldClick(connection.parent_id)}
                                            handleConnectionClick={() => this.props.handleBotFieldConnectionClick(connection.id)}/>
                        ))
                    }
                    <ConnectionItemCreate handleAddConnectionClick={() => {
                        return this.props.handleAddConnectionClick({child_id: botfield.id});
                    }}/>
                </div>
                <div className="bot-field-item-successors">
                    {
                        botfield.successors.map(connection => (
                            <ConnectionItem key={connection.id}
                                            name={this.getBotFieldName(connection.child_id)}
                                            handleBotFieldClick={() => this.props.handleBotFieldClick(connection.child_id)}
                                            handleConnectionClick={() => this.props.handleBotFieldConnectionClick(connection.id)}/>
                        ))
                    }
                    <ConnectionItemCreate handleAddConnectionClick={() => {
                        return this.props.handleAddConnectionClick({parent_id: botfield.id});
                    }}/>
                </div>
                <div className="bot-field-item-height">{botfield.height}</div>
                <div className="bot-field-item-actions">
                    <Button size={'sm'} variant={'dark'}
                            onClick={this.props.handleBotFieldTestClick}>Test</Button>
                </div>


            </div>
        );
    }
}
