import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';

import './styles.scss';


export default class ConnectionItemCreate extends PureComponent {

    static propTypes = {
        handleAddConnectionClick: PropTypes.func,
    };

    render() {

        return (
            <button className="connection-item-add"
                    onClick={this.props.handleAddConnectionClick}>
                <i className="fa fa-plus" style={{fontSize: '1.75em'}}/>
            </button>
        );
    }
}
