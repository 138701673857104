import {
    CREATE_REVIEW,
    CREATE_REVIEW_RESULT,
    CREATE_REVIEW_ERROR,
    CREATE_REVIEW_RETRY,

    FETCH_REVIEWS,
    FETCH_REVIEWS_RESULT,
    FETCH_REVIEWS_ERROR,
    FETCH_REVIEWS_RETRY,

    DELETE_REVIEW,
    DELETE_REVIEW_ERROR,
    DELETE_REVIEW_RESULT,
    DELETE_REVIEW_RETRY,

    FETCH_REVIEW,
    FETCH_REVIEW_ERROR,
    FETCH_REVIEW_RESULT,
    FETCH_REVIEW_RETRY,

    UPDATE_REVIEW,
    UPDATE_REVIEW_ERROR,
    UPDATE_REVIEW_RESULT,
    UPDATE_REVIEW_RETRY
} from '../../actions/cms/reviews';


const initialState = {
    detail: {},
    list: [],
    isCreating: false,
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_REVIEW:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_REVIEW_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_REVIEW_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_REVIEW_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_REVIEWS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_REVIEWS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_REVIEWS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_REVIEWS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_REVIEW:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_REVIEW_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_REVIEW_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_REVIEW_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_REVIEW:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_REVIEW_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_REVIEW_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_REVIEW_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_REVIEW:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_REVIEW_RESULT:
            return {...state, isDeleting: false};
        case DELETE_REVIEW_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_REVIEW_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
