import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {
    fetchReportSheetProduct,
    updateReportSheetProduct,
    createReportSheetProduct,
    deleteReportSheetProduct
} from "../../../actions/report/reportsheetproducts";
import {connect} from "react-redux";
import ExpressionBuilder from "../ExpressionBuilder";
import Select from "react-select";
import ConfirmButton from "../../buttons/ConfirmButton";
import {fetchProducts} from "../../../actions/quiz/products";


class ReportSheetProductEditCreateModal extends React.Component {

    static propTypes = {
        reportsheetproduct: PropTypes.any,
        reportsheetproducts: PropTypes.any,
        createReportSheetProduct: PropTypes.func,
        fetchReportSheetProduct: PropTypes.func,
        updateReportSheetProduct: PropTypes.func,
        deleteReportSheetProduct: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            reportsheetproduct: props.reportsheetproduct,
            message: undefined,
            field_child: undefined,
            showBuilder: false,
        }
    }

    componentDidMount() {
        if (this.props.reportsheetproduct.id) {
            this.props.fetchReportSheetProduct(this.props.reportsheetproduct);
        }

        !this.props.products.list.length && this.props.fetchProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {reportsheetproducts: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;

        if (!isFetching && prevProps.reportsheetproducts.isFetching && !error && !retry) {
            this.setState({reportsheetproduct: detail});
        }
        if (!isUpdating && prevProps.reportsheetproducts.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.reportsheetproducts.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.reportsheetproducts.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.reportsheetproducts.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.reportsheetproducts.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleReportSheetProductChange = (reportsheetproduct) => {
        this.setState({reportsheetproduct});
    };

    handleSaveReportSheetProduct = () => {
        if (this.state.reportsheetproduct.id) {
            this.props.updateReportSheetProduct(this.state.reportsheetproduct);
        } else {
            this.props.createReportSheetProduct({...this.state.reportsheetproduct});
        }
    };

    handleDeleteReportSheetProduct = () => {
        if (this.state.reportsheetproduct.id) {
            this.props.deleteReportSheetProduct(this.state.reportsheetproduct);
        }
    };

    render() {
        let {show, botfields, reportsheets, products, handleClose} = this.props;
        let {reportsheetproduct, message} = this.state;
        let identifiers = botfields.list.map(o => [o.field_id, ...o.field_children.map(i => i.field_id)]);
        identifiers = [].concat(...identifiers) || [];

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{reportsheetproduct.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Report Sheet</Form.Label>
                        <Select options={reportsheets.list.map(o => ({label: o.name, value: o.id}))}
                                value={reportsheets.list.filter(o => o.id == reportsheetproduct.report_sheet).map(o => ({
                                    label: o.name,
                                    value: o.id
                                }))}
                                onChange={(o) => {
                                    this.handleReportSheetProductChange({
                                        ...reportsheetproduct,
                                        report_sheet: o.value,
                                    })
                                }}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.components}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Product</Form.Label>
                        <Select options={products.list.map(p => ({label: `${p.product_id} - ${p.title}`, value: p.id}))}
                                className="bot-field-item-select"
                                value={products.list.map(p => ({
                                    label: p.product_id,
                                    value: p.id
                                })).filter(p => p.value == reportsheetproduct.product)}
                                onChange={(obj) => this.handleReportSheetProductChange({
                                    ...reportsheetproduct,
                                    product: obj.value,
                                })}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.item}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.product}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="expression">
                        <Form.Label>Expression</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      disabled={true}
                                      value={reportsheetproduct.select_expression || ""}/>
                        <Button onClick={() => this.setState({showBuilder: true})}>Change Value</Button>
                        <ExpressionBuilder identifiers={identifiers}
                                           expression={reportsheetproduct.select_expression || ""}
                                           handleExpressionChange={(select_expression => this.handleReportSheetProductChange({
                                               ...reportsheetproduct,
                                               select_expression
                                           }))}
                                           show={this.state.showBuilder}
                                           handleClose={() => this.setState({showBuilder: false})}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveReportSheetProduct}>Save</Button>
                    {
                        this.state.reportsheetproduct.id &&
                        <ConfirmButton onClick={this.handleDeleteReportSheetProduct}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    reportsheetproducts: state.report.reportsheetproducts,
    reportsheets: state.report.reportsheets,
    botfields: state.quiz.botfields,
    products: state.quiz.products,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createReportSheetProduct,
    fetchReportSheetProduct,
    updateReportSheetProduct,
    deleteReportSheetProduct,
    fetchProducts,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ReportSheetProductEditCreateModal);
