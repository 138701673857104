export const CREATE_REPORT_SHEET = 'CREATE_REPORT_SHEET';
export const CREATE_REPORT_SHEET_RESULT = 'CREATE_REPORT_SHEET_RESULT';
export const CREATE_REPORT_SHEET_ERROR = 'CREATE_REPORT_SHEET_ERROR';
export const CREATE_REPORT_SHEET_RETRY = 'CREATE_REPORT_SHEET_RETRY';


export const FETCH_REPORT_SHEETS = 'FETCH_REPORT_SHEETS';
export const FETCH_REPORT_SHEETS_RESULT = 'FETCH_REPORT_SHEETS_RESULT';
export const FETCH_REPORT_SHEETS_ERROR = 'FETCH_REPORT_SHEETS_ERROR';
export const FETCH_REPORT_SHEETS_RETRY = 'FETCH_REPORT_SHEETS_RETRY';


export const FETCH_REPORT_SHEET = 'FETCH_REPORT_SHEET';
export const FETCH_REPORT_SHEET_RESULT = 'FETCH_REPORT_SHEET_RESULT';
export const FETCH_REPORT_SHEET_ERROR = 'FETCH_REPORT_SHEET_ERROR';
export const FETCH_REPORT_SHEET_RETRY = 'FETCH_REPORT_SHEET_RETRY';


export const UPDATE_REPORT_SHEET = 'UPDATE_REPORT_SHEET';
export const UPDATE_REPORT_SHEET_RESULT = 'UPDATE_REPORT_SHEET_RESULT';
export const UPDATE_REPORT_SHEET_ERROR = 'UPDATE_REPORT_SHEET_ERROR';
export const UPDATE_REPORT_SHEET_RETRY = 'UPDATE_REPORT_SHEET_RETRY';


export const DELETE_REPORT_SHEET = 'DELETE_REPORT_SHEET';
export const DELETE_REPORT_SHEET_RESULT = 'DELETE_REPORT_SHEET_RESULT';
export const DELETE_REPORT_SHEET_ERROR = 'DELETE_REPORT_SHEET_ERROR';
export const DELETE_REPORT_SHEET_RETRY = 'DELETE_REPORT_SHEET_RETRY';


export const createReportSheet = (data) => ({
    type: CREATE_REPORT_SHEET,
    data,
});

export const fetchReportSheets = (data) => ({
    type: FETCH_REPORT_SHEETS,
    data,
});


export const fetchReportSheet = (data) => ({
    type: FETCH_REPORT_SHEET,
    data,
});

export const updateReportSheet = (data) => ({
    type: UPDATE_REPORT_SHEET,
    data,
});

export const deleteReportSheet = (data) => ({
    type: DELETE_REPORT_SHEET,
    data,
});
