import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {fetchRedFlag, updateRedFlag, createRedFlag, deleteRedFlag} from "../../../actions/report/redflags";
import {connect} from "react-redux";
import ExpressionBuilder from "../ExpressionBuilder";
import Select from "react-select";
import ConfirmButton from "../../buttons/ConfirmButton";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";


class RedFlagEditCreateModal extends React.Component {

    static propTypes = {
        redflag: PropTypes.any,
        redflags: PropTypes.any,
        createRedFlag: PropTypes.func,
        fetchRedFlag: PropTypes.func,
        updateRedFlag: PropTypes.func,
        deleteRedFlag: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            redflag: props.redflag,
            message: undefined,
            field_child: undefined,
            showBuilder: false,
            language: undefined,
        }
    }

    componentDidMount() {
        if (this.props.redflag.id) {
            this.props.fetchRedFlag(this.props.redflag);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {redflags: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;

        if (!isFetching && prevProps.redflags.isFetching && !error && !retry) {
            this.setState({redflag: detail});
        }
        if (!isUpdating && prevProps.redflags.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.redflags.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.redflags.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.redflags.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.redflags.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleRedFlagChange = (redflag) => {
        this.setState({redflag});
    };

    handleSaveRedFlag = () => {
        if (this.state.redflag.id) {
            this.props.updateRedFlag(this.state.redflag);
        } else {
            this.props.createRedFlag({...this.state.redflag});
        }
    };

    handleDeleteRedFlag = () => {
        if (this.state.redflag.id) {
            this.props.deleteRedFlag(this.state.redflag);
        }
    };

    handleLanguageChange = (language) => {
        this.setState({language});
    };

    render() {
        let {show, botfields, reportsheets, handleClose} = this.props;
        let {redflag, message, language} = this.state;
        let identifiers = botfields.list.map(o => [o.field_id, ...o.field_children.map(i => i.field_id)]);
        identifiers = [].concat(...identifiers) || [];

        let nameKey = 'name' + (language ? language.db_label: '');

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{redflag.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LanguageSelectInput onLanguageChange={this.handleLanguageChange}/>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Name"
                                      value={redflag[nameKey] || ''}
                                      isInvalid={!!message && !!message[nameKey]}
                                      onChange={(e) => this.handleRedFlagChange({
                                          ...redflag,
                                          [nameKey]: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message[nameKey]}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Report Sheet</Form.Label>
                        <Select options={reportsheets.list.map(o => ({label: o.name, value: o.id}))}
                                value={reportsheets.list.filter(o => o.id == redflag.report_sheet).map(o => ({label: o.name, value: o.id}))}
                                onChange={(o) => {
                                    this.handleRedFlagChange({
                                        ...redflag,
                                        report_sheet: o.value,
                                    })
                                }}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.report_sheet}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldText">
                        <Form.Label>Expression</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      disabled={true}
                                      value={redflag.expression || ""}/>
                        <Button onClick={() => this.setState({showBuilder: true})}>Change Value</Button>
                        <ExpressionBuilder identifiers={identifiers}
                                           expression={redflag.expression || ""}
                                           handleExpressionChange={(expression => this.handleRedFlagChange({
                                               ...redflag,
                                               expression
                                           }))}
                                           show={this.state.showBuilder}
                                           handleClose={() => this.setState({showBuilder: false})}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveRedFlag}>Save</Button>
                    {
                        this.state.redflag.id &&
                        <ConfirmButton onClick={this.handleDeleteRedFlag}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    redflags: state.report.redflags,
    reportsheets: state.report.reportsheets,
    botfields: state.quiz.botfields,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createRedFlag,
    fetchRedFlag,
    updateRedFlag,
    deleteRedFlag,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(RedFlagEditCreateModal);
