import React from 'react';
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";

import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import {bindActionCreators} from "redux";
import {fetchReviews} from "../../actions/cms/reviews";
import Table from "react-bootstrap/Table";
import Icon from "../../components/others/Icon";
import ReviewEditCreateModal from "../../components/modals/ReviewEditCreateModal";


class Reviews extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.props.fetchReviews();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {reviews: {isCreating, isUpdating, isDeleting, error, retry}} = this.props;

        if ((!isCreating && prevProps.reviews.isCreating && !error && !retry) ||
            (!isUpdating && prevProps.reviews.isUpdating && !error && !retry) ||
            (!isDeleting && prevProps.reviews.isDeleting && !error && !retry)) {
            this.props.fetchReviews();
        }
    }

    handleCreateReview = () => {
        this.setState({
            review: {
                name: '',
                title: '',
                text: '',
                star: null,
                verified_purchase: false,
            }
        });
    };

    render() {
        let {reviews} = this.props;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Reviews'}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pt-4 text-right">
                            <Button variant="primary" size="sm"
                                    onClick={this.handleCreateReview}>Add Review</Button>
                        </div>
                        <div className="col-12 pt-3">
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Text</th>
                                    <th>Star</th>
                                    <th>Verified</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    reviews.list.map(
                                        (review, index) => (
                                            <tr key={review.id}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {review.name}
                                                </td>
                                                <td>
                                                    {review.text}
                                                </td>
                                                <td>
                                                    {review.star}
                                                </td>
                                                <td>
                                                    <Icon
                                                        name={review.verified_purchase ? 'check_circle_outline' : 'highlight_off'}
                                                        color={review.verified_purchase ? '#00a700' : '#F00'}/>
                                                </td>
                                                <td>
                                                    <Button onClick={() => this.setState({review})}
                                                            variant="success" size="sm">
                                                        Edit
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        {
                            !!this.state.review &&
                            <ReviewEditCreateModal show={true}
                                                   review={this.state.review}
                                                   handleClose={() => this.setState({review: undefined})}/>
                        }
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}


const mapStateToProps = (state) => ({
    reviews: state.cms.reviews,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchReviews,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Reviews);
