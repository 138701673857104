import React, {PureComponent, Component} from 'react';
import PropTypes from 'prop-types';

import SuggestionInput from '../../inputs/SuggestionInput';
import DeleteIcon from '../../icons/DeleteIcon';
import UpDownArrowIcon from '../../icons/UpDownArrowIcon';
import {expressionToTreeData, treeDataToExpression, createTreeNode, functions} from '../../../utils';
import Select from "react-select";
import './styles.scss';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import ResponseModal from "../ResponseModal";
import api from "../../../sagas/api";


class ExpressionListItem extends Component {

    static propTypes = {
        identifiers: PropTypes.array.isRequired,
        expression: PropTypes.object,
        handleExpressionTree: PropTypes.func,
        addExpressionGroup: PropTypes.func,
        addExpression: PropTypes.func,
        removeExpression: PropTypes.func,
        handleClose: PropTypes.func,
    };

    state = {
        expand: true,
    };

    render() {
        const {
            expression = {}, handleExpressionTree,
            addExpressionGroup, addExpression,
            removeExpression, identifiers, ...props
        } = this.props;

        if (expression.children)
            return (
                <div className='expression-list-item'>
                    <div className='expression-list-item-group-header'>
                        <div className="group-header-left">
                            <DeleteIcon onClick={() => removeExpression(expression.expressionId)}/>
                            <Button variant='primary'
                                    size="sm"
                                    className="mr-1"
                                    onClick={() => addExpressionGroup(expression.expressionId)}>Add Function
                            </Button>
                            <Button variant='primary'
                                    size="sm"
                                    onClick={() => addExpression(expression.expressionId)}>Add Argument
                            </Button>
                            <UpDownArrowIcon active={this.state.expand}
                                             size={18}
                                             onClick={() => this.setState(prevState => ({expand: !prevState.expand}))}/>
                        </div>
                        <div className="group-header-right">
                            {
                                expression.name &&
                                <Select value={[{label: expression.name, value: expression.name}]}
                                        className={"expression-select"}
                                        options={Object.values(functions).map(o => ({label: o.name, value: o.name}))}
                                        placeholder=''
                                        onChange={(o) => handleExpressionTree(expression.expressionId, o.value)}/>
                            }
                        </div>
                    </div>
                    <div className={'expression-children ' + (this.state.expand ? '' : 'collapse')}>
                        {
                            expression.children &&
                            expression.children.length > 0 &&
                            expression.children.map(
                                (child, index) => (
                                    <ExpressionListItem key={index} expression={child}
                                                        identifiers={identifiers}
                                                        handleExpressionTree={handleExpressionTree}
                                                        addExpressionGroup={addExpressionGroup}
                                                        addExpression={addExpression}
                                                        removeExpression={removeExpression}/>
                                )
                            )
                        }
                    </div>
                </div>
            );
        else {

            return (
                <div className='expression-list-item-element'>
                    <SuggestionInput placeholder='argument' value={expression.name}
                                     onChange={(value) => handleExpressionTree(expression.expressionId, value)}
                                     suggestions={identifiers}/>
                    {/*<input type="text" placeholder='argument' value={expression.name}*/}
                    {/*onChange={(e) => handleExpressionTree(expression.expressionId, e.target.value)}/>*/}
                    <DeleteIcon onClick={() => removeExpression(expression.expressionId)}/>
                </div>
            )
        }
    }
}


class ExpressionBuilder extends PureComponent {

    static propTypes = {
        identifiers: PropTypes.array,
        expression: PropTypes.string,
        handleExpressionChange: PropTypes.func,
        handleClose: PropTypes.func,
    };

    state = {
        expression: '',
        expressionTree: {},
        result: {},
    };

    handleInputChange = (e) => {
        console.log(e.target.value)
    };

    componentDidMount() {
        const {expression} = this.props;
        let expressionTree;

        if (expression.length === 0) {
            expressionTree = {
                name: 'OR',
                expressionId: [],
                children: [],
            }
        } else {
            expressionTree = expressionToTreeData(expression, 0, expression.length - 1);
        }
        // console.log(expressionTree);
        this.setState({expression, expressionTree});
    }

    componentDidUpdate(prevProps, prevState) {
        const {expression} = this.props;
        if (expression && expression !== prevProps.expression) {
            const expressionTree = expressionToTreeData(expression, 0, expression.length - 1);
            console.log(expressionTree);
            this.setState({expressionTree, expression});
        }
    }

    saveExpressionBuilder = () => {
        this.props.handleExpressionChange(this.state.expression);
        this.props.handleClose();
    };

    getNewExpression = (expressionId, expressionTree) => {
        let newExpression = expressionTree;
        expressionId.map(
            (id, index) => {
                newExpression = newExpression.children[id];
            }
        );
        return newExpression;
    };

    removeNewExpression = (expressionId, expressionTree) => {
        let newExpression = expressionTree;
        if (expressionId.length === 0) {
            expressionTree = {};
        } else {
            expressionId.map(
                (id, index) => {
                    console.log(index, newExpression.children.length);
                    if (index === expressionId.length - 1) {
                        for (let i = id + 1; i < newExpression.children.length; i++) {
                            let {expressionId} = newExpression.children[i];
                            expressionId[expressionId.length - 1] = expressionId[expressionId.length - 1] - 1;
                        }
                        newExpression.children.splice(id, 1);
                    } else {
                        newExpression = newExpression.children[id];
                    }
                }
            );
        }
        return expressionTree;
    };

    handleExpressionChange = (e) => {
        this.setState({expression: e.target.value});
    };

    handleExpressionTree = (expressionId, name) => {
        const {expressionTree} = this.state;
        let newExpression = this.getNewExpression(expressionId, expressionTree);
        newExpression.name = name;
        // console.log(newExpression, expressionTree);
        this.setState({expressionTree, expression: treeDataToExpression(expressionTree)});
    };

    addExpressionGroup = (expressionId) => {
        const {expressionTree} = this.state;

        if (typeof expressionId === 'undefined') {
            let newExpressionTree = {
                name: 'OR',
                expressionId: [],
                children: [],
            };
            this.setState({expressionTree: newExpressionTree, expression: treeDataToExpression(newExpressionTree)});
        } else if (expressionId.length >= 0) {
            let newExpression = this.getNewExpression(expressionId, expressionTree);

            if (!functions[newExpression.name].params ||
                newExpression.children.length < functions[newExpression.name].params) {
                let newNode = createTreeNode(functions.OR.name);
                newNode.children = [];
                newExpression.children.push(newNode);
                newNode.expressionId = [...expressionId, newExpression.children.length - 1];
            } else {
                alert(`This function only supports ${functions[newExpression.name].params} parameters`);
            }
            this.setState({expressionTree, expression: treeDataToExpression(expressionTree)});
        }
    };

    addExpression = (expressionId) => {
        const {expressionTree} = this.state;
        let newExpression = this.getNewExpression(expressionId, expressionTree);

        // console.log(newExpression);

        if (!functions[newExpression.name].params ||
            newExpression.children.length < functions[newExpression.name].params) {
            let newNode = createTreeNode('');
            newExpression.children.push(newNode);
            newNode.expressionId = [...expressionId, newExpression.children.length - 1];
        } else {
            alert(`This function only supports ${functions[newExpression.name].params} parameters`);
        }

        this.setState({expressionTree, expression: treeDataToExpression(expressionTree)});
    };

    removeExpression = (expressionId) => {
        console.log(expressionId);
        let {expressionTree} = this.state;
        expressionTree = this.removeNewExpression(expressionId, expressionTree);

        let expression = treeDataToExpression(expressionTree);
        this.setState({expressionTree, expression});
    };

    handleTreeUpdate = () => {
        let expressionTree = expressionToTreeData(this.state.expression, 0, this.state.expression.length - 1);
        this.setState({expressionTree});
    };

    handleCheckOutput = async (response) => {
        let {expression} = this.state;
        console.log('handleCheckOutput: ', expression);
        let res = await api.quiz.expressions.check({response, expression});
        console.log(res.status);
        let result = await res.json();
        console.log(result);

        this.setState({result});
    };

    render() {

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Build Expression
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ResponseModal handleCheckOutput={this.handleCheckOutput}/>
                    <InputGroup className="mb-3" size="sm">
                        <FormControl
                            placeholder="Write Expression"
                            aria-label="Write Expression"
                            aria-describedby="basic-addon2"
                            value={this.state.expression}
                            onChange={this.handleExpressionChange}
                        />
                        <InputGroup.Append>
                            <Button onClick={this.handleTreeUpdate}>Update</Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <p>Result - {JSON.stringify(this.state.result)}</p>
                    {
                        this.state.expression.length === 0 &&
                        <Button variant="primary"
                                size="sm"
                                onClick={() => this.addExpressionGroup()}>Add Function</Button>
                    }
                    {
                        <div id="treeWrapper">
                            {
                                this.state.expression.length > 0 &&
                                Object.keys(this.state.expressionTree).length > 0 &&
                                <ExpressionListItem identifiers={this.props.identifiers}
                                                    expression={this.state.expressionTree}
                                                    handleExpressionTree={this.handleExpressionTree}
                                                    addExpressionGroup={this.addExpressionGroup}
                                                    addExpression={this.addExpression}
                                                    removeExpression={this.removeExpression}/>
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {/*<FormInput type='button' placeholder='Save' onClick={this.saveExpressionBuilder}/>*/}
                    <Button onClick={this.saveExpressionBuilder}>Save</Button>
                    <Button onClick={this.props.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


export default ExpressionBuilder;
