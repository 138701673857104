export const CREATE_REPORT_SHEET_PRODUCT = 'CREATE_REPORT_SHEET_PRODUCT';
export const CREATE_REPORT_SHEET_PRODUCT_RESULT = 'CREATE_REPORT_SHEET_PRODUCT_RESULT';
export const CREATE_REPORT_SHEET_PRODUCT_ERROR = 'CREATE_REPORT_SHEET_PRODUCT_ERROR';
export const CREATE_REPORT_SHEET_PRODUCT_RETRY = 'CREATE_REPORT_SHEET_PRODUCT_RETRY';


export const FETCH_REPORT_SHEET_PRODUCTS = 'FETCH_REPORT_SHEET_PRODUCTS';
export const FETCH_REPORT_SHEET_PRODUCTS_RESULT = 'FETCH_REPORT_SHEET_PRODUCTS_RESULT';
export const FETCH_REPORT_SHEET_PRODUCTS_ERROR = 'FETCH_REPORT_SHEET_PRODUCTS_ERROR';
export const FETCH_REPORT_SHEET_PRODUCTS_RETRY = 'FETCH_REPORT_SHEET_PRODUCTS_RETRY';


export const FETCH_REPORT_SHEET_PRODUCT = 'FETCH_REPORT_SHEET_PRODUCT';
export const FETCH_REPORT_SHEET_PRODUCT_RESULT = 'FETCH_REPORT_SHEET_PRODUCT_RESULT';
export const FETCH_REPORT_SHEET_PRODUCT_ERROR = 'FETCH_REPORT_SHEET_PRODUCT_ERROR';
export const FETCH_REPORT_SHEET_PRODUCT_RETRY = 'FETCH_REPORT_SHEET_PRODUCT_RETRY';


export const UPDATE_REPORT_SHEET_PRODUCT = 'UPDATE_REPORT_SHEET_PRODUCT';
export const UPDATE_REPORT_SHEET_PRODUCT_RESULT = 'UPDATE_REPORT_SHEET_PRODUCT_RESULT';
export const UPDATE_REPORT_SHEET_PRODUCT_ERROR = 'UPDATE_REPORT_SHEET_PRODUCT_ERROR';
export const UPDATE_REPORT_SHEET_PRODUCT_RETRY = 'UPDATE_REPORT_SHEET_PRODUCT_RETRY';


export const DELETE_REPORT_SHEET_PRODUCT = 'DELETE_REPORT_SHEET_PRODUCT';
export const DELETE_REPORT_SHEET_PRODUCT_RESULT = 'DELETE_REPORT_SHEET_PRODUCT_RESULT';
export const DELETE_REPORT_SHEET_PRODUCT_ERROR = 'DELETE_REPORT_SHEET_PRODUCT_ERROR';
export const DELETE_REPORT_SHEET_PRODUCT_RETRY = 'DELETE_REPORT_SHEET_PRODUCT_RETRY';


export const createReportSheetProduct = (data) => ({
    type: CREATE_REPORT_SHEET_PRODUCT,
    data,
});

export const fetchReportSheetProducts = (data) => ({
    type: FETCH_REPORT_SHEET_PRODUCTS,
    data,
});


export const fetchReportSheetProduct = (data) => ({
    type: FETCH_REPORT_SHEET_PRODUCT,
    data,
});

export const updateReportSheetProduct = (data) => ({
    type: UPDATE_REPORT_SHEET_PRODUCT,
    data,
});

export const deleteReportSheetProduct = (data) => ({
    type: DELETE_REPORT_SHEET_PRODUCT,
    data,
});
