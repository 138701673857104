import {call, put} from 'redux-saga/effects';
import api from "../api";
import {toast} from "react-toastify";

import {
    CREATE_REVIEW_RESULT,
    CREATE_REVIEW_ERROR,
    CREATE_REVIEW_RETRY,

    FETCH_REVIEWS_RESULT,
    FETCH_REVIEWS_ERROR,
    FETCH_REVIEWS_RETRY,

    FETCH_REVIEW_RESULT,
    FETCH_REVIEW_ERROR,
    FETCH_REVIEW_RETRY,

    UPDATE_REVIEW_RESULT,
    UPDATE_REVIEW_ERROR,
    UPDATE_REVIEW_RETRY,

    DELETE_REVIEW_RESULT,
    DELETE_REVIEW_ERROR,
    DELETE_REVIEW_RETRY,
} from '../../actions/cms/reviews';


export function* createReview(action) {
    try {
        const response = yield call(api.cms.reviews.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createReview', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_REVIEW_RESULT, data: result});
        }
        else {
            if (result.message) {
                toast(result.message, );
            }
            yield put({type: CREATE_REVIEW_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_REVIEW_RETRY, retry: true, message: e.message});
    }
}


export function* fetchReviews(action) {
    try {
        const response = yield call(api.cms.reviews.list);
        const result = yield response.json();
        console.warn('SAGA:fetchReviews', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REVIEWS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REVIEWS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REVIEWS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchReview(action) {
    try {
        const response = yield call(api.cms.reviews.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReview', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REVIEW_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REVIEW_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REVIEW_RETRY, retry: true, message: e.message});
    }
}


export function* updateReview(action) {
    try {
        const response = yield call(api.cms.reviews.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateReview', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_REVIEW_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_REVIEW_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_REVIEW_RETRY, retry: true, message: e.message});
    }
}


export function* deleteReview(action) {
    try {
        const response = yield call(api.cms.reviews.remove, action.data);
        console.warn('SAGA:deleteReview', action);

        if (response.status === 204) {
            yield put({type: DELETE_REVIEW_RESULT});
        }
        else {
            yield put({type: DELETE_REVIEW_ERROR, error: true});
        }
    } catch (e) {
        yield put({type: DELETE_REVIEW_RETRY, retry: true, message: e.message});
    }
}
