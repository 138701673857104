export const CREATE_PRODUCT_QUANTITY_OFFER = 'CREATE_PRODUCT_QUANTITY_OFFER';
export const CREATE_PRODUCT_QUANTITY_OFFER_RESULT = 'CREATE_PRODUCT_QUANTITY_OFFER_RESULT';
export const CREATE_PRODUCT_QUANTITY_OFFER_ERROR = 'CREATE_PRODUCT_QUANTITY_OFFER_ERROR';
export const CREATE_PRODUCT_QUANTITY_OFFER_RETRY = 'CREATE_PRODUCT_QUANTITY_OFFER_RETRY';


export const FETCH_PRODUCT_QUANTITY_OFFER = 'FETCH_PRODUCT_QUANTITY_OFFER';
export const FETCH_PRODUCT_QUANTITY_OFFER_RESULT = 'FETCH_PRODUCT_QUANTITY_OFFER_RESULT';
export const FETCH_PRODUCT_QUANTITY_OFFER_ERROR = 'FETCH_PRODUCT_QUANTITY_OFFER_ERROR';
export const FETCH_PRODUCT_QUANTITY_OFFER_RETRY = 'FETCH_PRODUCT_QUANTITY_OFFER_RETRY';


export const UPDATE_PRODUCT_QUANTITY_OFFER = 'UPDATE_PRODUCT_QUANTITY_OFFER';
export const UPDATE_PRODUCT_QUANTITY_OFFER_RESULT = 'UPDATE_PRODUCT_QUANTITY_OFFER_RESULT';
export const UPDATE_PRODUCT_QUANTITY_OFFER_ERROR = 'UPDATE_PRODUCT_QUANTITY_OFFER_ERROR';
export const UPDATE_PRODUCT_QUANTITY_OFFER_RETRY = 'UPDATE_PRODUCT_QUANTITY_OFFER_RETRY';


export const DELETE_PRODUCT_QUANTITY_OFFER = 'DELETE_PRODUCT_QUANTITY_OFFER';
export const DELETE_PRODUCT_QUANTITY_OFFER_RESULT = 'DELETE_PRODUCT_QUANTITY_OFFER_RESULT';
export const DELETE_PRODUCT_QUANTITY_OFFER_ERROR = 'DELETE_PRODUCT_QUANTITY_OFFER_ERROR';
export const DELETE_PRODUCT_QUANTITY_OFFER_RETRY = 'DELETE_PRODUCT_QUANTITY_OFFER_RETRY';


export const createProductQuantityOffer = (data) => ({
    type: CREATE_PRODUCT_QUANTITY_OFFER,
    data,
});

export const fetchProductQuantityOffer = (data) => ({
    type: FETCH_PRODUCT_QUANTITY_OFFER,
    data,
});

export const updateProductQuantityOffer = (data) => ({
    type: UPDATE_PRODUCT_QUANTITY_OFFER,
    data,
});

export const deleteProductQuantityOffer = (data) => ({
    type: DELETE_PRODUCT_QUANTITY_OFFER,
    data,
});

