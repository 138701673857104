export const CREATE_ANALYSIS_CARD = 'CREATE_ANALYSIS_CARD';
export const CREATE_ANALYSIS_CARD_RESULT = 'CREATE_ANALYSIS_CARD_RESULT';
export const CREATE_ANALYSIS_CARD_ERROR = 'CREATE_ANALYSIS_CARD_ERROR';
export const CREATE_ANALYSIS_CARD_RETRY = 'CREATE_ANALYSIS_CARD_RETRY';


export const FETCH_ANALYSIS_CARDS = 'FETCH_ANALYSIS_CARDS';
export const FETCH_ANALYSIS_CARDS_RESULT = 'FETCH_ANALYSIS_CARDS_RESULT';
export const FETCH_ANALYSIS_CARDS_ERROR = 'FETCH_ANALYSIS_CARDS_ERROR';
export const FETCH_ANALYSIS_CARDS_RETRY = 'FETCH_ANALYSIS_CARDS_RETRY';


export const FETCH_ANALYSIS_CARD = 'FETCH_ANALYSIS_CARD';
export const FETCH_ANALYSIS_CARD_RESULT = 'FETCH_ANALYSIS_CARD_RESULT';
export const FETCH_ANALYSIS_CARD_ERROR = 'FETCH_ANALYSIS_CARD_ERROR';
export const FETCH_ANALYSIS_CARD_RETRY = 'FETCH_ANALYSIS_CARD_RETRY';


export const UPDATE_ANALYSIS_CARD = 'UPDATE_ANALYSIS_CARD';
export const UPDATE_ANALYSIS_CARD_RESULT = 'UPDATE_ANALYSIS_CARD_RESULT';
export const UPDATE_ANALYSIS_CARD_ERROR = 'UPDATE_ANALYSIS_CARD_ERROR';
export const UPDATE_ANALYSIS_CARD_RETRY = 'UPDATE_ANALYSIS_CARD_RETRY';


export const DELETE_ANALYSIS_CARD = 'DELETE_ANALYSIS_CARD';
export const DELETE_ANALYSIS_CARD_RESULT = 'DELETE_ANALYSIS_CARD_RESULT';
export const DELETE_ANALYSIS_CARD_ERROR = 'DELETE_ANALYSIS_CARD_ERROR';
export const DELETE_ANALYSIS_CARD_RETRY = 'DELETE_ANALYSIS_CARD_RETRY';


export const createAnalysisCard = (data) => ({
    type: CREATE_ANALYSIS_CARD,
    data,
});

export const fetchAnalysisCards = (data) => ({
    type: FETCH_ANALYSIS_CARDS,
    data,
});


export const fetchAnalysisCard = (data) => ({
    type: FETCH_ANALYSIS_CARD,
    data,
});

export const updateAnalysisCard = (data) => ({
    type: UPDATE_ANALYSIS_CARD,
    data,
});

export const deleteAnalysisCard = (data) => ({
    type: DELETE_ANALYSIS_CARD,
    data,
});
