export const CREATE_PRODUCT_LANGUAGE = 'CREATE_PRODUCT_LANGUAGE';
export const CREATE_PRODUCT_LANGUAGE_RESULT = 'CREATE_PRODUCT_LANGUAGE_RESULT';
export const CREATE_PRODUCT_LANGUAGE_ERROR = 'CREATE_PRODUCT_LANGUAGE_ERROR';
export const CREATE_PRODUCT_LANGUAGE_RETRY = 'CREATE_PRODUCT_LANGUAGE_RETRY';


export const FETCH_PRODUCT_LANGUAGE = 'FETCH_PRODUCT_LANGUAGE';
export const FETCH_PRODUCT_LANGUAGE_RESULT = 'FETCH_PRODUCT_LANGUAGE_RESULT';
export const FETCH_PRODUCT_LANGUAGE_ERROR = 'FETCH_PRODUCT_LANGUAGE_ERROR';
export const FETCH_PRODUCT_LANGUAGE_RETRY = 'FETCH_PRODUCT_LANGUAGE_RETRY';


export const UPDATE_PRODUCT_LANGUAGE = 'UPDATE_PRODUCT_LANGUAGE';
export const UPDATE_PRODUCT_LANGUAGE_RESULT = 'UPDATE_PRODUCT_LANGUAGE_RESULT';
export const UPDATE_PRODUCT_LANGUAGE_ERROR = 'UPDATE_PRODUCT_LANGUAGE_ERROR';
export const UPDATE_PRODUCT_LANGUAGE_RETRY = 'UPDATE_PRODUCT_LANGUAGE_RETRY';


export const DELETE_PRODUCT_LANGUAGE = 'DELETE_PRODUCT_LANGUAGE';
export const DELETE_PRODUCT_LANGUAGE_RESULT = 'DELETE_PRODUCT_LANGUAGE_RESULT';
export const DELETE_PRODUCT_LANGUAGE_ERROR = 'DELETE_PRODUCT_LANGUAGE_ERROR';
export const DELETE_PRODUCT_LANGUAGE_RETRY = 'DELETE_PRODUCT_LANGUAGE_RETRY';


export const createProductLanguage = (data) => ({
    type: CREATE_PRODUCT_LANGUAGE,
    data,
});

export const fetchProductLanguage = (data) => ({
    type: FETCH_PRODUCT_LANGUAGE,
    data,
});

export const updateProductLanguage = (data) => ({
    type: UPDATE_PRODUCT_LANGUAGE,
    data,
});

export const deleteProductLanguage = (data) => ({
    type: DELETE_PRODUCT_LANGUAGE,
    data,
});

