export const CREATE_BOT_FIELD = 'CREATE_BOT_FIELD';
export const CREATE_BOT_FIELD_RESULT = 'CREATE_BOT_FIELD_RESULT';
export const CREATE_BOT_FIELD_ERROR = 'CREATE_BOT_FIELD_ERROR';
export const CREATE_BOT_FIELD_RETRY = 'CREATE_BOT_FIELD_RETRY';


export const FETCH_BOT_FIELDS = 'FETCH_BOT_FIELDS';
export const FETCH_BOT_FIELDS_RESULT = 'FETCH_BOT_FIELDS_RESULT';
export const FETCH_BOT_FIELDS_ERROR = 'FETCH_BOT_FIELDS_ERROR';
export const FETCH_BOT_FIELDS_RETRY = 'FETCH_BOT_FIELDS_RETRY';


export const FETCH_BOT_FIELD = 'FETCH_BOT_FIELD';
export const FETCH_BOT_FIELD_RESULT = 'FETCH_BOT_FIELD_RESULT';
export const FETCH_BOT_FIELD_ERROR = 'FETCH_BOT_FIELD_ERROR';
export const FETCH_BOT_FIELD_RETRY = 'FETCH_BOT_FIELD_RETRY';


export const UPDATE_BOT_FIELD = 'UPDATE_BOT_FIELD';
export const UPDATE_BOT_FIELD_RESULT = 'UPDATE_BOT_FIELD_RESULT';
export const UPDATE_BOT_FIELD_ERROR = 'UPDATE_BOT_FIELD_ERROR';
export const UPDATE_BOT_FIELD_RETRY = 'UPDATE_BOT_FIELD_RETRY';


export const DELETE_BOT_FIELD = 'DELETE_BOT_FIELD';
export const DELETE_BOT_FIELD_RESULT = 'DELETE_BOT_FIELD_RESULT';
export const DELETE_BOT_FIELD_ERROR = 'DELETE_BOT_FIELD_ERROR';
export const DELETE_BOT_FIELD_RETRY = 'DELETE_BOT_FIELD_RETRY';


export const COPY_BOT_FIELD = 'COPY_BOT_FIELD';
export const COPY_BOT_FIELD_RESULT = 'COPY_BOT_FIELD_RESULT';
export const COPY_BOT_FIELD_ERROR = 'COPY_BOT_FIELD_ERROR';
export const COPY_BOT_FIELD_RETRY = 'COPY_BOT_FIELD_RETRY';


export const createBotField = (data) => ({
    type: CREATE_BOT_FIELD,
    data,
});

export const fetchBotFields = (data) => ({
    type: FETCH_BOT_FIELDS,
    data,
});


export const fetchBotField = (data) => ({
    type: FETCH_BOT_FIELD,
    data,
});

export const updateBotField = (data) => ({
    type: UPDATE_BOT_FIELD,
    data,
});

export const deleteBotField = (data) => ({
    type: DELETE_BOT_FIELD,
    data,
});

export const copyBotField = (data) => ({
    type: COPY_BOT_FIELD,
    data,
});
