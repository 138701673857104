import {call, put} from 'redux-saga/effects';
import {
    CREATE_ANALYSIS_CARD_TEXT_RESULT,
    CREATE_ANALYSIS_CARD_TEXT_ERROR,
    CREATE_ANALYSIS_CARD_TEXT_RETRY,

    FETCH_ANALYSIS_CARD_TEXTS_RESULT,
    FETCH_ANALYSIS_CARD_TEXTS_ERROR,
    FETCH_ANALYSIS_CARD_TEXTS_RETRY,

    FETCH_ANALYSIS_CARD_TEXT_RESULT,
    FETCH_ANALYSIS_CARD_TEXT_ERROR,
    FETCH_ANALYSIS_CARD_TEXT_RETRY,

    UPDATE_ANALYSIS_CARD_TEXT_RESULT,
    UPDATE_ANALYSIS_CARD_TEXT_ERROR,
    UPDATE_ANALYSIS_CARD_TEXT_RETRY,

    DELETE_ANALYSIS_CARD_TEXT_RESULT,
    DELETE_ANALYSIS_CARD_TEXT_ERROR,
    DELETE_ANALYSIS_CARD_TEXT_RETRY,
} from '../../actions/report/analysiscardtexts';
import api from "../api";
import {toast} from "react-toastify";


export function* createAnalysisCardText(action) {
    try {
        const response = yield call(api.report.analysiscardtexts.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createAnalysisCardText', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_ANALYSIS_CARD_TEXT_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_ANALYSIS_CARD_TEXT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_ANALYSIS_CARD_TEXT_RETRY, retry: true, message: e.message});
    }
}


export function* fetchAnalysisCardTexts(action) {
    try {
        const response = yield call(api.report.analysiscardtexts.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchAnalysisCardTexts', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ANALYSIS_CARD_TEXTS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_ANALYSIS_CARD_TEXTS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ANALYSIS_CARD_TEXTS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchAnalysisCardText(action) {
    try {
        const response = yield call(api.report.analysiscardtexts.get, action.data);
        const result = yield response.json();
        // console.warn('SAGA:fetchAnalysisCardText', action, result);
        if (response.status === 200) {
            yield put({type: FETCH_ANALYSIS_CARD_TEXT_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_ANALYSIS_CARD_TEXT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ANALYSIS_CARD_TEXT_RETRY, retry: true, message: e.message});
    }
}


export function* updateAnalysisCardText(action) {
    try {
        const response = yield call(api.report.analysiscardtexts.update, action.data);
        const result = yield response.json();
        // console.warn('SAGA:updateAnalysisCardText', action, result);
        if (response.status === 200) {
            yield put({type: UPDATE_ANALYSIS_CARD_TEXT_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_ANALYSIS_CARD_TEXT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_ANALYSIS_CARD_TEXT_RETRY, retry: true, message: e.message});
    }
}


export function* deleteAnalysisCardText(action) {
    try {
        const response = yield call(api.report.analysiscardtexts.remove, action.data);
        // const result = yield response.json();
        // console.warn('SAGA:deleteAnalysisCardText', action);

        if (response.status === 204) {
            yield put({type: DELETE_ANALYSIS_CARD_TEXT_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_ANALYSIS_CARD_TEXT_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_ANALYSIS_CARD_TEXT_RETRY, retry: true, message: e.message});
    }
}
