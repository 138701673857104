import {getStorageItem, removeStorageItem, setStorageItem} from "../utils";
import moment from "moment";
import api from "./api";

export const USER = 'user';
export const ACCESS = 'access';
export const REFRESH = 'refresh';
export const ACCESS_EXPIRE = 'access_expire';
export const ACCESS_EXPIRE_TIME = {value: 1, unit: 'days'};


let removeFromStorage = () => {
    removeStorageItem(USER);
    removeStorageItem(ACCESS);
    removeStorageItem(REFRESH);
    removeStorageItem(ACCESS_EXPIRE);

    window.location.replace(`/login/?next=${window.location.pathname}`);
};

let accessTokenRefresh = async () => {
    let access_expire = getStorageItem(ACCESS_EXPIRE);
    let access = getStorageItem(ACCESS);
    let refresh = getStorageItem(REFRESH);

    if (!!access_expire && !!refresh) {
        let isExpired = moment().isAfter(access_expire);
        if (!!isExpired) {
            try {
                const res = await api.accounts.tokenRefresh({refresh});
                const result = await res.json();
                if (res.status === 200) {
                    setStorageItem(ACCESS, result.access);
                    setStorageItem(ACCESS_EXPIRE, moment().add(ACCESS_EXPIRE_TIME.value, ACCESS_EXPIRE_TIME.unit));
                    return result.access;
                } else {
                    removeFromStorage();
                    return null;
                }
            } catch (e) {
                return null;
            }
        } else {
            return access;
        }
    } else {
        if (!!access) {
            removeFromStorage();
            return null;
        } else {
            return null
        }
    }
};

function* makeRequest(path, allParams = {}) {
    let headers = {};
    const access = yield accessTokenRefresh();
    const language = yield localStorage.getItem('language');
    let {bodyType = 'form', ...params} = allParams;

    if (access) {
        headers = {...headers, 'Authorization': `Bearer ${access}`};
    }

    if (bodyType === 'json') {
        headers = {...headers, 'Content-Type': 'application/json'};
    } else if (bodyType === 'file') {
        headers = {...headers, 'Content-Type': 'multipart/form-data'};
    }

    if (params.headers) {
        headers = {...params.headers, ...headers};
    }
    const fetchParams = {
        ...params,
        headers,
    };

    if (path.indexOf('?') > -1) {
        path += `&language=${language}`;
    } else {
        path += `?language=${language}`;
    }

    return yield fetch(path, fetchParams);
}

export default makeRequest;
