//External Imports
import React from 'react';
import {Route, Switch} from 'react-router';
import PropTypes from 'prop-types';
import {toast, ToastContainer} from 'react-toastify';
import {connect} from "react-redux";

//My Imports
import routes from './routes';
import {LoginRedirect} from "./components/others";
import {checkPermissions, checkRoles} from "./permisions";
import {history} from './store';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';


class App extends React.Component {

    static propTypes = {
        profileData: PropTypes.object,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {profile: {isFetchingLogin, error, retry}} = this.props;

        if (!isFetchingLogin && prevProps.profile.isFetchingLogin && !error && !retry) {
            toast("Logged In Successfully",);
            history.push('/');
        }
    }

    render() {
        return (
            <div style={{paddingBottom: 100}}>
                <LoginRedirect />
              {
                !!this.props.profile.detail.id &&
                  <div style={{position: 'absolute', right: 20, top: 20, zIndex: 1000}}>
                    <button className="btn btn-primary mr-3"
                            onClick={() => history.push('/')}>Home</button>
                    <button className="btn btn-primary"
                            onClick={() => history.push('/logout')}>Logout</button>
                  </div>
              }

                <Switch>
                    {
                        routes.filter(r => checkPermissions(r.permissions)).filter(r => checkRoles(r.roles)).map(
                            (route, index) => <Route key={index} {...route} />
                        )
                    }
                </Switch>
                <ToastContainer enableMultiContainer
                                autoClose={3000}
                                className={'toast-container'}/>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    profile: state.accounts.profile,
});


export default connect(mapStateToProps)(App);
