import React from 'react';
import {Images} from "../../constants";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";


export default class NotFound extends React.Component {
    
    render() {
        return (
            <SidebarContainer>
                <div className="py-5"/>
                <div className="py-5 text-center">
                    <img src={Images.error404}
                         style={{width: '100%', maxWidth: 800}}
                         alt="404"/>
                </div>
            </SidebarContainer>
        )
    }
}
