export const CREATE_ANALYSIS_CARD_TEXT = 'CREATE_ANALYSIS_CARD_TEXT';
export const CREATE_ANALYSIS_CARD_TEXT_RESULT = 'CREATE_ANALYSIS_CARD_TEXT_RESULT';
export const CREATE_ANALYSIS_CARD_TEXT_ERROR = 'CREATE_ANALYSIS_CARD_TEXT_ERROR';
export const CREATE_ANALYSIS_CARD_TEXT_RETRY = 'CREATE_ANALYSIS_CARD_TEXT_RETRY';


export const FETCH_ANALYSIS_CARD_TEXTS = 'FETCH_ANALYSIS_CARD_TEXTS';
export const FETCH_ANALYSIS_CARD_TEXTS_RESULT = 'FETCH_ANALYSIS_CARD_TEXTS_RESULT';
export const FETCH_ANALYSIS_CARD_TEXTS_ERROR = 'FETCH_ANALYSIS_CARD_TEXTS_ERROR';
export const FETCH_ANALYSIS_CARD_TEXTS_RETRY = 'FETCH_ANALYSIS_CARD_TEXTS_RETRY';


export const FETCH_ANALYSIS_CARD_TEXT = 'FETCH_ANALYSIS_CARD_TEXT';
export const FETCH_ANALYSIS_CARD_TEXT_RESULT = 'FETCH_ANALYSIS_CARD_TEXT_RESULT';
export const FETCH_ANALYSIS_CARD_TEXT_ERROR = 'FETCH_ANALYSIS_CARD_TEXT_ERROR';
export const FETCH_ANALYSIS_CARD_TEXT_RETRY = 'FETCH_ANALYSIS_CARD_TEXT_RETRY';


export const UPDATE_ANALYSIS_CARD_TEXT = 'UPDATE_ANALYSIS_CARD_TEXT';
export const UPDATE_ANALYSIS_CARD_TEXT_RESULT = 'UPDATE_ANALYSIS_CARD_TEXT_RESULT';
export const UPDATE_ANALYSIS_CARD_TEXT_ERROR = 'UPDATE_ANALYSIS_CARD_TEXT_ERROR';
export const UPDATE_ANALYSIS_CARD_TEXT_RETRY = 'UPDATE_ANALYSIS_CARD_TEXT_RETRY';


export const DELETE_ANALYSIS_CARD_TEXT = 'DELETE_ANALYSIS_CARD_TEXT';
export const DELETE_ANALYSIS_CARD_TEXT_RESULT = 'DELETE_ANALYSIS_CARD_TEXT_RESULT';
export const DELETE_ANALYSIS_CARD_TEXT_ERROR = 'DELETE_ANALYSIS_CARD_TEXT_ERROR';
export const DELETE_ANALYSIS_CARD_TEXT_RETRY = 'DELETE_ANALYSIS_CARD_TEXT_RETRY';


export const createAnalysisCardText = (data) => ({
    type: CREATE_ANALYSIS_CARD_TEXT,
    data,
});

export const fetchAnalysisCardTexts = (data) => ({
    type: FETCH_ANALYSIS_CARD_TEXTS,
    data,
});

export const fetchAnalysisCardText = (data) => ({
    type: FETCH_ANALYSIS_CARD_TEXT,
    data,
});

export const updateAnalysisCardText = (data) => ({
    type: UPDATE_ANALYSIS_CARD_TEXT,
    data,
});

export const deleteAnalysisCardText = (data) => ({
    type: DELETE_ANALYSIS_CARD_TEXT,
    data,
});
