import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {
    fetchReportSheet,
    updateReportSheet,
    createReportSheet,
    deleteReportSheet
} from "../../../actions/report/reportsheets";
import {connect} from "react-redux";
import RedFlagEditCreateModal from "../RedFlagEditCreateModal";
import AnalysisScoreEditCreateModal from "../AnalysisScoreEditCreateModal";
import ListGroup from "react-bootstrap/ListGroup";
import AnalysisCardEditCreateModal from "../AnalysisCardEditCreateModal";
import ConfirmButton from "../../buttons/ConfirmButton";
import ReportSheetProductEditCreateModal from "../ReportSheetProductEditCreateModal";


class ReportSheetEditCreateModal extends React.Component {

    static propTypes = {
        reportsheet: PropTypes.any,
        reportsheets: PropTypes.any,
        reportsheetproducts: PropTypes.any,
        createReportSheet: PropTypes.func,
        fetchReportSheet: PropTypes.func,
        updateReportSheet: PropTypes.func,
        deleteReportSheet: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            reportsheet: props.reportsheet,
            redflag: undefined,
            analysisscore: undefined,
            analysiscard: undefined,
            reportsheetproduct: undefined,
            message: undefined,
            showBuilder: false,
        }
    }

    componentDidMount() {
        if (this.props.reportsheet.id) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            redflags,
            analysisscores,
            analysiscards,
            reportsheetproducts,
            reportsheets: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}
        } = this.props;

        if (!isFetching && prevProps.reportsheets.isFetching && !error && !retry) {
            this.setState({reportsheet: detail});
        }
        if (!redflags.isCreating && prevProps.redflags.isCreating && !redflags.error && !redflags.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!redflags.isUpdating && prevProps.redflags.isUpdating && !redflags.error && !redflags.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!redflags.isDeleting && prevProps.redflags.isDeleting && !redflags.error && !redflags.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!analysisscores.isCreating && prevProps.analysisscores.isCreating && !analysisscores.error && !analysisscores.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!analysisscores.isUpdating && prevProps.analysisscores.isUpdating && !analysisscores.error && !analysisscores.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!analysisscores.isDeleting && prevProps.analysisscores.isDeleting && !analysisscores.error && !analysisscores.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }

        if (!analysiscards.isCreating && prevProps.analysiscards.isCreating && !analysiscards.error && !analysiscards.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!analysiscards.isUpdating && prevProps.analysiscards.isUpdating && !analysiscards.error && !analysiscards.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!analysiscards.isDeleting && prevProps.analysiscards.isDeleting && !analysiscards.error && !analysiscards.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }

        if (!reportsheetproducts.isCreating && prevProps.reportsheetproducts.isCreating && !reportsheetproducts.error && !reportsheetproducts.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!reportsheetproducts.isUpdating && prevProps.reportsheetproducts.isUpdating && !reportsheetproducts.error && !reportsheetproducts.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }
        if (!reportsheetproducts.isDeleting && prevProps.reportsheetproducts.isDeleting && !reportsheetproducts.error && !reportsheetproducts.retry) {
            this.props.fetchReportSheet(this.props.reportsheet);
        }

        if (!isUpdating && prevProps.reportsheets.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.reportsheets.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.reportsheets.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.reportsheets.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.reportsheets.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleReportSheetChange = (reportsheet) => {
        this.setState({reportsheet});
    };

    handleSaveReportSheet = () => {
        if (this.state.reportsheet.id) {
            this.props.updateReportSheet(this.state.reportsheet);
        } else {
            this.props.createReportSheet({...this.state.reportsheet});
        }
    };

    handleDeleteReportSheet = () => {
        if (this.state.reportsheet.id) {
            this.props.deleteReportSheet(this.state.reportsheet);
        }
    };

    handleCreateRedFlag = () => {
        let {reportsheet = {}} = this.state;
        this.setState({
            redflag: {
                name: '',
                report_sheet: reportsheet.id,
                expression: '',
            }
        });
    };

    handleCreateAnalysisScore = () => {
        let {reportsheet = {}} = this.state;
        this.setState({
            analysisscore: {
                name: '',
                report_sheet: reportsheet.id,
                expression: '',
                type: '',
                total_score: '',
            }
        });
    };

    handleCreateAnalysisCard = () => {
        let {reportsheet = {}} = this.state;
        this.setState({
            analysiscard: {
                name: '',
                report_sheet: reportsheet.id,
            }
        });
    };

    handleCreateReportSheetProduct = () => {
        let {reportsheet = {}} = this.state;
        this.setState({
            reportsheetproduct: {
                report_sheet: reportsheet.id,
                select_expression: ''
            }
        });
    };

    render() {
        let {show, handleClose} = this.props;
        let {reportsheet, message} = this.state;

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{reportsheet.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Name"
                                      value={reportsheet.name}
                                      isInvalid={!!message && !!message.name}
                                      onChange={(e) => this.handleReportSheetChange({
                                          ...reportsheet,
                                          name: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.name}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <h5 className="mt-4 pb-2">
                        Red Flags
                        {
                            !!reportsheet.id &&
                            <Button size={'sm'} className={'ml-2 float-right'}
                                    onClick={this.handleCreateRedFlag}>Create Red Flag</Button>
                        }
                    </h5>
                    <ListGroup>
                        {
                            !!reportsheet.redflags &&
                            reportsheet.redflags.map(
                                obj => (
                                    <ListGroup.Item key={obj.id}>
                                        <strong>{obj.name}</strong>
                                        <Button size={'sm'} className={'ml-2 float-right'}
                                                onClick={() => this.setState({redflag: obj})}>Edit</Button>
                                    </ListGroup.Item>
                                )
                            )
                        }
                    </ListGroup>
                    <h5 className="mt-4 pb-2">
                        Analysis Scores
                        {
                            !!reportsheet.id &&
                            <Button size={'sm'} className={'ml-2 float-right'}
                                    onClick={this.handleCreateAnalysisScore}>Create Analysis Score</Button>
                        }
                    </h5>
                    <ListGroup>
                        {
                            !!reportsheet.analysisscores &&
                            reportsheet.analysisscores.map(
                                obj => (
                                    <ListGroup.Item key={obj.id}>
                                        <strong>{obj.name}</strong>
                                        <Button size={'sm'} className={'ml-2 float-right'}
                                                onClick={() => this.setState({analysisscore: obj})}>Edit</Button>
                                    </ListGroup.Item>
                                )
                            )
                        }
                    </ListGroup>
                    <h5 className="mt-4 pb-2">
                        Analysis Cards
                        {
                            !!reportsheet.id &&
                            <Button size={'sm'} className={'ml-2 float-right'}
                                    onClick={this.handleCreateAnalysisCard}>Create Analysis Card</Button>
                        }
                    </h5>
                    <ListGroup>
                        {
                            !!reportsheet.analysiscards &&
                            reportsheet.analysiscards.map(
                                obj => (
                                    <ListGroup.Item key={obj.id}>
                                        <strong>{obj.name}</strong>
                                        <Button size={'sm'} className={'ml-2 float-right'}
                                                onClick={() => this.setState({analysiscard: obj})}>Edit</Button>
                                    </ListGroup.Item>
                                )
                            )
                        }
                    </ListGroup>
                    <h5 className="mt-4 pb-2">
                        Available Products
                        {
                            !!reportsheet.id &&
                            <Button size={'sm'} className={'ml-2 float-right'}
                                    onClick={this.handleCreateReportSheetProduct}>Add Product</Button>
                        }
                    </h5>
                    <ListGroup>
                        {
                            !!reportsheet.reportsheetproducts &&
                            reportsheet.reportsheetproducts.map(
                                obj => (
                                    <ListGroup.Item key={obj.id}>
                                        <strong>{obj.product_name}</strong>
                                        <Button size={'sm'} className={'ml-2 float-right'}
                                                onClick={() => this.setState({reportsheetproduct: obj})}>Edit</Button>
                                    </ListGroup.Item>
                                )
                            )
                        }
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveReportSheet}>Save</Button>
                    {
                        this.state.reportsheet.id &&
                        <ConfirmButton onClick={this.handleDeleteReportSheet}/>
                    }
                </Modal.Footer>
                {
                    !!this.state.redflag &&
                    <RedFlagEditCreateModal show={true}
                                            redflag={this.state.redflag}
                                            handleClose={() => this.setState({redflag: undefined})}/>
                }
                {
                    !!this.state.analysisscore &&
                    <AnalysisScoreEditCreateModal show={true}
                                                  analysisscore={this.state.analysisscore}
                                                  handleClose={() => this.setState({analysisscore: undefined})}/>
                }
                {
                    !!this.state.analysiscard &&
                    <AnalysisCardEditCreateModal show={true}
                                                 analysiscard={this.state.analysiscard}
                                                 handleClose={() => this.setState({analysiscard: undefined})}/>
                }
                {
                    !!this.state.reportsheetproduct &&
                    <ReportSheetProductEditCreateModal show={true}
                                                       reportsheetproduct={this.state.reportsheetproduct}
                                                       handleClose={() => this.setState({reportsheetproduct: undefined})}/>
                }
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    reportsheets: state.report.reportsheets,
    redflags: state.report.redflags,
    analysisscores: state.report.analysisscores,
    analysiscards: state.report.analysiscards,
    reportsheetproducts: state.report.reportsheetproducts,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createReportSheet,
    fetchReportSheet,
    updateReportSheet,
    deleteReportSheet,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ReportSheetEditCreateModal);
