import {call, put} from 'redux-saga/effects';
import {
    CREATE_REPORT_SHEET_RESULT,
    CREATE_REPORT_SHEET_ERROR,
    CREATE_REPORT_SHEET_RETRY,

    FETCH_REPORT_SHEETS_RESULT,
    FETCH_REPORT_SHEETS_ERROR,
    FETCH_REPORT_SHEETS_RETRY,

    FETCH_REPORT_SHEET_RESULT,
    FETCH_REPORT_SHEET_ERROR,
    FETCH_REPORT_SHEET_RETRY,

    UPDATE_REPORT_SHEET_RESULT,
    UPDATE_REPORT_SHEET_ERROR,
    UPDATE_REPORT_SHEET_RETRY,

    DELETE_REPORT_SHEET_RESULT,
    DELETE_REPORT_SHEET_ERROR,
    DELETE_REPORT_SHEET_RETRY,
} from '../../actions/report/reportsheets';
import api from "../api";
import {toast} from "react-toastify";


export function* createReportSheet(action) {
    try {
        const response = yield call(api.report.reportsheets.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createReportSheet', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_REPORT_SHEET_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_REPORT_SHEET_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_REPORT_SHEET_RETRY, retry: true, message: e.message});
    }
}


export function* fetchReportSheets(action) {
    try {
        const response = yield call(api.report.reportsheets.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchReportSheets', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORT_SHEETS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REPORT_SHEETS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_SHEETS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchReportSheet(action) {
    try {
        const response = yield call(api.report.reportsheets.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReportSheet', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORT_SHEET_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REPORT_SHEET_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_SHEET_RETRY, retry: true, message: e.message});
    }
}


export function* updateReportSheet(action) {
    try {
        const response = yield call(api.report.reportsheets.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateReportSheet', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_REPORT_SHEET_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_REPORT_SHEET_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_REPORT_SHEET_RETRY, retry: true, message: e.message});
    }
}


export function* deleteReportSheet(action) {
    try {
        const response = yield call(api.report.reportsheets.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteReportSheet', action);

        if (response.status === 204) {
            yield put({type: DELETE_REPORT_SHEET_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_REPORT_SHEET_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_REPORT_SHEET_RETRY, retry: true, message: e.message});
    }
}
