export const CREATE_MESSAGE_TYPE = 'CREATE_MESSAGE_TYPE';
export const CREATE_MESSAGE_TYPE_RESULT = 'CREATE_MESSAGE_TYPE_RESULT';
export const CREATE_MESSAGE_TYPE_ERROR = 'CREATE_MESSAGE_TYPE_ERROR';
export const CREATE_MESSAGE_TYPE_RETRY = 'CREATE_MESSAGE_TYPE_RETRY';


export const FETCH_MESSAGE_TYPES = 'FETCH_MESSAGE_TYPES';
export const FETCH_MESSAGE_TYPES_RESULT = 'FETCH_MESSAGE_TYPES_RESULT';
export const FETCH_MESSAGE_TYPES_ERROR = 'FETCH_MESSAGE_TYPES_ERROR';
export const FETCH_MESSAGE_TYPES_RETRY = 'FETCH_MESSAGE_TYPES_RETRY';


export const FETCH_MESSAGE_TYPE = 'FETCH_MESSAGE_TYPE';
export const FETCH_MESSAGE_TYPE_RESULT = 'FETCH_MESSAGE_TYPE_RESULT';
export const FETCH_MESSAGE_TYPE_ERROR = 'FETCH_MESSAGE_TYPE_ERROR';
export const FETCH_MESSAGE_TYPE_RETRY = 'FETCH_MESSAGE_TYPE_RETRY';


export const UPDATE_MESSAGE_TYPE = 'UPDATE_MESSAGE_TYPE';
export const UPDATE_MESSAGE_TYPE_RESULT = 'UPDATE_MESSAGE_TYPE_RESULT';
export const UPDATE_MESSAGE_TYPE_ERROR = 'UPDATE_MESSAGE_TYPE_ERROR';
export const UPDATE_MESSAGE_TYPE_RETRY = 'UPDATE_MESSAGE_TYPE_RETRY';


export const DELETE_MESSAGE_TYPE = 'DELETE_MESSAGE_TYPE';
export const DELETE_MESSAGE_TYPE_RESULT = 'DELETE_MESSAGE_TYPE_RESULT';
export const DELETE_MESSAGE_TYPE_ERROR = 'DELETE_MESSAGE_TYPE_ERROR';
export const DELETE_MESSAGE_TYPE_RETRY = 'DELETE_MESSAGE_TYPE_RETRY';


export const createMessageType = (data) => ({
    type: CREATE_MESSAGE_TYPE,
    data,
});

export const fetchMessageTypes = (data) => ({
    type: FETCH_MESSAGE_TYPES,
    data,
});


export const fetchMessageType = (data) => ({
    type: FETCH_MESSAGE_TYPE,
    data,
});

export const updateMessageType = (data) => ({
    type: UPDATE_MESSAGE_TYPE,
    data,
});

export const deleteMessageType = (data) => ({
    type: DELETE_MESSAGE_TYPE,
    data,
});

