export const CREATE_ANALYSIS_SCORE = 'CREATE_ANALYSIS_SCORE';
export const CREATE_ANALYSIS_SCORE_RESULT = 'CREATE_ANALYSIS_SCORE_RESULT';
export const CREATE_ANALYSIS_SCORE_ERROR = 'CREATE_ANALYSIS_SCORE_ERROR';
export const CREATE_ANALYSIS_SCORE_RETRY = 'CREATE_ANALYSIS_SCORE_RETRY';


export const FETCH_ANALYSIS_SCORES = 'FETCH_ANALYSIS_SCORES';
export const FETCH_ANALYSIS_SCORES_RESULT = 'FETCH_ANALYSIS_SCORES_RESULT';
export const FETCH_ANALYSIS_SCORES_ERROR = 'FETCH_ANALYSIS_SCORES_ERROR';
export const FETCH_ANALYSIS_SCORES_RETRY = 'FETCH_ANALYSIS_SCORES_RETRY';


export const FETCH_ANALYSIS_SCORE = 'FETCH_ANALYSIS_SCORE';
export const FETCH_ANALYSIS_SCORE_RESULT = 'FETCH_ANALYSIS_SCORE_RESULT';
export const FETCH_ANALYSIS_SCORE_ERROR = 'FETCH_ANALYSIS_SCORE_ERROR';
export const FETCH_ANALYSIS_SCORE_RETRY = 'FETCH_ANALYSIS_SCORE_RETRY';


export const UPDATE_ANALYSIS_SCORE = 'UPDATE_ANALYSIS_SCORE';
export const UPDATE_ANALYSIS_SCORE_RESULT = 'UPDATE_ANALYSIS_SCORE_RESULT';
export const UPDATE_ANALYSIS_SCORE_ERROR = 'UPDATE_ANALYSIS_SCORE_ERROR';
export const UPDATE_ANALYSIS_SCORE_RETRY = 'UPDATE_ANALYSIS_SCORE_RETRY';


export const DELETE_ANALYSIS_SCORE = 'DELETE_ANALYSIS_SCORE';
export const DELETE_ANALYSIS_SCORE_RESULT = 'DELETE_ANALYSIS_SCORE_RESULT';
export const DELETE_ANALYSIS_SCORE_ERROR = 'DELETE_ANALYSIS_SCORE_ERROR';
export const DELETE_ANALYSIS_SCORE_RETRY = 'DELETE_ANALYSIS_SCORE_RETRY';


export const createAnalysisScore = (data) => ({
    type: CREATE_ANALYSIS_SCORE,
    data,
});

export const fetchAnalysisScores = (data) => ({
    type: FETCH_ANALYSIS_SCORES,
    data,
});


export const fetchAnalysisScore = (data) => ({
    type: FETCH_ANALYSIS_SCORE,
    data,
});

export const updateAnalysisScore = (data) => ({
    type: UPDATE_ANALYSIS_SCORE,
    data,
});

export const deleteAnalysisScore = (data) => ({
    type: DELETE_ANALYSIS_SCORE,
    data,
});
