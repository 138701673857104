import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


class DeleteIcon extends PureComponent {

    static propTypes = {
        onClick: PropTypes.func,
    };

    state = {
        confirm: false,
        name: '',
        timeout: null,
    };

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    toggleDelete = () => {
        let timeout = null;
        if (!this.state.confirm) {
            timeout = setTimeout(() => {
                this.setState({confirm: false});
            }, 2000)
        }
        this.setState(prevState => ({confirm: !prevState.confirm, timeout}));
    };

    handleDelete = () => {
        this.toggleDelete();
        clearTimeout(this.state.timeout);
        this.props.onClick();
    };

    render() {
        const {confirm} = this.state;

        return (
            <div className="delete-icon">
                {
                    confirm &&
                    <span onClick={this.handleDelete}
                          onBlur={() => console.log('hello')}>Confirm<i className="material-icons">delete_outline</i></span>
                }
                {
                    !confirm &&
                    <i className="material-icons delete-icon-main" onClick={this.toggleDelete}>delete</i>
                }
            </div>
        );
    }
}


export default DeleteIcon;

