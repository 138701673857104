import {call, put} from 'redux-saga/effects';
import {
    CREATE_EXPRESSION_RESULT,
    CREATE_EXPRESSION_ERROR,
    CREATE_EXPRESSION_RETRY,
    FETCH_EXPRESSIONS_RESULT,
    FETCH_EXPRESSIONS_ERROR,
    FETCH_EXPRESSIONS_RETRY,
    FETCH_EXPRESSION_RESULT,
    FETCH_EXPRESSION_ERROR,
    FETCH_EXPRESSION_RETRY,
    UPDATE_EXPRESSION_RESULT,
    UPDATE_EXPRESSION_ERROR,
    UPDATE_EXPRESSION_RETRY,
    DELETE_EXPRESSION_RESULT,
    DELETE_EXPRESSION_ERROR, DELETE_EXPRESSION_RETRY, FETCH_EXPRESSIONS,
} from '../../actions/quiz/expressions';
import api from "../api";
import {toast} from "react-toastify";


export function* createExpression(action) {
    try {
        const response = yield call(api.quiz.expressions.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createExpression', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_EXPRESSION_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_EXPRESSION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_EXPRESSION_RETRY, retry: true, message: e.message});
    }
}


export function* fetchExpressions(action) {
    try {
        const response = yield call(api.quiz.expressions.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchExpressions', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_EXPRESSIONS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_EXPRESSIONS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_EXPRESSIONS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchExpression(action) {
    try {
        const response = yield call(api.quiz.expressions.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchExpression', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_EXPRESSION_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_EXPRESSION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_EXPRESSION_RETRY, retry: true, message: e.message});
    }
}


export function* updateExpression(action) {
    try {
        const response = yield call(api.quiz.expressions.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateExpression', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_EXPRESSION_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_EXPRESSION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_EXPRESSION_RETRY, retry: true, message: e.message});
    }
}


export function* deleteExpression(action) {
    try {
        const response = yield call(api.quiz.expressions.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteExpression', action);

        if (response.status === 204) {
            yield put({type: DELETE_EXPRESSION_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_EXPRESSION_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_EXPRESSION_RETRY, retry: true, message: e.message});
    }
}
