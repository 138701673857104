import {call, put} from 'redux-saga/effects';
import {
    CREATE_REPORT_SHEET_PRODUCT_RESULT,
    CREATE_REPORT_SHEET_PRODUCT_ERROR,
    CREATE_REPORT_SHEET_PRODUCT_RETRY,

    FETCH_REPORT_SHEET_PRODUCTS_RESULT,
    FETCH_REPORT_SHEET_PRODUCTS_ERROR,
    FETCH_REPORT_SHEET_PRODUCTS_RETRY,

    FETCH_REPORT_SHEET_PRODUCT_RESULT,
    FETCH_REPORT_SHEET_PRODUCT_ERROR,
    FETCH_REPORT_SHEET_PRODUCT_RETRY,

    UPDATE_REPORT_SHEET_PRODUCT_RESULT,
    UPDATE_REPORT_SHEET_PRODUCT_ERROR,
    UPDATE_REPORT_SHEET_PRODUCT_RETRY,

    DELETE_REPORT_SHEET_PRODUCT_RESULT,
    DELETE_REPORT_SHEET_PRODUCT_ERROR,
    DELETE_REPORT_SHEET_PRODUCT_RETRY,
} from '../../actions/report/reportsheetproducts';
import api from "../api";
import {toast} from "react-toastify";


export function* createReportSheetProduct(action) {
    try {
        const response = yield call(api.report.reportsheetproducts.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createReportSheetProduct', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_REPORT_SHEET_PRODUCT_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_REPORT_SHEET_PRODUCT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_REPORT_SHEET_PRODUCT_RETRY, retry: true, message: e.message});
    }
}


export function* fetchReportSheetProducts(action) {
    try {
        const response = yield call(api.report.reportsheetproducts.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchReportSheetProducts', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORT_SHEET_PRODUCTS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REPORT_SHEET_PRODUCTS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_SHEET_PRODUCTS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchReportSheetProduct(action) {
    try {
        const response = yield call(api.report.reportsheetproducts.get, action.data);
        const result = yield response.json();
        // console.warn('SAGA:fetchReportSheetProduct', action, result);
        if (response.status === 200) {
            yield put({type: FETCH_REPORT_SHEET_PRODUCT_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REPORT_SHEET_PRODUCT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_SHEET_PRODUCT_RETRY, retry: true, message: e.message});
    }
}


export function* updateReportSheetProduct(action) {
    try {
        const response = yield call(api.report.reportsheetproducts.update, action.data);
        const result = yield response.json();
        // console.warn('SAGA:updateReportSheetProduct', action, result);
        if (response.status === 200) {
            yield put({type: UPDATE_REPORT_SHEET_PRODUCT_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_REPORT_SHEET_PRODUCT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_REPORT_SHEET_PRODUCT_RETRY, retry: true, message: e.message});
    }
}


export function* deleteReportSheetProduct(action) {
    try {
        const response = yield call(api.report.reportsheetproducts.remove, action.data);
        // const result = yield response.json();
        // console.warn('SAGA:deleteReportSheetProduct', action);

        if (response.status === 204) {
            yield put({type: DELETE_REPORT_SHEET_PRODUCT_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_REPORT_SHEET_PRODUCT_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_REPORT_SHEET_PRODUCT_RETRY, retry: true, message: e.message});
    }
}
