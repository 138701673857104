import React from 'react';
import PropTypes from "prop-types";
// My Imports
import {ACCESS} from "../../sagas/makeRequest";


export default class Logout extends React.Component {

    static propTypes = {
        history: PropTypes.object,
    };

    async componentDidMount() {
        await localStorage.removeItem(ACCESS);
        await localStorage.removeItem('user');
        this.props.history.push('/login/')
    }

    render() {
        return <div/>;
    }
}

