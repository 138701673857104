export const CREATE_COUPON = 'CREATE_COUPON';
export const CREATE_COUPON_RESULT = 'CREATE_COUPON_RESULT';
export const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';
export const CREATE_COUPON_RETRY = 'CREATE_COUPON_RETRY';


export const FETCH_COUPONS = 'FETCH_COUPONS';
export const FETCH_COUPONS_RESULT = 'FETCH_COUPONS_RESULT';
export const FETCH_COUPONS_ERROR = 'FETCH_COUPONS_ERROR';
export const FETCH_COUPONS_RETRY = 'FETCH_COUPONS_RETRY';


export const FETCH_COUPON = 'FETCH_COUPON';
export const FETCH_COUPON_RESULT = 'FETCH_COUPON_RESULT';
export const FETCH_COUPON_ERROR = 'FETCH_COUPON_ERROR';
export const FETCH_COUPON_RETRY = 'FETCH_COUPON_RETRY';


export const UPDATE_COUPON = 'UPDATE_COUPON';
export const UPDATE_COUPON_RESULT = 'UPDATE_COUPON_RESULT';
export const UPDATE_COUPON_ERROR = 'UPDATE_COUPON_ERROR';
export const UPDATE_COUPON_RETRY = 'UPDATE_COUPON_RETRY';


export const DELETE_COUPON = 'DELETE_COUPON';
export const DELETE_COUPON_RESULT = 'DELETE_COUPON_RESULT';
export const DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR';
export const DELETE_COUPON_RETRY = 'DELETE_COUPON_RETRY';


export const createCoupon = (data) => ({
    type: CREATE_COUPON,
    data,
});

export const fetchCoupons = () => ({
    type: FETCH_COUPONS,
});

export const fetchCoupon = (data) => ({
    type: FETCH_COUPON,
    data,
});

export const updateCoupon = (data) => ({
    type: UPDATE_COUPON,
    data,
});

export const deleteCoupon = (data) => ({
    type: DELETE_COUPON,
    data,
});
