// External Imports
import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';

import {getHttpsImageUrl} from "../../../utils";
import Pagination from "react-bootstrap/Pagination";
import Button from "react-bootstrap/Button";


export default class RatingInput extends Component {

    static propTypes = {
        handleSendClick: PropTypes.func.isRequired,
        maxRating: PropTypes.number,
        hideNextButton: PropTypes.bool,
    };

    static defaultProps = {
        maxRating: 9,
        hideNextButton: false,
    };

    state = {rating: 7,};

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleSend = (rating) => {
        this.setState({rating: rating});
        this.props.handleSendClick({text: rating});
    };

    render() {
        let {rating} = this.state;
        let {maxRating} = this.props;

        let ratingArray = [];
        for (let i = 1; i <= maxRating; i++) {
            ratingArray.push(i);
        }

        return (
            <div className='choice-item-container'>
                <small className="text-center">(select a rating)</small>
                <div className="choice-item-content">
                    <Pagination>
                        {
                            ratingArray.map(
                                r => (
                                    <Pagination.Item key={r} active={r === rating}
                                                     onClick={() => this.handleSend(r)}>
                                        {r}
                                    </Pagination.Item>
                                )
                            )
                        }
                    </Pagination>
                </div>
                {!this.props.hideNextButton && <div className="field-footer">
                    <div className="container d-flex justify-content-center">
                        <Button size="sm" variant="success" onClick={() => this.handleSend(rating)}>Next</Button>
                    </div>
                </div>}
            </div>
        )
    }
}
