import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {fetchCoupon, updateCoupon, createCoupon, deleteCoupon} from "../../../actions/cms/coupons";
import {connect} from "react-redux";
import ConfirmButton from "../../buttons/ConfirmButton";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import './styles.scss'
import moment from "moment";


class CouponEditCreateModal extends React.Component {

    static propTypes = {
        coupons: PropTypes.any,
        fetchCoupon: PropTypes.func,
        updateCoupon: PropTypes.func,
        createCoupon: PropTypes.func,
        deleteCoupon: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            coupon: props.coupon,
            message: undefined,
        };
    }

    componentDidMount() {
        if (this.props.coupon.id) {
            this.props.fetchCoupon(this.props.coupon);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {coupons: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;

        if (!isFetching && prevProps.coupons.isFetching && !error && !retry) {
            this.setState({coupon: detail});
        }

        if ((!isCreating && prevProps.coupons.isCreating && !error && !retry) ||
            (!isUpdating && prevProps.coupons.isUpdating && !error && !retry) ||
            (!isDeleting && prevProps.coupons.isDeleting && !error && !retry)) {
            this.props.handleClose();
        }

        if ((!isCreating && prevProps.coupons.isCreating && error && !retry) ||
            (!isUpdating && prevProps.coupons.isUpdating && error && !retry) ||
            (!isDeleting && prevProps.coupons.isDeleting && error && !retry)) {
            this.setState({message});
        }
    }


    handleCouponChange = (coupon) => {
        this.setState({coupon});
    };

    handleSaveCoupon = () => {
        if (this.state.coupon.id) {
            this.props.updateCoupon(this.state.coupon);
        } else {
            this.props.createCoupon(this.state.coupon);
        }
    };

    handleDeleteCoupon = () => {
        if (this.state.coupon.id) {
            this.props.deleteCoupon(this.state.coupon);
        }
    };

    render() {
        let {show, handleClose} = this.props;
        let {coupon, message} = this.state;

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="couponCode">
                        <Form.Label>Code</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Code"
                                      value={coupon.code || ''}
                                      isInvalid={!!message && !!message.code}
                                      onChange={(e) => this.handleCouponChange({
                                          ...coupon,
                                          code: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.code}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="couponText">
                        <Form.Label>Text</Form.Label>
                        <Form.Control as="textarea" row="2"
                                      placeholder="Enter Text"
                                      value={coupon.text || ''}
                                      isInvalid={!!message && !!message.text}
                                      onChange={(e) => this.handleCouponChange({
                                          ...coupon,
                                          text: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.text}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="max_discount">
                            <Form.Label>Max Discount</Form.Label>
                            <Form.Control type="number"
                                          placeholder="Max Discount"
                                          value={coupon.max_discount || ''}
                                          isInvalid={!!message && !!message.max_discount}
                                          onChange={(e) => this.handleCouponChange({
                                              ...coupon,
                                              max_discount: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.max_discount}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="use_count">
                            <Form.Label>Use Count</Form.Label>
                            <Form.Control type="number"
                                          placeholder="Use Count"
                                          value={coupon.use_count || ''}
                                          isInvalid={!!message && !!message.use_count}
                                          onChange={(e) => this.handleCouponChange({
                                              ...coupon,
                                              use_count: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.use_count}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="discount_percentage">
                            <Form.Label>Discount Percentage</Form.Label>
                            <Form.Control type="number"
                                          placeholder="Discount Percentage"
                                          value={coupon.discount_percentage || ''}
                                          isInvalid={!!message && !!message.discount_percentage}
                                          onChange={(e) => this.handleCouponChange({
                                              ...coupon,
                                              discount_percentage: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.discount_percentage}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="discount_amount">
                            <Form.Label>Discount Amount</Form.Label>
                            <Form.Control type="number"
                                          placeholder="Discount Amount"
                                          value={coupon.discount_amount || ''}
                                          isInvalid={!!message && !!message.discount_amount}
                                          onChange={(e) => this.handleCouponChange({
                                              ...coupon,
                                              discount_amount: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.discount_amount}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="expiry_date">
                            <Form.Label>Expiry Date</Form.Label>
                            <DatePicker showYearDropdown showMonthDropdown
                                className='form-control'
                                placeholderText='Select Date'
                                dateFormat="dd MMM yyyy"
                                minDate={new Date()}
                                selected={!!coupon.expiry_date?new Date(coupon.expiry_date):''}
                                onChange={(date) => this.handleCouponChange({
                                    ...coupon,
                                    expiry_date: moment(date).format(),
                                })}
                            />
                            {/*<Form.Control type="date"
                                          placeholder="Expiry Date"
                                          value={coupon.expiry_date || ''}
                                          isInvalid={!!message && !!message.expiry_date}
                                          onChange={(e) => this.handleCouponChange({
                                              ...coupon,
                                              expiry_date: e.target.value,
                                          })}/>*/}
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.expiry_date}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="pt-4 mt-3" as={Col} controlId="active">
                            <Form.Check type="checkbox" label="Active"
                                        checked={coupon.active || ''}
                                        isInvalid={!!message && !!message.active}
                                        onChange={(e) => this.handleCouponChange({
                                            ...coupon,
                                            active: !coupon.active,
                                        })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.active}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group as={Col} controlId="is_visible">
                        <Form.Check type="checkbox" label="Is Visible"
                                    checked={coupon.is_visible || ''}
                                    isInvalid={!!message && !!message.is_visible}
                                    onChange={(e) => this.handleCouponChange({
                                        ...coupon,
                                        is_visible: !coupon.is_visible,
                                    })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.is_visible}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveCoupon}>Save</Button>
                    {
                        this.state.coupon.id &&
                        <ConfirmButton onClick={this.handleDeleteCoupon}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    coupons: state.cms.coupons,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCoupon,
    updateCoupon,
    createCoupon,
    deleteCoupon,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(CouponEditCreateModal);
