export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_RESULT = 'UPDATE_USER_PROFILE_RESULT';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';
export const UPDATE_USER_PROFILE_RETRY = 'UPDATE_USER_PROFILE_RETRY';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_USER_PROFILE_RESULT = 'FETCH_USER_PROFILE_RESULT';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';
export const FETCH_USER_PROFILE_RETRY = 'FETCH_USER_PROFILE_RETRY';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_RESULT = 'USER_LOGIN_RESULT';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_RETRY = 'USER_LOGIN_RETRY';


export const userLogin = (data) => ({
    type: USER_LOGIN,
    data,
});

export const updateUserProfile = (data) => ({
    type: UPDATE_USER_PROFILE,
    data,
});

export const fetchUserProfile = () => ({
    type: FETCH_USER_PROFILE,
});
