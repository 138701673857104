import {
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_RESULT,
    UPDATE_USER_PROFILE_ERROR,
    UPDATE_USER_PROFILE_RETRY,
    FETCH_USER_PROFILE,
    FETCH_USER_PROFILE_RESULT,
    FETCH_USER_PROFILE_ERROR,
    FETCH_USER_PROFILE_RETRY,
    USER_LOGIN,
    USER_LOGIN_RESULT,
    USER_LOGIN_ERROR,
    USER_LOGIN_RETRY,
} from '../../actions/accounts/profile';

let detail = {};
try {
    detail = JSON.parse(localStorage.getItem('user') || '{}');
}
catch (e) {}


const initialState = {
    detail,
    isFetchingLogin: false,
    isFetching: false,
    isUpdating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    // console.warn(action, state);
    switch (action.type) {
        case UPDATE_USER_PROFILE:
            return {...state, isUpdating: true, error: false, message: null, retry: false,};
        case UPDATE_USER_PROFILE_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_USER_PROFILE_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_USER_PROFILE_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case FETCH_USER_PROFILE:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_USER_PROFILE_RESULT:
            return {...state, isFetching: false, detail: {...action.data}};
        case FETCH_USER_PROFILE_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_USER_PROFILE_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case USER_LOGIN:
            return {...state, isFetchingLogin: true, error: false, message: null, retry: false,};
        case USER_LOGIN_RESULT:
            return {...state, isFetchingLogin: false, detail: action.data,};
        case USER_LOGIN_ERROR:
            return {...state, isFetchingLogin: false, error: true, message: action.message};
        case USER_LOGIN_RETRY:
            return {...state, isFetchingLogin: false, retry: true, message: action.message};
        default:
            return {...state};
    }

};

export default reducer;
