import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import QuillHelper from "../../others/QuillHelper";

import './styles.scss';

import {
    fetchProductFeature,
    createProductFeature,
    updateProductFeature,
    deleteProductFeature
} from "../../../actions/quiz/productfeatures";
import ConfirmButton from "../../buttons/ConfirmButton";
import Select from "react-select";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";


const featureTypeOptions = [{label: 'Primary', value: 0}, {label: 'Secondary', value: 1}];


class ProductFeatureEditCreateModal extends React.Component {

    static propTypes = {
        productFeature: PropTypes.any,
        fetchProductFeature: PropTypes.func,
        createProductFeature: PropTypes.func,
        updateProductFeature: PropTypes.func,
        deleteProductFeature: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            productFeature: props.productFeature,
            message: undefined,
        };
    }

    componentDidMount() {
        if (this.props.productFeature.id) {
            this.props.fetchProductFeature(this.props.productFeature);
        }

        let quill = new QuillHelper('#product-feature-description-editor', this.handleContentChange);
        this.setState({quill});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {productfeatures: {detail, isFetching, isCreating, isDeleting, isUpdating, message, error, retry}} = this.props;

        if (!isFetching && prevProps.productfeatures.isFetching && !error && !retry) {
            this.setState({productFeature: detail});
            this.state.quill.setHtmlText(detail.description);
        }
        if (!isUpdating && prevProps.productfeatures.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.productfeatures.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.productfeatures.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.productfeatures.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.productfeatures.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleContentChange = () => {
        let {productFeature, language} = this.state;
        let descriptionKey = 'description' + (language ? language.db_label: '');
        this.setState({
            productFeature: {
                ...productFeature,
                [descriptionKey]: this.state.quill.getHtmlText()
            }
        });
    };

    handleSaveProductFeature = () => {
        if (this.state.productFeature.id) {
            this.props.updateProductFeature(this.state.productFeature);
        } else {
            this.props.createProductFeature({...this.state.productFeature});
        }
    };

    handleDeleteProductFeature = () => {
        if (this.state.productFeature.id) {
            this.props.deleteProductFeature(this.state.productFeature);
        }
    };

    handleLanguageChange = (language) => {
        let {productFeature} = this.state;
        this.setState({language});
        let descriptionKey = 'description' + (language ? language.db_label: '');
        this.state.quill.setHtmlText(productFeature[descriptionKey]);
    };

    render() {
        let {show, handleClose} = this.props;
        let {productFeature, message, language} = this.state;

        let titleKey = 'title' + (language ? language.db_label: '');

        return (
            <Modal className="product-feature-modal" show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Feature</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LanguageSelectInput onLanguageChange={this.handleLanguageChange}/>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text"
                                      value={productFeature[titleKey] || ''}
                                      onChange={(e) => this.setState({
                                          productFeature: {
                                              ...productFeature,
                                              [titleKey]: e.target.value
                                          }
                                      })}
                                      isInvalid={!!message && !!message[titleKey]}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message[titleKey]}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <div id="product-feature-description-editor" className="content"/>

                        <Form.Control.Feedback type="invalid">
                            {!!message && message.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Priority</Form.Label>
                        <Form.Control type="number"
                                      value={productFeature.priority}
                                      onChange={(e) => this.setState({
                                          productFeature: {
                                              ...productFeature,
                                              priority: e.target.value
                                          }
                                      })}
                                      isInvalid={!!message && !!message.priority}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.priority}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Feature Type</Form.Label>
                        <Select options={featureTypeOptions}
                                value={featureTypeOptions.filter(obj => obj.value === productFeature.feature_type)}
                                onChange={({value}) => this.setState({
                                    productFeature: {
                                        ...productFeature,
                                        feature_type: value
                                    }
                                })}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.feature_type}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.feature_type}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveProductFeature}>Save</Button>
                    {
                        this.state.productFeature.id &&
                        <ConfirmButton onClick={this.handleDeleteProductFeature}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    productfeatures: state.quiz.productfeatures,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchProductFeature,
    createProductFeature,
    updateProductFeature,
    deleteProductFeature,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ProductFeatureEditCreateModal);
