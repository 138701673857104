import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {fetchExpression, updateExpression, createExpression, deleteExpression} from "../../../actions/quiz/expressions";
import {connect} from "react-redux";
import {FIELD_LABELS, FIELD_TYPES} from "../../../constants";
import ExpressionBuilder from "../ExpressionBuilder";
import {fetchBotForm, fetchBotForms} from "../../../actions/quiz/botforms";
import ConfirmButton from "../../buttons/ConfirmButton";


class ExpressionEditCreateModal extends React.Component {

    static propTypes = {
        expression: PropTypes.any,
        expressions: PropTypes.any,
        createExpression: PropTypes.func,
        fetchExpression: PropTypes.func,
        updateExpression: PropTypes.func,
        deleteExpression: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
        fetchBotForms: PropTypes.func,
        fetchBotForm: PropTypes.func,
    };

    static defaultProps = {
    };

    constructor(props) {
        super(props);

        this.state = {
            expression: props.expression,
            message: undefined,
            field_child: undefined,
            showBuilder: false,
        }
    }

    componentDidMount() {
        if (this.props.expression.id) {
            this.props.fetchExpression(this.props.expression);
            this.props.fetchBotForm({id: this.props.expression.bot_form_id});
        }
        if (this.state.expression.bot_form_id) {
            this.props.fetchBotForm({id: this.state.expression.bot_form_id});
        }
        this.props.fetchBotForms();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {expressions: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;

        if (!isFetching && prevProps.expressions.isFetching && !error && !retry) {
            this.setState({expression: detail});
        }
        if (!isUpdating && prevProps.expressions.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.expressions.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.expressions.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.expressions.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.expressions.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleExpressionChange = (expression) => {
        this.setState({expression});
    };

    handleSaveExpression = () => {
        if (this.state.expression.id) {
            this.props.updateExpression(this.state.expression);
        } else {
            this.props.createExpression({...this.state.expression});
        }
    };

    handleDeleteExpression = () => {
        if (this.state.expression.id) {
            this.props.deleteExpression(this.state.expression);
        }
    };

    handleCloseExpressionChildModal = () => {
        this.setState({field_child: undefined});
    };

    render() {
        let {show, botforms, expressions, handleConfirm, handleClose} = this.props;
        let {expression, message} = this.state;

        expression.root_bot_fields = expression.root_bot_fields || [];
        expression.bot_fields = expression.bot_fields || [];
        let identifiers = (botforms.detail.bot_fields || []).map(o => o[1]);

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{expression.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Name"
                                      value={expression.name}
                                      isInvalid={!!message && !!message.name}
                                      onChange={(e) => this.handleExpressionChange({
                                          ...expression,
                                          name: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.name}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Select Quiz</Form.Label>
                        <Select options={botforms.list.map(o => ({label: o.name, value: o.id}))}
                                className="bot-form-bot-fields-select"
                                value={botforms.list.filter(o => o.id == expression.bot_form_id).map(o => ({
                                    label: o.name,
                                    value: o.id
                                }))}
                                onChange={(value) => {
                                    this.props.fetchBotForm({id: value.value});
                                    this.handleExpressionChange({
                                        ...expression,
                                        bot_form_id: value.value,
                                    })
                                }}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.bot_form_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldText">
                        <Form.Label>Expression</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      disabled={true}
                                      value={expression.expression}/>
                        <Button className="mt-3" size="sm"
                                onClick={() => this.setState({showBuilder: true})}>Change Show When</Button>
                        <ExpressionBuilder identifiers={identifiers}
                                           expression={expression.expression || ''}
                                           handleExpressionChange={(value) => this.handleExpressionChange({
                                               ...expression,
                                               expression: value,
                                           })}
                                           show={this.state.showBuilder}
                                           handleClose={() => this.setState({showBuilder: false})}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveExpression}>Save</Button>
                    {
                        this.state.expression.id &&
                        <ConfirmButton onClick={this.handleDeleteExpression}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    expressions: state.quiz.expressions,
    botforms: state.quiz.botforms,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createExpression,
    fetchExpression,
    updateExpression,
    deleteExpression,
    fetchBotForms,
    fetchBotForm,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ExpressionEditCreateModal);
