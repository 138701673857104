export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_RESULT = 'CREATE_PRODUCT_RESULT';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';
export const CREATE_PRODUCT_RETRY = 'CREATE_PRODUCT_RETRY';


export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_RESULT = 'FETCH_PRODUCTS_RESULT';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const FETCH_PRODUCTS_RETRY = 'FETCH_PRODUCTS_RETRY';


export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_RESULT = 'FETCH_PRODUCT_RESULT';
export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR';
export const FETCH_PRODUCT_RETRY = 'FETCH_PRODUCT_RETRY';


export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_RESULT = 'UPDATE_PRODUCT_RESULT';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';
export const UPDATE_PRODUCT_RETRY = 'UPDATE_PRODUCT_RETRY';


export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_RESULT = 'DELETE_PRODUCT_RESULT';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';
export const DELETE_PRODUCT_RETRY = 'DELETE_PRODUCT_RETRY';


export const createProduct = (data) => ({
    type: CREATE_PRODUCT,
    data,
});

export const fetchProducts = (data) => ({
    type: FETCH_PRODUCTS,
    data,
});


export const fetchProduct = (data) => ({
    type: FETCH_PRODUCT,
    data,
});

export const updateProduct = (data) => ({
    type: UPDATE_PRODUCT,
    data,
});

export const deleteProduct = (data) => ({
    type: DELETE_PRODUCT,
    data,
});

