import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import SocketIOClient from "socket.io-client";
import {toast} from "react-toastify";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchBotFields} from "../../actions/quiz/botfields";
import BotFieldItem from "../../components/items/BotFieldItem";
import BotFieldItemHeader from "../../components/items/BotFieldItem/BotFieldItemHeader";
import BotFieldEditCreateModal from "../../components/modals/BotFieldEditCreateModal/BotFieldEditCreateModal";
import BotFieldConnectionEditCreateModal from "../../components/modals/BotFieldConnectionEditCreateModal";
import QuizModal from "../../components/modals/QuizModal";
import ExpressionEditCreateModal from "../../components/modals/ExpressionEditCreateModal";
import {createExpression, fetchExpressions} from "../../actions/quiz/expressions";
import './styles.scss';
import ReportSheets from "./ReportSheets";
import {NODE_SERVER_HOST, WEBSITE_HOST} from "../../constants";
import ResponseModal from "../../components/modals/ResponseModal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import {fetchBotForm, updateBotForm} from "../../actions/quiz/botforms";


class BotEdit extends React.Component {

    static propTypes = {
        botfields: PropTypes.object,
        expressions: PropTypes.object,
        fetchBotFields: PropTypes.func,
        fetchExpressions: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            botform: undefined,
            message: undefined,
            field_child: undefined,
            botfield: undefined,
            connection: undefined,
            expression: undefined,
        }
    }

    socket = SocketIOClient(NODE_SERVER_HOST, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99999
    });

    componentDidMount() {
        let {params} = this.props.match;
        if (params.botFormId) {
            this.props.fetchBotForm({id: params.botFormId});
        }
        this.props.fetchBotFields({bot_form_id: params.botFormId});
        this.props.fetchExpressions({bot_form_id: params.botFormId});

        this.setSocketListeners();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {botforms: {detail, isFetching, isUpdating, message, error, retry}} = this.props;
        if (!isFetching && prevProps.botforms.isFetching && !error && !retry) {
            this.setState({botform: detail});
        }
        if (!isUpdating && prevProps.botforms.isUpdating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.botforms.isUpdating && !error && !retry) {
            toast("Meta data updated")
        }
    }


    setSocketListeners = async () => {
        let user = localStorage.getItem('user');
        console.warn(user);
        if (user) {
            let userId = JSON.parse(user).id;
            console.warn(userId);
            // this.socket.on(`ASSISTANT:${userId}`, this.handleAssistantSocketData);
            this.socket.on(`PAGE:${userId}`, this.handlePageSocketData);
            this.socket.on('reconnect', (attemptNumber) => {
                console.warn('connected');
                // DropDownHolder.alert('success', 'Internet', 'Connected successfully');
            });
            this.socket.on('disconnect', (reason) => {
                if (reason === 'io server disconnect') {
                    // the disconnection was initiated by the server, you need to reconnect manually
                    this.socket.connect();
                }
                // else the socket will automatically try to reconnect
            });
        }
    };

    handlePageSocketData = (data) => {
        let {history, match} = this.props;
        if (data.url.indexOf('http') === 0) {
            window.location.replace(data.url);
        } else {
            // window.location.replace(`https://misters.in/en${data.url}`);
            // window.location.replace(`http://localhost:8079/en${data.url}`);
            window.open(`${WEBSITE_HOST}/en${data.url}`, "_blank");
        }
    };

    handleCreateExpression = () => {
        let {params} = this.props.match;
        this.setState({expression: {name: '', expression: '', bot_form_id: params.botFormId}});
        // this.props.createExpression({name: });
    };

    handleConnectionClick = (connection_id) => {
        this.setState({
            connection: {
                id: connection_id,
                child_id: undefined,
                parent_id: undefined,
                show_when: '',
                actions: '',
            },
        });
    };

    handleAddConnectionClick = ({child_id, parent_id}) => {
        this.setState({
            connection: {
                child_id,
                parent_id,
                show_when: '',
                actions: '',
            },
        });
    };

    handleBotFieldCreate = ({field_parent_id = undefined}) => {
        let {params} = this.props.match;
        this.setState({
            botfield: {
                active: true,
                field_id: "",
                field_parent_id,
                predecessors: [],
                successors: [],
                text: "",
                type: "",
                value: "",
                web_actions: "",
                bot_form_id: params.botFormId,
            },
            message: undefined,
        })
    };

    getBotFieldFromId = (botfield_id) => {
        let {botfields} = this.props;
        let mappedFields = botfields.list.map(obj => [obj, ...obj.field_children]);
        mappedFields = [].concat(...mappedFields);
        let filteredFields = mappedFields.filter(obj => obj.id === botfield_id);
        if (filteredFields.length) {
            return filteredFields[0];
        }
        return undefined;
    };

    handleBotFieldClick = (botfield_id) => {
        let botfield = this.getBotFieldFromId(botfield_id);
        if (botfield) {
            this.setState({botfield});
        }
    };

    handleCloseBotFieldModal = () => {
        this.setState({botfield: undefined});
    };

    handleCloseBotFieldConnectionModal = () => {
        this.setState({connection: undefined});
    };

    handleBotFormChange = (botform) => {
        this.setState({botform});
    };

    handleSaveBotForm = () => {
        if (this.state.botform.id) {
            this.props.updateBotForm(this.state.botform);
        } else {
            this.props.createBotForm({...this.state.botform});
        }
    };

    _renderEditFields = () => {
        let {botfields} = this.props;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 pt-5">
                        <Button variant={'dark'} size={'sm'}
                                onClick={this.handleBotFieldCreate}>Create Field</Button>
                    </div>
                    <div className="col-12 pt-2">
                        <div className="bot-edit-page">
                            <BotFieldItemHeader/>
                            {
                                botfields.list.map(
                                    (botfield, index) => (
                                        <BotFieldItem key={JSON.stringify(botfield)}
                                                      botfield={botfield} index={index}
                                                      getBotFieldFromId={this.getBotFieldFromId}
                                                      handleBotFieldConnectionClick={this.handleConnectionClick}
                                                      handleAddConnectionClick={this.handleAddConnectionClick}
                                                      handleBotFieldCreate={this.handleBotFieldCreate}
                                                      handleBotFieldTestClick={() => this.setState({test_bot_field_id: botfield.id})}
                                                      handleBotFieldClick={this.handleBotFieldClick}/>
                                    )
                                )
                            }
                        </div>
                        {
                            !!this.state.botfield &&
                            <BotFieldEditCreateModal show={true}
                                                     botfield={this.state.botfield}
                                                     handleClose={this.handleCloseBotFieldModal}/>
                        }
                        {
                            !!this.state.connection &&
                            <BotFieldConnectionEditCreateModal show={true}
                                                               connection={this.state.connection}
                                                               handleClose={this.handleCloseBotFieldConnectionModal}/>
                        }
                        {
                            !!this.state.test_bot_field_id &&
                            <QuizModal show={true} bot_field_id={this.state.test_bot_field_id}
                                       handleClose={() => this.setState({test_bot_field_id: undefined})}/>
                        }
                        {
                            botfields.isFetchingList &&
                            <div className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    };

    handleCheckOutput = (response) => {
        let {params} = this.props.match;
        this.props.fetchExpressions({bot_form_id: params.botFormId, response});
    };

    _renderEditExpressions = () => {
        let {expressions} = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 pt-2">
                        <ResponseModal handleCheckOutput={this.handleCheckOutput}/>
                    </div>
                    <div className="col-12 pt-2 text-right">
                        <Button size={'sm'}
                                onClick={this.handleCreateExpression}>Create Expression</Button>
                    </div>
                    <div className="col-12 pt-3">
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Expression</th>
                                <th>Action</th>
                                <th>Output</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                expressions.list.map(
                                    (expression, index) => (
                                        <tr key={expression.id}>
                                            <td>{index + 1}</td>
                                            <td>{expression.name}</td>
                                            <td className="expression-table-expression">{expression.expression}</td>
                                            <td>
                                                <Button size={'sm'}
                                                        onClick={() => this.setState({expression})}>edit</Button>
                                            </td>
                                            <td> {expression.output !== null ? (expression.output).toString() : '-'} </td>
                                        </tr>
                                    )
                                )
                            }
                            </tbody>
                        </Table>
                    </div>
                    {
                        !!this.state.expression &&
                        <ExpressionEditCreateModal show={true}
                                                   expression={this.state.expression}
                                                   handleClose={() => this.setState({expression: undefined})}/>
                    }
                </div>
            </div>
        )
    };

    render() {
        let {location, push} = this.props.history;

        let {botform, message} = this.state;

        if (!!botform) {
            botform.root_bot_fields = botform.root_bot_fields || [];
            botform.bot_fields = botform.bot_fields || [];
        }

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Quiz Editor'}/>
                {!!botform &&
                    <div className="p-5">
                        <div className="pb-4">
                            To test quiz on website -&nbsp;
                            <a target="_blank"
                               href={`https://misters.in/en/quiz/custom/?bot_form_id=${botform.bot_form_id}&field_id=${botform.bot_field_id ? botform.bot_field_id: ''}`}>
                                click here
                            </a>
                        </div>
                        <div className="pb-4">
                            Edit other meta fields from here -&nbsp;
                            <a target="_blank"
                               href={`https://admin.misters.in/admin/bot/botform/${botform.id}/change/`}>
                                click here
                            </a>
                        </div>
                        {
                            (!botform.bot_form_id || (!!botform.root_bot_fields && !botform.root_bot_fields.length)) &&
                              <p className="text-danger">Please add bot_form_id and starting question if the link is not working</p>
                        }
                        <Form.Row>
                            <Form.Group as={Col} controlId="fieldId">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text"
                                              placeholder="Enter Name"
                                              value={botform.name}
                                              isInvalid={!!message && !!message.name}
                                              onChange={(e) => this.handleBotFormChange({
                                                  ...botform,
                                                  name: e.target.value,
                                              })}/>
                                <Form.Control.Feedback type="invalid">
                                    {!!message && message.name}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="fieldId">
                                <Form.Label>Bot Field ID</Form.Label>
                                <Form.Control type="text"
                                              placeholder="Enter Bot Field ID"
                                              value={botform.bot_form_id}
                                              isInvalid={!!message && !!message.bot_form_id}
                                              onChange={(e) => this.handleBotFormChange({
                                                  ...botform,
                                                  bot_form_id: e.target.value,
                                              })}/>
                                <Form.Text className="text-muted">
                                    Space not allowed
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    {!!message && message.bot_form_id}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="fieldId">
                            <Form.Label>Starting Question</Form.Label>
                            <Select options={botform.bot_fields.map(o => ({label: o[1], value: o[0]}))}
                                    className="bot-form-bot-fields-select"
                                    isMulti={true}
                                    value={botform.bot_fields.filter(o => botform.root_bot_fields.indexOf(o[0]) > -1).map(o => ({
                                        label: o[1],
                                        value: o[0]
                                    }))}
                                    onChange={(value) => this.handleBotFormChange({
                                        ...botform,
                                        root_bot_fields: value.map(o => o.value),
                                    })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.root_bot_fields}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Button variant="success" onClick={this.handleSaveBotForm}>Save Form Meta data</Button>
                    </div>
                }

                <Tabs defaultActiveKey={"#fields"}
                      activeKey={location.hash || "#fields"}
                      onSelect={k => push(k)}
                      id="bot-tabs">
                    <Tab eventKey="#fields"
                         title="Fields">
                        {this._renderEditFields()}
                    </Tab>
                    <Tab eventKey="#expressions"
                         title="Expressions">
                        {this._renderEditExpressions()}
                    </Tab>
                    <Tab eventKey="#reportsheets"
                         title="Report Sheets">
                        <ReportSheets/>
                    </Tab>
                </Tabs>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    botforms: state.quiz.botforms,
    botfields: state.quiz.botfields,
    expressions: state.quiz.expressions,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchBotFields,
    fetchExpressions,
    createExpression,
    fetchBotForm,
    updateBotForm,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(BotEdit);
