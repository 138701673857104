import {
    CREATE_PRODUCT_LANGUAGE,
    CREATE_PRODUCT_LANGUAGE_RESULT,
    CREATE_PRODUCT_LANGUAGE_ERROR,
    CREATE_PRODUCT_LANGUAGE_RETRY,

    FETCH_PRODUCT_LANGUAGE,
    FETCH_PRODUCT_LANGUAGE_RESULT,
    FETCH_PRODUCT_LANGUAGE_ERROR,
    FETCH_PRODUCT_LANGUAGE_RETRY,

    UPDATE_PRODUCT_LANGUAGE,
    UPDATE_PRODUCT_LANGUAGE_RESULT,
    UPDATE_PRODUCT_LANGUAGE_ERROR,
    UPDATE_PRODUCT_LANGUAGE_RETRY,

    DELETE_PRODUCT_LANGUAGE,
    DELETE_PRODUCT_LANGUAGE_RESULT,
    DELETE_PRODUCT_LANGUAGE_ERROR,
    DELETE_PRODUCT_LANGUAGE_RETRY,
} from '../../actions/quiz/productlanguages';


const initialState = {
    detail: {},
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_PRODUCT_LANGUAGE:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_PRODUCT_LANGUAGE_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_PRODUCT_LANGUAGE_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_PRODUCT_LANGUAGE_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_PRODUCT_LANGUAGE:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_PRODUCT_LANGUAGE_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_PRODUCT_LANGUAGE_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_PRODUCT_LANGUAGE_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_PRODUCT_LANGUAGE:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_PRODUCT_LANGUAGE_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_PRODUCT_LANGUAGE_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_PRODUCT_LANGUAGE_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_PRODUCT_LANGUAGE:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_PRODUCT_LANGUAGE_RESULT:
            return {...state, isDeleting: false, detail: action.data};
        case DELETE_PRODUCT_LANGUAGE_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_PRODUCT_LANGUAGE_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
