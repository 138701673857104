import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';

import './styles.scss';


export default class ConnectionItem extends PureComponent {

    static propTypes = {
        name: PropTypes.string,
        handleConnectionClick: PropTypes.func,
        handleBotFieldClick: PropTypes.func,
    };

    render() {
        const {name} = this.props;

        return (
            <div className="connection-item">
                <div className="connection-item-name">{name}</div>
                <button onClick={this.props.handleConnectionClick}>
                    <i className="fa fa-edit" style={{fontSize: '1.75em'}}/>
                </button>
                <button onClick={this.props.handleBotFieldClick}>
                    <i className="fa fa-link" style={{fontSize: '1.75em'}}/>
                </button>
            </div>
        );
    }
}
