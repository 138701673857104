import {takeEvery} from 'redux-saga/effects';

import {createBotField, fetchBotFields, fetchBotField, updateBotField, deleteBotField, copyBotField} from './botfields';
import {createBotForm, fetchBotForms, fetchBotForm, updateBotForm, deleteBotForm, cloneBotForm} from './botforms';
import {fetchExpression, fetchExpressions, createExpression, updateExpression, deleteExpression} from './expressions';

import {
    COPY_BOT_FIELD,
    CREATE_BOT_FIELD,
    DELETE_BOT_FIELD,
    FETCH_BOT_FIELD,
    FETCH_BOT_FIELDS,
    UPDATE_BOT_FIELD
} from "../../actions/quiz/botfields";

import {
    CREATE_BOT_FIELD_CONNECTION,
    DELETE_BOT_FIELD_CONNECTION,
    FETCH_BOT_FIELD_CONNECTION,
    FETCH_BOT_FIELD_CONNECTIONS,
    UPDATE_BOT_FIELD_CONNECTION,
} from "../../actions/quiz/connections";
import {
    CLONE_BOT_FORM,
    CREATE_BOT_FORM,
    DELETE_BOT_FORM,
    FETCH_BOT_FORM,
    FETCH_BOT_FORMS,
    UPDATE_BOT_FORM
} from "../../actions/quiz/botforms";
import {
    createBotFieldConnection,
    deleteBotFieldConnection,
    fetchBotFieldConnection,
    fetchBotFieldConnections,
    updateBotFieldConnection
} from "./connections";
import {
    CREATE_EXPRESSION,
    DELETE_EXPRESSION, FETCH_EXPRESSION,
    FETCH_EXPRESSIONS,
    UPDATE_EXPRESSION
} from "../../actions/quiz/expressions";
import {createAnswer, updateAnswer} from "./answers";
import {CREATE_ANSWER, UPDATE_ANSWER} from "../../actions/quiz/answers";
import {
    createBotFieldFile,
    deleteBotFieldFile,
    fetchBotFieldFile,
    fetchBotFieldFiles,
    updateBotFieldFile
} from "./botfieldfiles";
import {
    CREATE_BOT_FIELD_FILE, DELETE_BOT_FIELD_FILE,
    FETCH_BOT_FIELD_FILE,
    FETCH_BOT_FIELD_FILES,
    UPDATE_BOT_FIELD_FILE
} from "../../actions/quiz/botfieldfiles";
import {createProduct, deleteProduct, fetchProduct, fetchProducts, updateProduct} from "./products";
import {
    CREATE_PRODUCT,
    DELETE_PRODUCT,
    FETCH_PRODUCT,
    FETCH_PRODUCTS,
    UPDATE_PRODUCT
} from "../../actions/quiz/products";

import {
    createProductItemConnection,
    deleteProductItemConnection,
    fetchProductItemConnection,
    fetchProductItemConnections,
    updateProductItemConnection
} from "./productitemconnections";
import {
    CREATE_PRODUCT_ITEM_CONNECTION,
    DELETE_PRODUCT_ITEM_CONNECTION,
    FETCH_PRODUCT_ITEM_CONNECTION,
    FETCH_PRODUCT_ITEM_CONNECTIONS,
    UPDATE_PRODUCT_ITEM_CONNECTION
} from "../../actions/quiz/productitemconnections";

import {
    createProductImage,
    deleteProductImage
} from "./productimages";
import {
    CREATE_PRODUCT_IMAGE,
    DELETE_PRODUCT_IMAGE
} from "../../actions/quiz/productimages";

import {
    fetchProductFeature,
    createProductFeature,
    updateProductFeature,
    deleteProductFeature,
} from "./productfeatures";
import {
    CREATE_PRODUCT_FEATURE,
    FETCH_PRODUCT_FEATURE,
    UPDATE_PRODUCT_FEATURE,
    DELETE_PRODUCT_FEATURE,
} from "../../actions/quiz/productfeatures";

import {
    fetchProductRelation,
    createProductRelation,
    updateProductRelation,
    deleteProductRelation,
} from "./productrelations";
import {
    FETCH_PRODUCT_RELATION,
    CREATE_PRODUCT_RELATION,
    UPDATE_PRODUCT_RELATION,
    DELETE_PRODUCT_RELATION,
} from "../../actions/quiz/productrelations";

import {
    fetchProductLanguage,
    createProductLanguage,
    updateProductLanguage,
    deleteProductLanguage,
} from "./productlanguages";
import {
    CREATE_PRODUCT_LANGUAGE,
    FETCH_PRODUCT_LANGUAGE,
    UPDATE_PRODUCT_LANGUAGE,
    DELETE_PRODUCT_LANGUAGE,
} from "../../actions/quiz/productlanguages";


export default [
    takeEvery(CREATE_BOT_FIELD, createBotField),
    takeEvery(FETCH_BOT_FIELDS, fetchBotFields),
    takeEvery(FETCH_BOT_FIELD, fetchBotField),
    takeEvery(UPDATE_BOT_FIELD, updateBotField),
    takeEvery(DELETE_BOT_FIELD, deleteBotField),

    takeEvery(CREATE_BOT_FIELD_CONNECTION, createBotFieldConnection),
    takeEvery(FETCH_BOT_FIELD_CONNECTIONS, fetchBotFieldConnections),
    takeEvery(FETCH_BOT_FIELD_CONNECTION, fetchBotFieldConnection),
    takeEvery(UPDATE_BOT_FIELD_CONNECTION, updateBotFieldConnection),
    takeEvery(DELETE_BOT_FIELD_CONNECTION, deleteBotFieldConnection),

    takeEvery(COPY_BOT_FIELD, copyBotField),
    takeEvery(CREATE_BOT_FORM, createBotForm),
    takeEvery(FETCH_BOT_FORMS, fetchBotForms),
    takeEvery(FETCH_BOT_FORM, fetchBotForm),
    takeEvery(UPDATE_BOT_FORM, updateBotForm),
    takeEvery(DELETE_BOT_FORM, deleteBotForm),
    takeEvery(CLONE_BOT_FORM, cloneBotForm),

    takeEvery(FETCH_EXPRESSION, fetchExpression),
    takeEvery(FETCH_EXPRESSIONS, fetchExpressions),
    takeEvery(CREATE_EXPRESSION, createExpression),
    takeEvery(UPDATE_EXPRESSION, updateExpression),
    takeEvery(DELETE_EXPRESSION, deleteExpression),

    takeEvery(CREATE_ANSWER, createAnswer),
    takeEvery(UPDATE_ANSWER, updateAnswer),

    takeEvery(CREATE_BOT_FIELD_FILE, createBotFieldFile),
    takeEvery(FETCH_BOT_FIELD_FILES, fetchBotFieldFiles),
    takeEvery(FETCH_BOT_FIELD_FILE, fetchBotFieldFile),
    takeEvery(UPDATE_BOT_FIELD_FILE, updateBotFieldFile),
    takeEvery(DELETE_BOT_FIELD_FILE, deleteBotFieldFile),

    takeEvery(FETCH_PRODUCT, fetchProduct),
    takeEvery(FETCH_PRODUCTS, fetchProducts),
    takeEvery(CREATE_PRODUCT, createProduct),
    takeEvery(UPDATE_PRODUCT, updateProduct),
    takeEvery(DELETE_PRODUCT, deleteProduct),

    takeEvery(CREATE_PRODUCT_IMAGE, createProductImage),
    takeEvery(DELETE_PRODUCT_IMAGE, deleteProductImage),

    takeEvery(FETCH_PRODUCT_ITEM_CONNECTION, fetchProductItemConnection),
    takeEvery(FETCH_PRODUCT_ITEM_CONNECTIONS, fetchProductItemConnections),
    takeEvery(CREATE_PRODUCT_ITEM_CONNECTION, createProductItemConnection),
    takeEvery(UPDATE_PRODUCT_ITEM_CONNECTION, updateProductItemConnection),
    takeEvery(DELETE_PRODUCT_ITEM_CONNECTION, deleteProductItemConnection),

    takeEvery(FETCH_PRODUCT_FEATURE, fetchProductFeature),
    takeEvery(CREATE_PRODUCT_FEATURE, createProductFeature),
    takeEvery(UPDATE_PRODUCT_FEATURE, updateProductFeature),
    takeEvery(DELETE_PRODUCT_FEATURE, deleteProductFeature),

    takeEvery(FETCH_PRODUCT_RELATION, fetchProductRelation),
    takeEvery(CREATE_PRODUCT_RELATION, createProductRelation),
    takeEvery(UPDATE_PRODUCT_RELATION, updateProductRelation),
    takeEvery(DELETE_PRODUCT_RELATION, deleteProductRelation),

    takeEvery(FETCH_PRODUCT_LANGUAGE, fetchProductLanguage),
    takeEvery(CREATE_PRODUCT_LANGUAGE, createProductLanguage),
    takeEvery(UPDATE_PRODUCT_LANGUAGE, updateProductLanguage),
    takeEvery(DELETE_PRODUCT_LANGUAGE, deleteProductLanguage),
];
