import {
    CREATE_COUPON,
    CREATE_COUPON_RESULT,
    CREATE_COUPON_ERROR,
    CREATE_COUPON_RETRY,

    FETCH_COUPONS,
    FETCH_COUPONS_RESULT,
    FETCH_COUPONS_ERROR,
    FETCH_COUPONS_RETRY,

    DELETE_COUPON,
    DELETE_COUPON_ERROR,
    DELETE_COUPON_RESULT,
    DELETE_COUPON_RETRY,

    FETCH_COUPON,
    FETCH_COUPON_ERROR,
    FETCH_COUPON_RESULT,
    FETCH_COUPON_RETRY,

    UPDATE_COUPON,
    UPDATE_COUPON_ERROR,
    UPDATE_COUPON_RESULT,
    UPDATE_COUPON_RETRY
} from '../../actions/cms/coupons';


const initialState = {
    detail: {},
    list: [],
    isCreating: false,
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_COUPON:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_COUPON_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_COUPON_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_COUPON_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_COUPONS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_COUPONS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_COUPONS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_COUPONS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_COUPON:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_COUPON_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_COUPON_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_COUPON_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_COUPON:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_COUPON_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_COUPON_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_COUPON_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_COUPON:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_COUPON_RESULT:
            return {...state, isDeleting: false};
        case DELETE_COUPON_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_COUPON_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
