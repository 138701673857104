//External Imports
import {combineReducers} from 'redux';
//My Imports
import reportsheets from './reportsheets';
import redflags from './redflags';
import analysisscores from './analysisscores';
import analysiscards from './analysiscards';
import analysiscardtexts from './analysiscardtexts';
import reportsheetproducts from './reportsheetproducts';


export default combineReducers({
    reportsheets,
    redflags,
    analysisscores,
    analysiscards,
    analysiscardtexts,
    reportsheetproducts,
});
