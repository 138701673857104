import React from "react";
import Modal from 'react-bootstrap/Modal';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import ReactJson from 'react-json-view';

import {createAnswer, updateAnswer} from "../../../actions/quiz/answers";
import {
    CHOICE,
    DATE,
    FLOAT,
    getMessages,
    HEIGHT,
    INTEGER,
    languageOptions,
    MULTIPLE_CHOICE,
    TEXT,
    EMAIL,
    STAR,
    RATING,
} from "../../../constants";
import HeightInput from "../../inputs/HeightInput";
import SingleInput from "../../inputs/SingleInput";
import ChoiceInput from "../../inputs/ChoiceInput";
import './styles.scss';
import StarInput from "../../inputs/StarInput";
import RatingInput from "../../inputs/RatingInput";


class QuizModal extends React.Component {

    static propTypes = {
        bot_field_id: PropTypes.number,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
        createAnswer: PropTypes.func,
        updateAnswer: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            answer: {},
            message: undefined,
            language: 'english',
        }
    }

    componentDidMount() {
        if (this.props.bot_field_id) {
            this.props.createAnswer({bot_field_id: this.props.bot_field_id});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {answers: {detail, isCreating, isUpdating, message, error, retry}} = this.props;

        if (!isUpdating && prevProps.answers.isUpdating && !error && !retry) {
            this.setState({answer: detail});
        }
        if (!isCreating && prevProps.answers.isCreating && !error && !retry) {
            this.setState({answer: detail});
        }
        if (!isCreating && prevProps.answers.isCreating && error && !retry) {
            this.setState({answer: {}, message});
        }
        if (!isUpdating && prevProps.answers.isUpdating && error && !retry) {
            this.setState({answer: {}, message});
        }
    }

    renderField = () => {
        const {answer, language} = this.state;
        if (answer.bot_field) {
            switch (answer.bot_field.type) {
                case HEIGHT:
                    return <HeightInput handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                case INTEGER:
                case TEXT:
                case EMAIL:
                case FLOAT:
                case DATE:
                    return <SingleInput language={language}
                                        inputType={answer.bot_field.type}
                                        handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                case CHOICE:
                    return <ChoiceInput language={language}
                                        choices={answer.field_response_children}
                                        handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                case MULTIPLE_CHOICE:
                    return <ChoiceInput multipleChoice={true}
                                        language={language}
                                        choices={answer.field_response_children}
                                        handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                case STAR:
                    return <StarInput hideNextButton={true}
                                      handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;

                case RATING:
                    return <RatingInput hideNextButton={true}
                                        handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                default:
                    return null;
            }
        }
        return null;
    };


    handleSendClick = async (responseId, data) => {
        this.props.updateAnswer({id: responseId, ...data});
    };

    render() {
        let {show, answers: {detail}, handleConfirm, handleClose} = this.props;
        let {answer, language, message} = this.state;

        if (!answer.bot_field)
            return null;

        let {[language]: htmlData = detail.bot_field.text} = answer.bot_field.languages;

        return (
            <Modal show={show} onHide={handleClose} size='xl' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Quiz Test</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*<h5>{!!answer.bot_field && answer.bot_field.text}</h5>*/}
                    <div dangerouslySetInnerHTML={{__html: htmlData}}/>
                    {this.renderField()}

                    {
                        !!message &&
                        <Alert variant={'danger'}>
                            {getMessages(message)}
                        </Alert>
                    }
                    <div className="quiz-modal-response-dict">
                        {detail.response_dict && <ReactJson src={detail.response_dict}/>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Select options={languageOptions}
                            className="quiz-modal-language-select"
                            value={languageOptions.filter(o => o.value === language)}
                            onChange={(value) => {
                                this.setState({language: value.value,})
                            }}/>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    answers: state.quiz.answers,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createAnswer,
    updateAnswer,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(QuizModal);
