export const CREATE_PRODUCT_FEATURE = 'CREATE_PRODUCT_FEATURE';
export const CREATE_PRODUCT_FEATURE_RESULT = 'CREATE_PRODUCT_FEATURE_RESULT';
export const CREATE_PRODUCT_FEATURE_ERROR = 'CREATE_PRODUCT_FEATURE_ERROR';
export const CREATE_PRODUCT_FEATURE_RETRY = 'CREATE_PRODUCT_FEATURE_RETRY';


export const FETCH_PRODUCT_FEATURE = 'FETCH_PRODUCT_FEATURE';
export const FETCH_PRODUCT_FEATURE_RESULT = 'FETCH_PRODUCT_FEATURE_RESULT';
export const FETCH_PRODUCT_FEATURE_ERROR = 'FETCH_PRODUCT_FEATURE_ERROR';
export const FETCH_PRODUCT_FEATURE_RETRY = 'FETCH_PRODUCT_FEATURE_RETRY';


export const UPDATE_PRODUCT_FEATURE = 'UPDATE_PRODUCT_FEATURE';
export const UPDATE_PRODUCT_FEATURE_RESULT = 'UPDATE_PRODUCT_FEATURE_RESULT';
export const UPDATE_PRODUCT_FEATURE_ERROR = 'UPDATE_PRODUCT_FEATURE_ERROR';
export const UPDATE_PRODUCT_FEATURE_RETRY = 'UPDATE_PRODUCT_FEATURE_RETRY';


export const DELETE_PRODUCT_FEATURE = 'DELETE_PRODUCT_FEATURE';
export const DELETE_PRODUCT_FEATURE_RESULT = 'DELETE_PRODUCT_FEATURE_RESULT';
export const DELETE_PRODUCT_FEATURE_ERROR = 'DELETE_PRODUCT_FEATURE_ERROR';
export const DELETE_PRODUCT_FEATURE_RETRY = 'DELETE_PRODUCT_FEATURE_RETRY';


export const createProductFeature = (data) => ({
    type: CREATE_PRODUCT_FEATURE,
    data,
});

export const fetchProductFeature = (data) => ({
    type: FETCH_PRODUCT_FEATURE,
    data,
});

export const updateProductFeature = (data) => ({
    type: UPDATE_PRODUCT_FEATURE,
    data,
});

export const deleteProductFeature = (data) => ({
    type: DELETE_PRODUCT_FEATURE,
    data,
});

