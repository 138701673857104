import {HOST} from "../../../constants";
import {getHttpsImageUrl} from "../../../utils";


export default class QuillHelper {

    static toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        [{'direction': 'rtl'}],                         // text direction

        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        ['link', 'image', 'video', 'formula'],    // add's image support
        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],

        ['clean']                                         // remove formatting button
    ];

    handelImageClick = (e) => {
        if (e.target.tagName === 'IMG') {
            let alt = prompt("Enter Alt value for image", e.target.alt);
            if (!!alt) {
                e.target.alt = alt;
            }
        }
    };

    constructor(selector, onChange) {
        let quill = new Quill(selector, {
            theme: 'snow',
            modules: {
                toolbar: QuillHelper.toolbarOptions
            }
        });

        // document.querySelector(selector).removeEventListener('click', e => this.handelImageClick(e), true);

        document.querySelector(selector).addEventListener('click', this.handelImageClick, true);
        // quill editor add image handler
        // 'https://publish.twitter.com/oembed?url='
        // 'https://twitter.com/exultantdreamer/status/1191626174666924035'
        // quill.getModule('toolbar').addHandler('link', async (value) => {
        //     console.warn('link:', value);
        //     if (value) {
        //         let href = prompt('Enter the URL');
        //
        //         if (href.startsWith("https://twitter.com/")) {
        //             let res = await fetch(`https://publish.twitter.com/oembed?url=${href}`);
        //             let result = await res.json();
        //             const range = quill.getSelection();
        //             quill.insertEmbed(range.index, 'image', `${getHttpsImageUrl(url)}`);
        //         }
        //         this.quill.format('link', href);
        //     } else {
        //         this.quill.format('link', false);
        //     }
        // });

        quill.getModule('toolbar').addHandler('image', () => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.click();
            // Listen upload local image and save to server
            input.onchange = () => {
                const file = input.files[0];
                // file type is only image.
                if (/^image\//.test(file.type)) {
                    const fd = new FormData();
                    fd.append('image', file);

                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', `${HOST}/api/v1/blog/posts/image/`, true);
                    let alt = '';
                    xhr.onloadstart = () => {
                        alt = prompt("Enter Alt value for image", "");
                    };
                    xhr.onload = () => {
                        if (xhr.status === 201) {
                            // this is callback data: url
                            const url = JSON.parse(xhr.responseText).image.split('?')[0];
                            const range = quill.getSelection();
                            quill.insertEmbed(range.index, 'image', getHttpsImageUrl(url));
                            quill.formatText(range.index, range.index + 1, 'alt', alt);
                        }
                    };
                    xhr.send(fd);
                } else {
                    console.warn('You could only upload images.');
                }
            };
        });

        quill.on('text-change', onChange);
        this.quill = quill;
        this.selector = selector;
    }


    setHtmlText = (htmlToInsert) => {
        let editor = document.querySelector(`${this.selector} .ql-editor`);
        editor.innerHTML = htmlToInsert
    };

    getHtmlText = () => {
        let editor = document.querySelector(`${this.selector} .ql-editor`);
        // console.warn(editor, this.selector);
        return editor.innerHTML;
    };
}

// $('#post-create-form').submit(function () {
//     $('#id_content').val($('#editor .ql-editor').html());
//     return true;
// });
// {% if post %}
// {#quill.clipboard.dangerouslyPasteHTML("{{ post.content | safe}}");#}
// {#let data = '{{ post.content }}';#}
// {#console.warn(data);#}
// $('#editor .ql-editor').html(`{{ post.content | safe}}`);
// {% endif %}
// function previewBlog() {
//     let blogHtml = $('#editor .ql-editor').html();
//     let titleVal = $('#id_title').val();
//     if ($('#preview-blog').html() !== blogHtml) {
//         $('#preview-blog').html(blogHtml);
//     }
//     if (titleVal !== $('#posttitle').text()) {
//         $('#posttitle').text(titleVal);
//     }
// }
