//External Imports
import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Select from "react-select";
//My Imports
import {languageOptions} from "../../../constants";


export default ({onLanguageChange}) => {
    let [value, setValue] = useState('english');

    let onChange = (value) => {
        setValue(value);
        onLanguageChange(languageOptions.filter(o => o.value === value)[0]);
    };

    return (
        <Form.Group controlId="fieldText">
            <Form.Label>Language</Form.Label>
            <Select options={languageOptions}
                    className="bot-form-bot-fields-select"
                    value={languageOptions.filter(o => o.value === value)}
                    onChange={({label, value}) => onChange(value)}/>
        </Form.Group>
    )
}
