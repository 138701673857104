// External Imports
import React from "react";
// My Imports
import {
    Home, Login, Logout, Bot, BotEdit,
    Products, NotFound, Reviews, Coupons
} from './screens';
import MessageTypes from "./screens/MessageTypes";
import {PermissionEnum, RoleEnum} from "./permisions";


const routes = [
    // {
    //     exact: true,
    //     path: '/',
    //     component: Home,
    //     permissions: [],
    //     roles: [],
    // },
    {
        exact: true,
        path: '/',
        component: Bot,
        permissions: [],
        roles: [RoleEnum.QUIZ_WRITER],
    },
    {
        exact: true,
        path: '/bot/:botFormId/edit/',
        component: BotEdit,
        permissions: [],
        roles: [RoleEnum.QUIZ_WRITER],
    },
    // {
    //     exact: true,
    //     path: '/messagetypes/',
    //     component: MessageTypes,
    //     permissions: [
    //         PermissionEnum.EVENTS_MESSAGE_TYPE_READ, PermissionEnum.EVENTS_MESSAGE_TYPE_CREATE,
    //         PermissionEnum.EVENTS_MESSAGE_TYPE_DELETE, PermissionEnum.EVENTS_MESSAGE_TYPE_UPDATE,
    //     ],
    //     roles: [],
    // },
    // {
    //     exact: true,
    //     path: '/products',
    //     component: Products,
    //     permissions: [],
    //     roles: [RoleEnum.QUIZ_WRITER],
    // },
    // {
    //     exact: true,
    //     path: '/reviews/',
    //     component: Reviews,
    //     permissions: [],
    //     roles: [RoleEnum.QUIZ_WRITER],
    // },
    // {
    //     exact: true,
    //     path: '/coupons/',
    //     component: Coupons,
    //     permissions: [],
    //     roles: [RoleEnum.QUIZ_WRITER],
    // },
    {
        exact: true,
        path: '/login',
        component: Login,
        permissions: [],
        roles: [],
    },
    {
        exact: true,
        path: '/logout',
        component: Logout,
        permissions: [],
        roles: [],
    },
    {
        path: "*",
        component: NotFound,
        permissions: [],
        roles: [],
    }
];

export default routes;
