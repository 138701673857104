import {call, put} from 'redux-saga/effects';
import {
    CREATE_ANALYSIS_SCORE_RESULT,
    CREATE_ANALYSIS_SCORE_ERROR,
    CREATE_ANALYSIS_SCORE_RETRY,

    FETCH_ANALYSIS_SCORES_RESULT,
    FETCH_ANALYSIS_SCORES_ERROR,
    FETCH_ANALYSIS_SCORES_RETRY,

    FETCH_ANALYSIS_SCORE_RESULT,
    FETCH_ANALYSIS_SCORE_ERROR,
    FETCH_ANALYSIS_SCORE_RETRY,

    UPDATE_ANALYSIS_SCORE_RESULT,
    UPDATE_ANALYSIS_SCORE_ERROR,
    UPDATE_ANALYSIS_SCORE_RETRY,

    DELETE_ANALYSIS_SCORE_RESULT,
    DELETE_ANALYSIS_SCORE_ERROR,
    DELETE_ANALYSIS_SCORE_RETRY,
} from '../../actions/report/analysisscores';
import api from "../api";
import {toast} from "react-toastify";


export function* createAnalysisScore(action) {
    try {
        const response = yield call(api.report.analysisscores.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createAnalysisScore', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_ANALYSIS_SCORE_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_ANALYSIS_SCORE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_ANALYSIS_SCORE_RETRY, retry: true, message: e.message});
    }
}


export function* fetchAnalysisScores(action) {
    try {
        const response = yield call(api.report.analysisscores.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchAnalysisScores', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ANALYSIS_SCORES_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_ANALYSIS_SCORES_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ANALYSIS_SCORES_RETRY, retry: true, message: e.message});
    }
}


export function* fetchAnalysisScore(action) {
    try {
        const response = yield call(api.report.analysisscores.get, action.data);
        const result = yield response.json();
        // console.warn('SAGA:fetchAnalysisScore', action, result);
        if (response.status === 200) {
            yield put({type: FETCH_ANALYSIS_SCORE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_ANALYSIS_SCORE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ANALYSIS_SCORE_RETRY, retry: true, message: e.message});
    }
}


export function* updateAnalysisScore(action) {
    try {
        const response = yield call(api.report.analysisscores.update, action.data);
        const result = yield response.json();
        // console.warn('SAGA:updateAnalysisScore', action, result);
        if (response.status === 200) {
            yield put({type: UPDATE_ANALYSIS_SCORE_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_ANALYSIS_SCORE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_ANALYSIS_SCORE_RETRY, retry: true, message: e.message});
    }
}


export function* deleteAnalysisScore(action) {
    try {
        const response = yield call(api.report.analysisscores.remove, action.data);
        // const result = yield response.json();
        // console.warn('SAGA:deleteAnalysisScore', action);

        if (response.status === 204) {
            yield put({type: DELETE_ANALYSIS_SCORE_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_ANALYSIS_SCORE_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_ANALYSIS_SCORE_RETRY, retry: true, message: e.message});
    }
}
