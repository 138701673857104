export const CREATE_PRODUCT_ITEM_CONNECTION = 'CREATE_PRODUCT_ITEM_CONNECTION';
export const CREATE_PRODUCT_ITEM_CONNECTION_RESULT = 'CREATE_PRODUCT_ITEM_CONNECTION_RESULT';
export const CREATE_PRODUCT_ITEM_CONNECTION_ERROR = 'CREATE_PRODUCT_ITEM_CONNECTION_ERROR';
export const CREATE_PRODUCT_ITEM_CONNECTION_RETRY = 'CREATE_PRODUCT_ITEM_CONNECTION_RETRY';


export const FETCH_PRODUCT_ITEM_CONNECTIONS = 'FETCH_PRODUCT_ITEM_CONNECTIONS';
export const FETCH_PRODUCT_ITEM_CONNECTIONS_RESULT = 'FETCH_PRODUCT_ITEM_CONNECTIONS_RESULT';
export const FETCH_PRODUCT_ITEM_CONNECTIONS_ERROR = 'FETCH_PRODUCT_ITEM_CONNECTIONS_ERROR';
export const FETCH_PRODUCT_ITEM_CONNECTIONS_RETRY = 'FETCH_PRODUCT_ITEM_CONNECTIONS_RETRY';


export const FETCH_PRODUCT_ITEM_CONNECTION = 'FETCH_PRODUCT_ITEM_CONNECTION';
export const FETCH_PRODUCT_ITEM_CONNECTION_RESULT = 'FETCH_PRODUCT_ITEM_CONNECTION_RESULT';
export const FETCH_PRODUCT_ITEM_CONNECTION_ERROR = 'FETCH_PRODUCT_ITEM_CONNECTION_ERROR';
export const FETCH_PRODUCT_ITEM_CONNECTION_RETRY = 'FETCH_PRODUCT_ITEM_CONNECTION_RETRY';


export const UPDATE_PRODUCT_ITEM_CONNECTION = 'UPDATE_PRODUCT_ITEM_CONNECTION';
export const UPDATE_PRODUCT_ITEM_CONNECTION_RESULT = 'UPDATE_PRODUCT_ITEM_CONNECTION_RESULT';
export const UPDATE_PRODUCT_ITEM_CONNECTION_ERROR = 'UPDATE_PRODUCT_ITEM_CONNECTION_ERROR';
export const UPDATE_PRODUCT_ITEM_CONNECTION_RETRY = 'UPDATE_PRODUCT_ITEM_CONNECTION_RETRY';


export const DELETE_PRODUCT_ITEM_CONNECTION = 'DELETE_PRODUCT_ITEM_CONNECTION';
export const DELETE_PRODUCT_ITEM_CONNECTION_RESULT = 'DELETE_PRODUCT_ITEM_CONNECTION_RESULT';
export const DELETE_PRODUCT_ITEM_CONNECTION_ERROR = 'DELETE_PRODUCT_ITEM_CONNECTION_ERROR';
export const DELETE_PRODUCT_ITEM_CONNECTION_RETRY = 'DELETE_PRODUCT_ITEM_CONNECTION_RETRY';


export const createProductItemConnection = (data) => ({
    type: CREATE_PRODUCT_ITEM_CONNECTION,
    data,
});

export const fetchProductItemConnections = (data) => ({
    type: FETCH_PRODUCT_ITEM_CONNECTIONS,
    data,
});


export const fetchProductItemConnection = (data) => ({
    type: FETCH_PRODUCT_ITEM_CONNECTION,
    data,
});

export const updateProductItemConnection = (data) => ({
    type: UPDATE_PRODUCT_ITEM_CONNECTION,
    data,
});

export const deleteProductItemConnection = (data) => ({
    type: DELETE_PRODUCT_ITEM_CONNECTION,
    data,
});

