import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
// My Imports
import './styles.scss';
import {userLogin} from "../../actions/accounts/profile";
import Alert from "react-bootstrap/Alert";
import {getMessages} from "../../constants";


class Login extends React.Component {

    static propTypes = {
        profile: PropTypes.object,
        userLogin: PropTypes.func,
        requestPassword: PropTypes.func,
    };

    state = {
        username: '',
        password: '',
        message: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {profile: {isFetchingLogin, message, error, retry}} = this.props;

        if (!isFetchingLogin && prevProps.profile.isFetchingLogin && error && !retry) {
            this.setState({message});
        }
    }

    handleUsernameChange = (e) => {
        this.setState({username: e.target.value})
    };

    handlePasswordChange = (e) => {
        this.setState({password: e.target.value})
    };

    handleLogin = () => {
        let {username, password} = this.state;

        this.props.userLogin({
            username,
            password,
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.handleLogin();
    };

    render() {
        let {username, password, message} = this.state;
        let messages = getMessages(message);

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                        <div className="min-vh-100 d-flex justify-content-center align-items-center flex-column">
                            <h3 className='text-center mb-4 w-100'>Login</h3>
                            {
                                messages.map(
                                    (message, index) => (
                                        <Alert key={index} variant={'danger'} className='w-100 text-center'>
                                            {message}
                                        </Alert>
                                    )
                                )
                            }
                            <Form className='w-100' onSubmit={this.handleSubmit}>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>Username</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="text" placeholder="Enter Username"
                                                      value={username}
                                                      isInvalid={!!message && !!message.username}
                                                      onChange={this.handleUsernameChange}/>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        {!!message && message.username}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password"
                                                  placeholder="Enter Password"
                                                  value={password}
                                                  isInvalid={!!message && !!message.password}
                                                  onChange={this.handlePasswordChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        {!!message && message.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="pb-3"/>
                                <Button type='submit' variant="dark" block
                                        onClick={this.handleSubmit}>
                                    Login
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    profile: state.accounts.profile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    userLogin,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
