import {call, put} from 'redux-saga/effects';
import api from "../api";
import {toast} from "react-toastify";

import {
    CREATE_PRODUCT_FEATURE_RESULT,
    CREATE_PRODUCT_FEATURE_ERROR,
    CREATE_PRODUCT_FEATURE_RETRY,

    FETCH_PRODUCT_FEATURE_RESULT,
    FETCH_PRODUCT_FEATURE_ERROR,
    FETCH_PRODUCT_FEATURE_RETRY,

    UPDATE_PRODUCT_FEATURE_RESULT,
    UPDATE_PRODUCT_FEATURE_ERROR,
    UPDATE_PRODUCT_FEATURE_RETRY,

    DELETE_PRODUCT_FEATURE_RESULT,
    DELETE_PRODUCT_FEATURE_ERROR,
    DELETE_PRODUCT_FEATURE_RETRY
} from '../../actions/quiz/productfeatures';


export function* createProductFeature(action) {
    try {
        const response = yield call(api.quiz.productfeatures.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createProductFeature', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_PRODUCT_FEATURE_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_PRODUCT_FEATURE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_PRODUCT_FEATURE_RETRY, retry: true, message: e.message});
    }
}

export function* fetchProductFeature(action) {
    try {
        const response = yield call(api.quiz.productfeatures.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchProductFeature', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PRODUCT_FEATURE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_PRODUCT_FEATURE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PRODUCT_FEATURE_RETRY, retry: true, message: e.message});
    }
}


export function* updateProductFeature(action) {
    try {
        const response = yield call(api.quiz.productfeatures.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateProductFeature', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_PRODUCT_FEATURE_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_PRODUCT_FEATURE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_PRODUCT_FEATURE_RETRY, retry: true, message: e.message});
    }
}


export function* deleteProductFeature(action) {
    try {
        const response = yield call(api.quiz.productfeatures.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteProductFeature', action);

        if (response.status === 204) {
            yield put({type: DELETE_PRODUCT_FEATURE_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_PRODUCT_FEATURE_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_PRODUCT_FEATURE_RETRY, retry: true, message: e.message});
    }
}
