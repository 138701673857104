import {
    CREATE_EXPRESSION,
    CREATE_EXPRESSION_RESULT,
    CREATE_EXPRESSION_ERROR,
    CREATE_EXPRESSION_RETRY,

    FETCH_EXPRESSIONS,
    FETCH_EXPRESSIONS_RESULT,
    FETCH_EXPRESSIONS_ERROR,
    FETCH_EXPRESSIONS_RETRY,

    FETCH_EXPRESSION,
    FETCH_EXPRESSION_RESULT,
    FETCH_EXPRESSION_ERROR,
    FETCH_EXPRESSION_RETRY,

    UPDATE_EXPRESSION,
    UPDATE_EXPRESSION_RESULT,
    UPDATE_EXPRESSION_ERROR,
    UPDATE_EXPRESSION_RETRY,

    DELETE_EXPRESSION,
    DELETE_EXPRESSION_RESULT,
    DELETE_EXPRESSION_ERROR,
    DELETE_EXPRESSION_RETRY,
} from '../../actions/quiz/expressions';
import {removeDuplicateFields} from "../../utils";


const initialState = {
    detail: {},
    list: [],
    isCreating: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_EXPRESSION:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_EXPRESSION_RESULT:
            let newList = [action.data, ...state.list];
            return {...state, isCreating: false, detail: action.data, list: newList};
        case CREATE_EXPRESSION_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_EXPRESSION_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_EXPRESSIONS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_EXPRESSIONS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_EXPRESSIONS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_EXPRESSIONS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_EXPRESSION:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_EXPRESSION_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_EXPRESSION_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_EXPRESSION_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_EXPRESSION:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_EXPRESSION_RESULT:
            return {
                ...state, isUpdating: false, detail: action.data,
                list: removeDuplicateFields([action.data, ...state.list])
            };
        case UPDATE_EXPRESSION_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_EXPRESSION_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_EXPRESSION:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_EXPRESSION_RESULT:
            return {...state, isDeleting: false, list: state.list.filter(o => o.id !== action.data.id)};
        case DELETE_EXPRESSION_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_EXPRESSION_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
