import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


class SidebarItem extends PureComponent {

    static propTypes = {
        // type: PropTypes.oneOf(['Dashboard', 'Forms', 'Reports', 'Settings']),
        type: PropTypes.string,
        icon: PropTypes.string,
        active: PropTypes.bool,
        onClick: PropTypes.func,
    };

    state = {
        active: false,
    };

    componentDidMount() {
        this.setState({active: this.props.active});
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.active && this.props.active !== prevProps.active)
            this.setState({active: this.props.active});
    }

    handleParentItemClick = () => {
        this.setState(prevState => this.setState({active: !prevState.active}));
    };

    render() {
        const {type, icon, active, onClick, children} = this.props;

        if (children) {

            return (
                <li className={"sidebar-item-container " + (this.state.active ? 'active' : '')}>
                    <ul>
                        <li className={"sidebar-item " + (this.state.active ? 'active' : '')}
                            onClick={this.handleParentItemClick}>
                            <i className="material-icons">{icon}</i>
                            <span className='side-item-text'>{type}</span>
                        </li>
                        {
                            this.state.active &&
                            children
                        }
                    </ul>
                </li>
            )
        }

        return (
            <li className={"sidebar-item " + (active ? 'active' : '')} onClick={onClick}>
                <i className="material-icons">{icon}</i>
                <span className='side-item-text'>{type}</span>
            </li>
        )
    }
}

export default SidebarItem;