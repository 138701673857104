import {takeEvery} from 'redux-saga/effects';

import {createReview, fetchReview, fetchReviews, updateReview, deleteReview} from './reviews';
import {createCoupon, fetchCoupon, fetchCoupons, updateCoupon, deleteCoupon} from './coupons';

import {CREATE_REVIEW, FETCH_REVIEWS, FETCH_REVIEW, UPDATE_REVIEW, DELETE_REVIEW} from "../../actions/cms/reviews";
import {CREATE_COUPON, FETCH_COUPONS, FETCH_COUPON, UPDATE_COUPON, DELETE_COUPON} from "../../actions/cms/coupons";

import {
    fetchProductQuantityOffer,
    createProductQuantityOffer,
    updateProductQuantityOffer,
    deleteProductQuantityOffer,
} from "./productquantityoffers";
import {
    FETCH_PRODUCT_QUANTITY_OFFER,
    CREATE_PRODUCT_QUANTITY_OFFER,
    UPDATE_PRODUCT_QUANTITY_OFFER,
    DELETE_PRODUCT_QUANTITY_OFFER,
} from "../../actions/cms/productquantityoffers";


export default [

    takeEvery(CREATE_REVIEW, createReview),
    takeEvery(FETCH_REVIEWS, fetchReviews),
    takeEvery(FETCH_REVIEW, fetchReview),
    takeEvery(UPDATE_REVIEW, updateReview),
    takeEvery(DELETE_REVIEW, deleteReview),

    takeEvery(CREATE_COUPON, createCoupon),
    takeEvery(FETCH_COUPONS, fetchCoupons),
    takeEvery(FETCH_COUPON, fetchCoupon),
    takeEvery(UPDATE_COUPON, updateCoupon),
    takeEvery(DELETE_COUPON, deleteCoupon),

    takeEvery(FETCH_PRODUCT_QUANTITY_OFFER, fetchProductQuantityOffer),
    takeEvery(CREATE_PRODUCT_QUANTITY_OFFER, createProductQuantityOffer),
    takeEvery(UPDATE_PRODUCT_QUANTITY_OFFER, updateProductQuantityOffer),
    takeEvery(DELETE_PRODUCT_QUANTITY_OFFER, deleteProductQuantityOffer),
];
