import {call, put} from 'redux-saga/effects';
import api from "../api";
import {toast} from "react-toastify";

import {
    CREATE_PRODUCT_RELATION_RESULT,
    CREATE_PRODUCT_RELATION_ERROR,
    CREATE_PRODUCT_RELATION_RETRY,

    FETCH_PRODUCT_RELATION_RESULT,
    FETCH_PRODUCT_RELATION_ERROR,
    FETCH_PRODUCT_RELATION_RETRY,

    UPDATE_PRODUCT_RELATION_RESULT,
    UPDATE_PRODUCT_RELATION_ERROR,
    UPDATE_PRODUCT_RELATION_RETRY,

    DELETE_PRODUCT_RELATION_RESULT,
    DELETE_PRODUCT_RELATION_ERROR,
    DELETE_PRODUCT_RELATION_RETRY
} from '../../actions/quiz/productrelations';


export function* createProductRelation(action) {
    try {
        const response = yield call(api.quiz.productrelations.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createProductRelation', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_PRODUCT_RELATION_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_PRODUCT_RELATION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_PRODUCT_RELATION_RETRY, retry: true, message: e.message});
    }
}

export function* fetchProductRelation(action) {
    try {
        const response = yield call(api.quiz.productrelations.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchProductRelation', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PRODUCT_RELATION_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_PRODUCT_RELATION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PRODUCT_RELATION_RETRY, retry: true, message: e.message});
    }
}


export function* updateProductRelation(action) {
    try {
        const response = yield call(api.quiz.productrelations.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateProductRelation', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_PRODUCT_RELATION_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_PRODUCT_RELATION_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_PRODUCT_RELATION_RETRY, retry: true, message: e.message});
    }
}


export function* deleteProductRelation(action) {
    try {
        const response = yield call(api.quiz.productrelations.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteProductRelation', action);

        if (response.status === 204) {
            yield put({type: DELETE_PRODUCT_RELATION_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_PRODUCT_RELATION_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_PRODUCT_RELATION_RETRY, retry: true, message: e.message});
    }
}
