import {
    CREATE_BOT_FIELD_FILE,
    CREATE_BOT_FIELD_FILE_RESULT,
    CREATE_BOT_FIELD_FILE_ERROR,
    CREATE_BOT_FIELD_FILE_RETRY,

    FETCH_BOT_FIELD_FILES,
    FETCH_BOT_FIELD_FILES_RESULT,
    FETCH_BOT_FIELD_FILES_ERROR,
    FETCH_BOT_FIELD_FILES_RETRY,

    FETCH_BOT_FIELD_FILE,
    FETCH_BOT_FIELD_FILE_RESULT,
    FETCH_BOT_FIELD_FILE_ERROR,
    FETCH_BOT_FIELD_FILE_RETRY,

    UPDATE_BOT_FIELD_FILE,
    UPDATE_BOT_FIELD_FILE_RESULT,
    UPDATE_BOT_FIELD_FILE_ERROR,
    UPDATE_BOT_FIELD_FILE_RETRY,

    DELETE_BOT_FIELD_FILE,
    DELETE_BOT_FIELD_FILE_RESULT,
    DELETE_BOT_FIELD_FILE_ERROR,
    DELETE_BOT_FIELD_FILE_RETRY,
} from '../../actions/quiz/botfieldfiles';


const initialState = {
    detail: {},
    list: [],
    isCreating: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_BOT_FIELD_FILE:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_BOT_FIELD_FILE_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_BOT_FIELD_FILE_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_BOT_FIELD_FILE_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_BOT_FIELD_FILES:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_BOT_FIELD_FILES_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_BOT_FIELD_FILES_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_BOT_FIELD_FILES_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_BOT_FIELD_FILE:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_BOT_FIELD_FILE_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_BOT_FIELD_FILE_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_BOT_FIELD_FILE_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_BOT_FIELD_FILE:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_BOT_FIELD_FILE_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_BOT_FIELD_FILE_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_BOT_FIELD_FILE_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_BOT_FIELD_FILE:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_BOT_FIELD_FILE_RESULT:
            return {...state, isDeleting: false, detail: action.data};
        case DELETE_BOT_FIELD_FILE_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_BOT_FIELD_FILE_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
