export const CREATE_PRODUCT_RELATION = 'CREATE_PRODUCT_RELATION';
export const CREATE_PRODUCT_RELATION_RESULT = 'CREATE_PRODUCT_RELATION_RESULT';
export const CREATE_PRODUCT_RELATION_ERROR = 'CREATE_PRODUCT_RELATION_ERROR';
export const CREATE_PRODUCT_RELATION_RETRY = 'CREATE_PRODUCT_RELATION_RETRY';


export const FETCH_PRODUCT_RELATION = 'FETCH_PRODUCT_RELATION';
export const FETCH_PRODUCT_RELATION_RESULT = 'FETCH_PRODUCT_RELATION_RESULT';
export const FETCH_PRODUCT_RELATION_ERROR = 'FETCH_PRODUCT_RELATION_ERROR';
export const FETCH_PRODUCT_RELATION_RETRY = 'FETCH_PRODUCT_RELATION_RETRY';


export const UPDATE_PRODUCT_RELATION = 'UPDATE_PRODUCT_RELATION';
export const UPDATE_PRODUCT_RELATION_RESULT = 'UPDATE_PRODUCT_RELATION_RESULT';
export const UPDATE_PRODUCT_RELATION_ERROR = 'UPDATE_PRODUCT_RELATION_ERROR';
export const UPDATE_PRODUCT_RELATION_RETRY = 'UPDATE_PRODUCT_RELATION_RETRY';


export const DELETE_PRODUCT_RELATION = 'DELETE_PRODUCT_RELATION';
export const DELETE_PRODUCT_RELATION_RESULT = 'DELETE_PRODUCT_RELATION_RESULT';
export const DELETE_PRODUCT_RELATION_ERROR = 'DELETE_PRODUCT_RELATION_ERROR';
export const DELETE_PRODUCT_RELATION_RETRY = 'DELETE_PRODUCT_RELATION_RETRY';


export const createProductRelation = (data) => ({
    type: CREATE_PRODUCT_RELATION,
    data,
});

export const fetchProductRelation = (data) => ({
    type: FETCH_PRODUCT_RELATION,
    data,
});

export const updateProductRelation = (data) => ({
    type: UPDATE_PRODUCT_RELATION,
    data,
});

export const deleteProductRelation = (data) => ({
    type: DELETE_PRODUCT_RELATION,
    data,
});

