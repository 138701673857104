import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import {fetchProduct, updateProduct, createProduct, deleteProduct} from "../../../actions/quiz/products";
import {createProductImage, deleteProductImage} from "../../../actions/quiz/productimages";
import {fetchBotForm, fetchBotForms} from "../../../actions/quiz/botforms";
import ExpressionBuilder from "../ExpressionBuilder";
import ProductItemConnectionEditCreateModal from "../ProductItemConnectionEditCreateModal";
import TitleEditListItem from "../../items/TitleEditListItem";
import ConfirmButton from "../../buttons/ConfirmButton";
import BotFieldFileItem from "../../items/BotFieldFileItem";
import ProductFeatureEditCreateModal from "../ProductFeatureEditCreateModal";
import ProductRelationEditCreateModal from "../ProductRelationEditCreateModal";
import QuillHelper from "../../others/QuillHelper";
import ProductLanguageEditCreateModal from "../ProductLanguageEditCreateModal";
import ProductQuantityOfferEditCreateModal from "../ProductQuantityOfferEditCreateModal/ProductQuantityOfferEditCreateModal";


class ProductEditCreateModal extends React.Component {

    static propTypes = {
        products: PropTypes.any,
        createProduct: PropTypes.func,
        fetchProduct: PropTypes.func,
        updateProduct: PropTypes.func,
        deleteProduct: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
        fetchBotForms: PropTypes.func,
        fetchBotForm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            product: props.product,
            message: undefined,
            field_child: undefined,
            showBuilder: false,
            deletingImage: false,
            creatingImage: false,
        };
    }

    componentDidMount() {
        if (this.props.product.id) {
            this.props.fetchProduct(this.props.product);
        }

        this.props.fetchBotForms();
        let quill = new QuillHelper('#product-description-editor', this.handleContentChange);
        this.setState({quill});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            products: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry},
            productitemconnections, productimages, productfeatures, productrelations, productlanguages,
            productquantityoffers,
        } = this.props;

        if (!isFetching && prevProps.products.isFetching && !error && !retry) {
            this.setState({product: detail});
            this.state.quill.setHtmlText(detail.description);
        }
        if (!isUpdating && prevProps.products.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.products.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.products.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.products.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.products.isUpdating && error && !retry) {
            this.setState({message});
        }

        if (!productimages.isCreating && prevProps.productimages.isCreating) {
            this.setState({creatingImage: false});
        }

        if (!productimages.isDeleting && prevProps.productimages.isDeleting) {
            this.setState({deletingImage: false});
        }

        if (!productimages.isCreating && prevProps.productimages.isCreating && !productimages.error && !productimages.retry) {
            this.props.fetchProduct(this.props.product);
        }
        if (!productimages.isDeleting && prevProps.productimages.isDeleting && !productimages.error && !productimages.retry) {
            this.props.fetchProduct(this.props.product);
        }

        if (
            (!productitemconnections.isUpdating && prevProps.productitemconnections.isUpdating && !productitemconnections.error && !productitemconnections.retry) ||
            (!productitemconnections.isCreating && prevProps.productitemconnections.isCreating && !productitemconnections.error && !productitemconnections.retry) ||
            (!productitemconnections.isDeleting && prevProps.productitemconnections.isDeleting && !productitemconnections.error && !productitemconnections.retry) ||

            (!productfeatures.isUpdating && prevProps.productfeatures.isUpdating && !productfeatures.error && !productfeatures.retry) ||
            (!productfeatures.isCreating && prevProps.productfeatures.isCreating && !productfeatures.error && !productfeatures.retry) ||
            (!productfeatures.isDeleting && prevProps.productfeatures.isDeleting && !productfeatures.error && !productfeatures.retry) ||

            (!productrelations.isUpdating && prevProps.productrelations.isUpdating && !productrelations.error && !productrelations.retry) ||
            (!productrelations.isCreating && prevProps.productrelations.isCreating && !productrelations.error && !productrelations.retry) ||
            (!productrelations.isDeleting && prevProps.productrelations.isDeleting && !productrelations.error && !productrelations.retry) ||

            (!productlanguages.isUpdating && prevProps.productlanguages.isUpdating && !productlanguages.error && !productlanguages.retry) ||
            (!productlanguages.isCreating && prevProps.productlanguages.isCreating && !productlanguages.error && !productlanguages.retry) ||
            (!productlanguages.isDeleting && prevProps.productlanguages.isDeleting && !productlanguages.error && !productlanguages.retry) ||

            (!productquantityoffers.isUpdating && prevProps.productquantityoffers.isUpdating && !productquantityoffers.error && !productquantityoffers.retry) ||
            (!productquantityoffers.isCreating && prevProps.productquantityoffers.isCreating && !productquantityoffers.error && !productquantityoffers.retry) ||
            (!productquantityoffers.isDeleting && prevProps.productquantityoffers.isDeleting && !productquantityoffers.error && !productquantityoffers.retry)
        ) {
            this.props.fetchProduct(this.props.product);
        }
    }

    handleContentChange = () => {
        let {product} = this.state;
        this.setState({
            product: {
                ...product,
                description: this.state.quill.getHtmlText()
            }
        });
    };

    handleProductImageCreate = (e) => {
        let {product} = this.state;
        if (product.id) {
            this.setState({creatingImage: true});
            let file = e.target.files[0];
            this.props.createProductImage({
                file,
                file_type: 'image',
                name: 'product',
                product: product.id,
            });
        }
    };

    handleProductImageDelete = (file) => {
        this.setState({deletingImage: true});
        this.props.deleteProductImage(file);
    };

    handleProductChange = (product) => {
        this.setState({product});
    };

    handleProductImageChange = (e) => {
        let image = e.target.files[0];
        let data = {
            image,
            id: this.state.product.id,
        };
        this.props.updateProduct(data);
    };

    handleSaveProduct = () => {
        let {product} = this.state;
        if (!product.sale_price) product.sale_price = null;
        // product.components = undefined;
        if (this.state.product.id) {
            this.props.updateProduct(this.state.product);
        } else {
            this.props.createProduct({...this.state.product});
        }
    };

    handleDeleteProduct = () => {
        if (this.state.product.id) {
            this.props.deleteProduct(this.state.product);
        }
    };

    handleCloseProductChildModal = () => {
        this.setState({field_child: undefined});
    };

    render() {
        let {show, botforms, botfields, handleConfirm, handleClose} = this.props;
        let {product, message, deletingImage, creatingImage} = this.state;

        let identifiers = botfields.list.map(o => [o.field_id, ...o.field_children.map(i => i.field_id)]);
        identifiers = [].concat(...identifiers) || [];

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{product.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Product Id</Form.Label>
                        <Form.Control type="text" disabled={!!product.id}
                                      placeholder="Enter Title"
                                      value={product.product_id || ''}
                                      isInvalid={!!message && !!message.product_id}
                                      onChange={(e) => this.handleProductChange({
                                          ...product,
                                          product_id: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.product_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Title"
                                      value={product.title || ''}
                                      isInvalid={!!message && !!message.title}
                                      onChange={(e) => this.handleProductChange({
                                          ...product,
                                          title: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.title}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Short Description</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      placeholder="Enter Short Description"
                                      value={product.short_description || ''}
                                      isInvalid={!!message && !!message.short_description}
                                      onChange={(e) => this.handleProductChange({
                                          ...product,
                                          short_description: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.short_description}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <div id="product-description-editor" className="content"/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.description}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} controlId="brand_name">
                            <Form.Label>Brand Name</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Brand Name"
                                          value={product.brand_name || ''}
                                          isInvalid={!!message && !!message.brand_name}
                                          onChange={(e) => this.handleProductChange({
                                              ...product,
                                              brand_name: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.brand_name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="brand_id">
                            <Form.Label>Brand Id</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Brand Id"
                                          value={product.brand_id || ''}
                                          isInvalid={!!message && !!message.brand_id}
                                          onChange={(e) => this.handleProductChange({
                                              ...product,
                                              brand_id: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.brand_id}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="generic_name">
                            <Form.Label>Generic Name</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Generic Name"
                                          value={product.generic_name || ''}
                                          isInvalid={!!message && !!message.generic_name}
                                          onChange={(e) => this.handleProductChange({
                                              ...product,
                                              generic_name: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.generic_name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="generic_id">
                            <Form.Label>Generic Id</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Generic Id"
                                          value={product.generic_id || ''}
                                          isInvalid={!!message && !!message.generic_id}
                                          onChange={(e) => this.handleProductChange({
                                              ...product,
                                              generic_id: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.generic_id}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="company_name">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Company Name"
                                          value={product.company_name || ''}
                                          isInvalid={!!message && !!message.company_name}
                                          onChange={(e) => this.handleProductChange({
                                              ...product,
                                              company_name: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.company_name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="pack_size">
                            <Form.Label>Pack Size</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Pack Size"
                                          value={product.pack_size || ''}
                                          isInvalid={!!message && !!message.pack_size}
                                          onChange={(e) => this.handleProductChange({
                                              ...product,
                                              pack_size: e.target.value,
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.pack_size}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="price">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="number"
                                          placeholder="Enter Price"
                                          value={product.price || ''}
                                          isInvalid={!!message && !!message.price}
                                          onChange={(e) => this.handleProductChange({
                                              ...product,
                                              price: e.target.value,
                                          })}/>
                            <Form.Text className="text-muted">
                                Enter price in paise.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.price}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="price">
                            <Form.Label>Tax Percentage</Form.Label>
                            <Form.Control type="number"
                                          placeholder="Tax"
                                          value={product.tax_percentage || ''}
                                          isInvalid={!!message && !!message.tax_percentage}
                                          onChange={(e) => this.handleProductChange({
                                              ...product,
                                              tax_percentage: e.target.value,
                                          })}/>
                            <Form.Text className="text-muted">
                                Enter tax in percentage 1-100%.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.tax_percentage}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Sale Price</Form.Label>
                        <Form.Control type="number"
                                      placeholder="Enter sale price"
                                      value={product.sale_price || ''}
                                      isInvalid={!!message && !!message.sale_price}
                                      onChange={(e) => this.handleProductChange({
                                          ...product,
                                          sale_price: e.target.value,
                                      })}/>
                        <Form.Text className="text-muted">
                            Enter sale price in paise.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.sale_price}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Consultation required"
                                    isInvalid={!!message && !!message.consultation_required}
                                    checked={product.consultation_required || false}
                                    onChange={(e) => this.handleProductChange({
                                        ...product,
                                        consultation_required: !product.consultation_required,
                                    })}/>
                    </Form.Group>
                    <Form.Group controlId="productIsVisible">
                        <Form.Check type="checkbox" label="Is Visible"
                                    isInvalid={!!message && !!message.is_visible}
                                    checked={product.is_visible || false}
                                    onChange={(e) => this.handleProductChange({
                                        ...product,
                                        is_visible: !product.is_visible,
                                    })}/>
                    </Form.Group>
                    <Form.Group controlId="fieldText">
                        <Form.Label>Select Expression</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      disabled={true}
                                      value={product.select_expression || ""}/>
                        <Button onClick={() => this.setState({showBuilder: true})}>Change Value</Button>
                        <ExpressionBuilder identifiers={identifiers}
                                           expression={product.select_expression || ""}
                                           handleExpressionChange={(select_expression => this.handleProductChange({
                                               ...product,
                                               select_expression
                                           }))}
                                           show={this.state.showBuilder}
                                           handleClose={() => this.setState({showBuilder: false})}/>
                    </Form.Group>
                    {
                        !!product.id &&
                        <Form.Group controlId="fieldText">
                            <Form.Label>Image</Form.Label>
                            <img src={product.image} style={{maxWidth: 150}} alt=""/>
                            <Form.Control type="file" onChange={this.handleProductImageChange}/>
                        </Form.Group>
                    }
                    {
                        !!product.id &&
                        <Form.Group controlId="fieldText">
                            <Form.Label>Files</Form.Label>
                            <div className="mb-2">
                                {
                                    !!product.productimage_set &&
                                    product.productimage_set.map(
                                        product => <BotFieldFileItem key={product.id} isDeleting={deletingImage}
                                                                     image={product.image}
                                                                     onDeleteClick={() => this.handleProductImageDelete(product)}/>
                                    )
                                }
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <Form.Control type="file" onChange={this.handleProductImageCreate}/>
                                </div>
                                <div className="col-6">
                                    {creatingImage ? <Spinner size="sm" animation="border"/> : ''}
                                </div>
                            </div>
                        </Form.Group>
                    }

                    {
                        !!product.id &&
                        <Form.Group controlId="languageText">
                            <Form.Label>Language</Form.Label>
                            <Button className="float-right" variant={'dark'}
                                    size="sm" onClick={() => this.setState({
                                productLanguage: {
                                    product: product.id,
                                }
                            })}>Add Language</Button>
                            <div>
                                {
                                    !!product.languages &&
                                    product.languages.map(
                                        item => <TitleEditListItem key={item.id}
                                                                   title={item.title}
                                                                   onEditClick={() => this.setState({productLanguage: item})}/>
                                    )
                                }
                            </div>
                        </Form.Group>
                    }

                    {
                        !!product.id &&
                        <Form.Group controlId="fieldText">
                            <Form.Label>Items</Form.Label>
                            <Button className="float-right"
                                    size="sm" onClick={() => this.setState({
                                productitemconnection: {
                                    quantity: 1,
                                    product: product.id,
                                    item: null
                                }
                            })}>Add Item</Button>
                            <div>
                                {
                                    !!product.item_connections &&
                                    product.item_connections.map(
                                        item => <TitleEditListItem key={item.id}
                                                                   title={`${item.item_data.product_id} - ${item.item_data.title} - ${item.item_data.price} - ${item.quantity}`}
                                                                   onEditClick={() => this.setState({productitemconnection: item})}/>
                                    )
                                }
                            </div>
                        </Form.Group>
                    }

                    {
                        !!product.id &&
                        <Form.Group controlId="featureText">
                            <Form.Label>Features</Form.Label>
                            <Button className="float-right"
                                    size="sm" onClick={() => this.setState({
                                productFeature: {
                                    product: product.id,
                                    title: '',
                                    description: '',
                                }
                            })}>Add Feature</Button>
                            <div>
                                {
                                    !!product.features &&
                                    product.features.map(
                                        item => <TitleEditListItem key={item.id}
                                                                   title={item.title}
                                                                   onEditClick={() => this.setState({productFeature: item})}/>
                                    )
                                }
                            </div>
                        </Form.Group>
                    }

                    {
                        !!product.id &&
                        <Form.Group controlId="featureText">
                            <Form.Label>Relation</Form.Label>
                            <Button className="float-right" size="sm"
                                    onClick={() => this.setState({
                                        productRelation: {
                                            display_product: product.id,
                                            related_product: null,
                                            title: '',
                                            description: '',
                                        }
                                    })}>Add Relation</Button>
                            <div>
                                {
                                    !!product.display_product_relations &&
                                    product.display_product_relations.map(
                                        item => <TitleEditListItem key={item.id}
                                                                   title={item.title}
                                                                   onEditClick={() => this.setState({productRelation: item})}/>
                                    )
                                }
                            </div>
                        </Form.Group>
                    }
                    {
                        !!product.id &&
                        <Form.Group controlId="featureOffer">
                            <Form.Label>Quantity Offer</Form.Label>
                            <Button className="float-right" size="sm"
                                    onClick={() => this.setState({
                                        productOffer: {
                                            product: product.id,
                                        }
                                    })}>Add Offer</Button>
                            <div>
                                {
                                    !!product.product_quantity_offers &&
                                    product.product_quantity_offers.map(
                                        offer => <TitleEditListItem key={offer.id}
                                                                    title={offer.text}
                                                                    onEditClick={() => this.setState({productOffer: offer})}/>
                                    )
                                }
                            </div>
                        </Form.Group>
                    }
                    {!!this.state.productLanguage &&
                    <ProductLanguageEditCreateModal show={!!this.state.productLanguage}
                                                    productLanguage={this.state.productLanguage}
                                                    handleClose={() => this.setState({productLanguage: undefined})}/>
                    }

                    {
                        !!this.state.productitemconnection &&
                        <ProductItemConnectionEditCreateModal show={!!this.state.productitemconnection}
                                                              productitemconnection={this.state.productitemconnection}
                                                              handleClose={() => this.setState({productitemconnection: undefined})}/>
                    }

                    {!!this.state.productFeature &&
                    <ProductFeatureEditCreateModal show={!!this.state.productFeature}
                                                   productFeature={this.state.productFeature}
                                                   handleClose={() => this.setState({productFeature: undefined})}/>
                    }

                    {!!this.state.productRelation &&
                    <ProductRelationEditCreateModal show={!!this.state.productRelation}
                                                    productRelation={this.state.productRelation}
                                                    handleClose={() => this.setState({productRelation: undefined})}/>
                    }

                    {!!this.state.productOffer &&
                    <ProductQuantityOfferEditCreateModal show={!!this.state.productOffer}
                                                         productOffer={this.state.productOffer}
                                                         handleClose={() => this.setState({productOffer: undefined})}/>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveProduct}>Save</Button>
                    {
                        this.state.product.id &&
                        <ConfirmButton onClick={this.handleDeleteProduct}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.quiz.products,
    productimages: state.quiz.productimages,
    productitemconnections: state.quiz.productitemconnections,
    productfeatures: state.quiz.productfeatures,
    productlanguages: state.quiz.productlanguages,
    productrelations: state.quiz.productrelations,
    productquantityoffers: state.cms.productquantityoffers,
    botforms: state.quiz.botforms,
    botfields: state.quiz.botfields,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createProduct,
    fetchProduct,
    updateProduct,
    deleteProduct,
    fetchBotForms,
    fetchBotForm,
    createProductImage,
    deleteProductImage,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ProductEditCreateModal);
