import {call, put} from 'redux-saga/effects';
import api from "../api";
import {toast} from "react-toastify";

import {
    CREATE_PRODUCT_IMAGE_RESULT,
    CREATE_PRODUCT_IMAGE_ERROR,
    CREATE_PRODUCT_IMAGE_RETRY,

    DELETE_PRODUCT_IMAGE_RESULT,
    DELETE_PRODUCT_IMAGE_ERROR,
    DELETE_PRODUCT_IMAGE_RETRY,
} from '../../actions/quiz/productimages';


export function* createProductImage(action) {
    try {
        const response = yield call(api.quiz.productimages.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createProductImage', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_PRODUCT_IMAGE_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_PRODUCT_IMAGE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_PRODUCT_IMAGE_RETRY, retry: true, message: e.message});
    }
}

export function* deleteProductImage(action) {
    try {
        const response = yield call(api.quiz.productimages.remove, action.data);
        // const result = yield response.json();
        console.warn('SAGA:deleteProductImage', action);

        if (response.status === 204) {
            yield put({type: DELETE_PRODUCT_IMAGE_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_PRODUCT_IMAGE_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_PRODUCT_IMAGE_RETRY, retry: true, message: e.message});
    }
}
