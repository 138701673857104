import {call, put} from 'redux-saga/effects';
import {
    UPDATE_USER_PROFILE_RESULT, UPDATE_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_RETRY,
    FETCH_USER_PROFILE_RESULT, FETCH_USER_PROFILE_ERROR, FETCH_USER_PROFILE_RETRY,
    USER_LOGIN_RESULT, USER_LOGIN_ERROR, USER_LOGIN_RETRY,
} from '../../actions/accounts/profile';
import api from "../api";
import {isUserEditor, isUserQuizWriter, isUserWriter} from "../../permisions";
import {ACCESS, ACCESS_EXPIRE, ACCESS_EXPIRE_TIME, REFRESH, USER} from "../makeRequest";
import moment from "moment";
import {setStorageItem} from "../../utils";


export function* updateUserProfile(action) {
    try {
        const {data} = action;
        const response = yield call(api.accounts.patchUser, data);
        const result = yield response.json();
        console.warn('SAGA:updateUserProfile', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_USER_PROFILE_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_USER_PROFILE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_USER_PROFILE_RETRY, retry: true, message: e.message});
    }
}


export function* fetchUserProfile(action) {
    try {
        const response = yield call(api.accounts.getUser, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchUserProfile', action, result);

        if (response.status === 200) {
            yield localStorage.setItem(USER, JSON.stringify(result));
            yield put({type: FETCH_USER_PROFILE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_USER_PROFILE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_USER_PROFILE_RETRY, retry: true, message: e.message});
    }
}


export function* userLogin(action) {
    try {
        // const {phoneNumber, otp} = action;
        const response = yield call(api.accounts.postUserLogin, action.data);
        const result = yield response.json();
        console.warn('SAGA:userLogin', action, result);

        if (response.status === 200) {
            if (isUserWriter(result.user) || isUserEditor(result.user) || isUserQuizWriter(result.user)) {
                setStorageItem(ACCESS, result.token.access);
                setStorageItem(USER, JSON.stringify(result.user));
                setStorageItem(REFRESH, result.token.refresh);
                setStorageItem(ACCESS_EXPIRE, moment().add(ACCESS_EXPIRE_TIME.value, ACCESS_EXPIRE_TIME.unit));
                yield put({type: USER_LOGIN_RESULT, data: result.user});
            }
            else {
                yield put({type: USER_LOGIN_ERROR, error: true, message: result});
            }
        }
        else {
            yield put({type: USER_LOGIN_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: USER_LOGIN_RETRY, retry: true, message: e.message});
    }
}

