import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";


export default class ConfirmButton extends PureComponent {

    static propTypes = {
        style: PropTypes.object,
        confirmStyle: PropTypes.object,
        variant: PropTypes.string,
        confirmVariant: PropTypes.string,
        confirmDuration: PropTypes.number,
        onClick: PropTypes.func,
        text: PropTypes.string,
        confirmText: PropTypes.string,
        confirmClassName: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        style: {},
        text: 'Delete',
        variant: 'danger',
        className: '',
        confirmStyle: {},
        confirmDuration: 2000,
        confirmVariant: 'danger',
        confirmText: 'Remove Item',
        confirmClassName: '',
    };

    state = {
        confirm: false,
    };

    handleConfirmTrigger = () => {
        this.setState({confirm: true}, () => {
            this.timeout = setTimeout(() => {
                this.setState({confirm: false});
            }, this.props.confirmDuration);
        });
    };

    onClick = () => {
        if (this.state.confirm) {
            this.setState({confirm: false});
            this.props.onClick();
        }
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const {text, variant, confirmText, confirmVariant, style, confirmStyle, confirmClassName, className} = this.props;
        const {confirm} = this.state;

        return (
            <React.Fragment>
                {!confirm ?
                    <Button className={className} variant={variant} style={style}
                            onClick={this.handleConfirmTrigger}>{text}</Button>
                    :
                    <Button className={confirmClassName} variant={confirmVariant} style={confirmStyle}
                            onClick={this.onClick}>{confirmText}</Button>
                }
            </React.Fragment>
        )
    }
}
