export const CREATE_BOT_FIELD_CONNECTION = 'CREATE_BOT_FIELD_CONNECTION';
export const CREATE_BOT_FIELD_CONNECTION_RESULT = 'CREATE_BOT_FIELD_CONNECTION_RESULT';
export const CREATE_BOT_FIELD_CONNECTION_ERROR = 'CREATE_BOT_FIELD_CONNECTION_ERROR';
export const CREATE_BOT_FIELD_CONNECTION_RETRY = 'CREATE_BOT_FIELD_CONNECTION_RETRY';


export const FETCH_BOT_FIELD_CONNECTIONS = 'FETCH_BOT_FIELD_CONNECTIONS';
export const FETCH_BOT_FIELD_CONNECTIONS_RESULT = 'FETCH_BOT_FIELD_CONNECTIONS_RESULT';
export const FETCH_BOT_FIELD_CONNECTIONS_ERROR = 'FETCH_BOT_FIELD_CONNECTIONS_ERROR';
export const FETCH_BOT_FIELD_CONNECTIONS_RETRY = 'FETCH_BOT_FIELD_CONNECTIONS_RETRY';


export const FETCH_BOT_FIELD_CONNECTION = 'FETCH_BOT_FIELD_CONNECTION';
export const FETCH_BOT_FIELD_CONNECTION_RESULT = 'FETCH_BOT_FIELD_CONNECTION_RESULT';
export const FETCH_BOT_FIELD_CONNECTION_ERROR = 'FETCH_BOT_FIELD_CONNECTION_ERROR';
export const FETCH_BOT_FIELD_CONNECTION_RETRY = 'FETCH_BOT_FIELD_CONNECTION_RETRY';


export const UPDATE_BOT_FIELD_CONNECTION = 'UPDATE_BOT_FIELD_CONNECTION';
export const UPDATE_BOT_FIELD_CONNECTION_RESULT = 'UPDATE_BOT_FIELD_CONNECTION_RESULT';
export const UPDATE_BOT_FIELD_CONNECTION_ERROR = 'UPDATE_BOT_FIELD_CONNECTION_ERROR';
export const UPDATE_BOT_FIELD_CONNECTION_RETRY = 'UPDATE_BOT_FIELD_CONNECTION_RETRY';


export const DELETE_BOT_FIELD_CONNECTION = 'DELETE_BOT_FIELD_CONNECTION';
export const DELETE_BOT_FIELD_CONNECTION_RESULT = 'DELETE_BOT_FIELD_CONNECTION_RESULT';
export const DELETE_BOT_FIELD_CONNECTION_ERROR = 'DELETE_BOT_FIELD_CONNECTION_ERROR';
export const DELETE_BOT_FIELD_CONNECTION_RETRY = 'DELETE_BOT_FIELD_CONNECTION_RETRY';


export const createBotFieldConnection = (data) => ({
    type: CREATE_BOT_FIELD_CONNECTION,
    data,
});

export const fetchBotFieldConnections = (data) => ({
    type: FETCH_BOT_FIELD_CONNECTIONS,
    data,
});


export const fetchBotFieldConnection = (data) => ({
    type: FETCH_BOT_FIELD_CONNECTION,
    data,
});

export const updateBotFieldConnection = (data) => ({
    type: UPDATE_BOT_FIELD_CONNECTION,
    data,
});

export const deleteBotFieldConnection = (data) => ({
    type: DELETE_BOT_FIELD_CONNECTION,
    data,
});

