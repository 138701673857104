import {call, put} from 'redux-saga/effects';
import {
    CREATE_RED_FLAG_RESULT,
    CREATE_RED_FLAG_ERROR,
    CREATE_RED_FLAG_RETRY,

    FETCH_RED_FLAGS_RESULT,
    FETCH_RED_FLAGS_ERROR,
    FETCH_RED_FLAGS_RETRY,

    FETCH_RED_FLAG_RESULT,
    FETCH_RED_FLAG_ERROR,
    FETCH_RED_FLAG_RETRY,

    UPDATE_RED_FLAG_RESULT,
    UPDATE_RED_FLAG_ERROR,
    UPDATE_RED_FLAG_RETRY,

    DELETE_RED_FLAG_RESULT,
    DELETE_RED_FLAG_ERROR,
    DELETE_RED_FLAG_RETRY,
} from '../../actions/report/redflags';
import api from "../api";
import {toast} from "react-toastify";


export function* createRedFlag(action) {
    try {
        const response = yield call(api.report.redflags.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createRedFlag', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_RED_FLAG_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_RED_FLAG_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_RED_FLAG_RETRY, retry: true, message: e.message});
    }
}


export function* fetchRedFlags(action) {
    try {
        const response = yield call(api.report.redflags.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchRedFlags', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_RED_FLAGS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_RED_FLAGS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_RED_FLAGS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchRedFlag(action) {
    try {
        const response = yield call(api.report.redflags.get, action.data);
        const result = yield response.json();
        // console.warn('SAGA:fetchRedFlag', action, result);
        if (response.status === 200) {
            yield put({type: FETCH_RED_FLAG_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_RED_FLAG_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_RED_FLAG_RETRY, retry: true, message: e.message});
    }
}


export function* updateRedFlag(action) {
    try {
        const response = yield call(api.report.redflags.update, action.data);
        const result = yield response.json();
        // console.warn('SAGA:updateRedFlag', action, result);
        if (response.status === 200) {
            yield put({type: UPDATE_RED_FLAG_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_RED_FLAG_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_RED_FLAG_RETRY, retry: true, message: e.message});
    }
}


export function* deleteRedFlag(action) {
    try {
        const response = yield call(api.report.redflags.remove, action.data);
        // const result = yield response.json();
        // console.warn('SAGA:deleteRedFlag', action);

        if (response.status === 204) {
            yield put({type: DELETE_RED_FLAG_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_RED_FLAG_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_RED_FLAG_RETRY, retry: true, message: e.message});
    }
}
