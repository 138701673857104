import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import './styles.scss';
import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import moment from "moment";
import {
    createMessageType, deleteMessageType,
    fetchMessageTypes,
    updateMessageType
} from "../../actions/events/messagetypes";
import {fetchProducts} from "../../actions/quiz/products";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {messageEventTypes, messageTypes, messageUnitTypes} from "../../constants";
import ConfirmButton from "../../components/buttons/ConfirmButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {toast} from "react-toastify";
import {copyText} from "../../utils";
import {InputGroup} from "react-bootstrap";


class MessageTypes extends React.Component {

    static propTypes = {
        messagetypes: PropTypes.object,
        fetchMessageTypes: PropTypes.func,
        fetchProducts: PropTypes.func,
    };

    static defaultProps = {
        messagetypes: {},
    };

    state = {
        message: undefined,
        messageTypesList: [],
        messageTypesIndex: undefined,
    };

    componentDidMount() {
        this.props.fetchMessageTypes();
        this.props.fetchProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            messagetypes: {detail, list, isFetchingList, isFetching, isCreating, isUpdating, isDeleting, message, error, retry},
        } = this.props;

        const {messageTypesIndex} = this.state;

        if (!isFetchingList && prevProps.messagetypes.isFetchingList && !error && !retry) {
            this.setState({messageTypesList: list});
        }

        if (!isCreating && prevProps.messagetypes.isCreating && !error && !retry) {
            this.props.fetchMessageTypes();
            this.setState({message: undefined});
            toast("Successfully Created");
        }
        if (!isDeleting && prevProps.messagetypes.isDeleting && !error && !retry) {
            this.props.fetchMessageTypes();
            toast("Successfully Deleted");
        }
        if (!isUpdating && prevProps.messagetypes.isUpdating && !error && !retry) {
            this.props.fetchMessageTypes();
            this.setState({message: undefined});
            toast("Successfully Updated");
        }
        if ((!isCreating && prevProps.messagetypes.isCreating && error && !retry) ||
            (!isUpdating && prevProps.messagetypes.isUpdating && error && !retry)) {
            this.setState({
                message: {
                    [messageTypesIndex]: message,
                }
            });
        }
    }

    handleCreateMessageType = () => {
        let {messageTypesList} = this.state;
        messageTypesList.unshift({
            event_type: '',
            message_type: 'sms',
            message_template: '',
            count: '',
            unit: '',
            active: false,
        });

        this.setState({messageTypesList, messageTypesIndex: 0});

    };

    handleSaveMessageType = (index) => {
        let {messageTypesList} = this.state;
        this.setState({messageTypesIndex: index});

        if (messageTypesList[index].id) {
            this.props.updateMessageType(messageTypesList[index]);
        } else {
            this.props.createMessageType(messageTypesList[index]);
        }
    };

    handleDeleteMessageType = (index) => {
        let {messageTypesList} = this.state;
        if (messageTypesList[index].id) {
            this.props.deleteMessageType(messageTypesList[index]);
        }
    };

    renderMessageTemplateHint = (messagetype) => {
        let messageTemplateHint = "Use '{}' to define variables. Allowed variables ";
        if (messagetype.event_type === 'purchase') {
            return messageTemplateHint += "{cid|name|total}";
        } else if (messagetype.event_type === 'dispatch') {
            return messageTemplateHint += "{cid|name|total|etd}";
        } else if (messagetype.event_type === 'receive') {
            return messageTemplateHint += "{cid|name|total|delivered_date}";
        }
        return messageTemplateHint;
    }

    handelChange = (messagetype, index) => {
        let {messageTypesList} = this.state;
        messageTypesList[index] = messagetype;

        this.setState({messageTypesList})
    }

    handelCopy = (text) => {
        text = text.replace(/{[^}]+\}/g, "%s");
        copyText(text);
    }

    render() {
        let {messagetypes, products} = this.props;
        let {message, messageTypesList} = this.state;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Products'}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pt-2 text-right">
                            <Button size={'sm'} onClick={this.handleCreateMessageType}>Create Message Type</Button>
                        </div>
                        <div className="col-12 pt-3">
                            <Table className="message-type-table" responsive striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Event Type</th>
                                    <th>Message Type</th>
                                    <th>Product</th>
                                    <th>Message Template</th>
                                    <th>Message Template hindi</th>
                                    <th>Count</th>
                                    <th>Unit</th>
                                    <th>Active</th>
                                    <th>Created At</th>
                                    <th>Updated By</th>
                                    <th>Updated At</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    messageTypesList.map(
                                        (messagetype, index) => (
                                            <tr key={index + 1}
                                                style={{backgroundColor: `${!messagetype.id ? 'rgba(53, 239, 6, 0.16)' : ''}`}}>
                                                <td>{index + 1}</td>
                                                <td className="minWidth100">
                                                    <Form.Group>
                                                        <Select options={messageEventTypes}
                                                                isDisabled={!!messagetype.id}
                                                                value={messageEventTypes.filter(({value}) => value === messagetype.event_type)}
                                                                onChange={({value}) => {
                                                                    this.handelChange({
                                                                        ...messagetype,
                                                                        event_type: value
                                                                    }, index)
                                                                }}/>
                                                        <Form.Control type="hidden"
                                                                      isInvalid={!!message && !!message[index] && !!message[index].event_type}/>
                                                        <Form.Control.Feedback type="invalid">
                                                            {!!message && !!message[index] && message[index].event_type}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                                <td className="minWidth100">
                                                    <Form.Group>
                                                        <Select options={messageTypes}
                                                                value={messageTypes.filter(({value}) => value === messagetype.message_type)}
                                                                onChange={({value}) => {
                                                                    this.handelChange({
                                                                        ...messagetype,
                                                                        message_type: value
                                                                    }, index)
                                                                }}/>
                                                        <Form.Control type="hidden"
                                                                      isInvalid={!!message && !!message[index] && !!message[index].message_type}/>
                                                        <Form.Control.Feedback type="invalid">
                                                            {!!message && !!message[index] && message[index].message_type}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                                <td className="minWidth200">
                                                    <Form.Group>
                                                        <Select options={products.list.map(p => ({
                                                            label: `${p.product_id} - ${p.title}`,
                                                            value: p.id
                                                        }))}
                                                                isMulti={true}
                                                                value={products.list.map(p => ({
                                                                    label: p.product_id,
                                                                    value: p.id
                                                                })).filter(p => !!messagetype.products ? (messagetype.products.indexOf(p.value) >= 0) : false)}
                                                                onChange={(obj) => {
                                                                    if (obj) {
                                                                        this.handelChange({
                                                                            ...messagetype,
                                                                            products: obj.map(p => p.value)
                                                                        }, index)
                                                                    } else {
                                                                        this.handelChange({
                                                                            ...messagetype,
                                                                            products: []
                                                                        }, index)
                                                                    }
                                                                }}
                                                        />
                                                        <Form.Control type="hidden"
                                                                      isInvalid={!!message && !!message[index] && !!message[index].product}/>
                                                        <Form.Control.Feedback type="invalid">
                                                            {!!message && !!message[index] && message[index].product}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                                <td className="minWidth250">
                                                    <InputGroup>
                                                        <Form.Control as="textarea" rows="3"
                                                                      placeholder="Enter Message Template"
                                                                      value={messagetype.message_template || ''}
                                                                      isInvalid={!!message && !!message[index] && !!message[index].message_template}
                                                                      onChange={(e) => this.handelChange({
                                                                          ...messagetype,
                                                                          message_template: e.target.value,
                                                                      }, index)}/>
                                                        <InputGroup.Append>
                                                            <Button variant="outline-success"
                                                                    className="far fa-copy p-1" size="sm"
                                                                    onClick={() => this.handelCopy(messagetype.message_template)}/>
                                                        </InputGroup.Append>
                                                        <Form.Text className="text-muted">
                                                            {this.renderMessageTemplateHint(messagetype)}
                                                        </Form.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {!!message && !!message[index] && message[index].message_template}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </td>
                                                <td className="minWidth250">
                                                    <InputGroup>
                                                        <Form.Control as="textarea" rows="3"
                                                                      placeholder="Enter Message Template"
                                                                      value={messagetype.message_template_hi || ''}
                                                                      isInvalid={!!message && !!message[index] && !!message[index].message_template_hi}
                                                                      onChange={(e) => this.handelChange({
                                                                          ...messagetype,
                                                                          message_template_hi: e.target.value,
                                                                      }, index)}/>
                                                        <InputGroup.Append>
                                                            <Button variant="outline-success"
                                                                    className="far fa-copy p-1" size="sm"
                                                                    onClick={() => this.handelCopy(messagetype.message_template_hi)}/>
                                                        </InputGroup.Append>
                                                        <Form.Text className="text-muted">
                                                            {this.renderMessageTemplateHint(messagetype)}
                                                        </Form.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {!!message && !!message[index] && message[index].message_template_hi}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </td>
                                                <td className="minWidth100">
                                                    <Form.Group controlId="count">
                                                        <Form.Control type="number"
                                                                      disabled={!!messagetype.id}
                                                                      placeholder="Enter Count"
                                                                      value={messagetype.count || ''}
                                                                      isInvalid={!!message && !!message[index] && !!message[index].count}
                                                                      onChange={(e) => this.handelChange({
                                                                          ...messagetype,
                                                                          count: e.target.value,
                                                                      }, index)}/>
                                                        <Form.Control.Feedback type="invalid">
                                                            {!!message && !!message[index] && message[index].count}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                                <td className="minWidth100">
                                                    <Form.Group controlId="title">
                                                        <Select options={messageUnitTypes}
                                                                isDisabled={!!messagetype.id}
                                                                value={messageUnitTypes.filter(({value}) => value === messagetype.unit)}
                                                                onChange={({value}) => this.handelChange({
                                                                    ...messagetype,
                                                                    unit: value
                                                                }, index)}
                                                        />
                                                        <Form.Control type="hidden"
                                                                      isInvalid={!!message && !!message[index] && !!message[index].unit}/>
                                                        <Form.Control.Feedback type="invalid">
                                                            {!!message && !!message[index] && message[index].unit}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group controlId="messagetypeActive">
                                                        <Form.Check type="checkbox"
                                                                    isInvalid={!!message && !!message[index] && !!message[index].active}
                                                                    checked={messagetype.active || false}
                                                                    onChange={(e) => this.handelChange({
                                                                        ...messagetype,
                                                                        active: !messagetype.active,
                                                                    }, index)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {!!message && !!message[index] && message[index].active}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </td>
                                                <td className="minWidth100">
                                                    {!!messagetype.created_at && moment(messagetype.created_at).format('DD, MMM, YY')}
                                                </td>
                                                <td className="minWidth100">
                                                    {messagetype.updated_by}
                                                </td>
                                                <td className="minWidth100">
                                                    {!!messagetype.updated_at && moment(messagetype.updated_at).format('DD, MMM, YY')}
                                                </td>
                                                <td>
                                                    <ButtonGroup className="w-100" vertical>
                                                        <Button variant="success"
                                                                onClick={() => this.handleSaveMessageType(index)}>
                                                            {messagetype.id ? 'Update' : 'Save'}
                                                        </Button>
                                                        {
                                                            messagetype.id &&
                                                            <ConfirmButton
                                                                onClick={() => this.handleDeleteMessageType(index)}/>
                                                        }
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    messagetypes: state.events.messagetypes,
    products: state.quiz.products,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchMessageTypes,
    fetchProducts,
    createMessageType,
    updateMessageType,
    deleteMessageType,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(MessageTypes);
