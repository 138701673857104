import store from "./store";

export const checkRole = (role, user = store.getState().accounts.profile.detail) => {
    return ((!!user && !!user.permissions && user.permissions.roles) || []).includes(role);
};

export const isUserWriter = (user = store.getState().accounts.profile.detail) => {
    return checkRole(RoleEnum.WRITER, user);
};

export const isUserEditor = (user = store.getState().accounts.profile.detail) => {
    return checkRole(RoleEnum.EDITOR, user);
};

export const isUserQuizWriter = (user = store.getState().accounts.profile.detail) => {
    return checkRole(RoleEnum.QUIZ_WRITER, user);
};

export const checkPermissions = (permissions = []) => {
    const user = store.getState().accounts.profile.detail;
    if (permissions.length === 0) {
        return true;
    }
    let isAllowed = true;
    permissions.map(p => {
        isAllowed = isAllowed && (!!user && !!user.permissions && (user.permissions.permissions || []).includes(p));
    });
    return isAllowed;
};

export const checkRoles = (roles = []) => {
    const user = store.getState().accounts.profile.detail;
    if (roles.length === 0) {
        return true;
    }
    let isAllowed = true;
    roles.map(r => {
        isAllowed = isAllowed && (!!user && !!user.permissions && (user.permissions.roles || []).includes(r));
    });
    return isAllowed;
};


export class PermissionEnum {
    static BLOG_POST_CREATE = "blog_post_create";
    static BLOG_POST_READ = "blog_post_read";
    static BLOG_POST_UPDATE = "blog_post_update";
    static BLOG_POST_DELETE = "blog_post_delete";

    static BLOG_QA_CREATE = "blog_qa_create";
    static BLOG_QA_READ = "blog_qa_read";
    static BLOG_QA_UPDATE = "blog_qa_update";
    static BLOG_QA_DELETE = "blog_qa_delete";

    static BLOG_TAG_CREATE = "blog_tag_create";
    static BLOG_TAG_READ = "blog_tag_read";
    static BLOG_TAG_UPDATE = "blog_tag_update";
    static BLOG_TAG_DELETE = "blog_tag_delete";

    static BLOG_WRITER_CREATE = "blog_writer_create";
    static BLOG_WRITER_READ = "blog_writer_read";
    static BLOG_WRITER_UPDATE = "blog_writer_update";
    static BLOG_WRITER_DELETE = "blog_writer_delete";

    static BLOG_EDITOR_CREATE = "blog_editor_create";
    static BLOG_EDITOR_READ = "blog_editor_read";
    static BLOG_EDITOR_UPDATE = "blog_editor_update";
    static BLOG_EDITOR_DELETE = "blog_editor_delete";

    static BLOG_EDITOR_POST_CREATE = "blog_editor_post_create";
    static BLOG_EDITOR_POST_READ = "blog_editor_post_read";
    static BLOG_EDITOR_POST_UPDATE = "blog_editor_post_update";
    static BLOG_EDITOR_POST_DELETE = "blog_editor_post_delete";


    static EVENTS_MESSAGE_TYPE_CREATE = "events_message_type_create";
    static EVENTS_MESSAGE_TYPE_READ = "events_message_type_read";
    static EVENTS_MESSAGE_TYPE_UPDATE = "events_message_type_update";
    static EVENTS_MESSAGE_TYPE_DELETE = "events_message_type_delete";
}

export class RoleEnum {
    static WRITER = 'writer';
    static EDITOR = 'editor';
    static QUIZ_WRITER = 'quiz_writer';
}
