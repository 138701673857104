import React, {PureComponent, Component} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


class SuggestionInput extends Component {

    static propTypes = {
        suggestions: PropTypes.array.isRequired,
        onChange: PropTypes.func,
        value: PropTypes.string,
    };

    static defaultProps = {
        suggestions: [],
    };

    state = {
        suggestions: [],
        showSuggestions: false,
    };

    componentDidMount() {
        // console.log(this.props.suggestions);
        if (this.inputRef.id === document.activeElement.id) {
            // console.warn('show suggestions!!');
            this.setState({showSuggestions: true});
            this.handleSuggestionOnChange(this.inputRef.value);
        }
    }

    handleSuggestionOnChange = (value, showSuggestions=true) => {
        let suggestions = this.props.suggestions.filter(
            suggestion => (suggestion !== value && suggestion.includes(value) && showSuggestions)
        );
        // console.log('suggestions', suggestions);
        this.setState({suggestions});
        this.props.onChange(value);
    };

    onFocus = () => {
        // console.log('on click');
        this.setState({showSuggestions: true});
    };

    onBlur = () => {
        // console.log('on blur');
        setTimeout(() => this.setState({showSuggestions: false}), 300);
    };

    render() {

        const {value = '', onChange, suggestions, ...props} = this.props;

        return (
            <div className='suggestion-input-container'>
                <input {...props} className="suggestion-input"
                       type="text" value={value}
                       ref={ref => this.inputRef = ref}
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       autoFocus={true}
                       onChange={(e) => this.handleSuggestionOnChange(e.target.value)} />
                <div className="suggestion-list-container">
                    {
                        this.state.showSuggestions &&
                        this.state.suggestions.map(
                            (suggestion, index) => (
                                <div key={index} className="suggestion-list"
                                     onClick={() => this.handleSuggestionOnChange(suggestion)}>{suggestion}</div>
                            )
                        )
                    }
                </div>
            </div>
        )
    }
}


export default SuggestionInput;
