import {takeEvery} from 'redux-saga/effects';

import {
    fetchReportSheet,
    fetchReportSheets,
    createReportSheet,
    updateReportSheet,
    deleteReportSheet,
} from './reportsheets';
import {
    fetchRedFlag,
    fetchRedFlags,
    createRedFlag,
    updateRedFlag,
    deleteRedFlag,
} from './redflags';
import {
    fetchAnalysisScore,
    fetchAnalysisScores,
    createAnalysisScore,
    updateAnalysisScore,
    deleteAnalysisScore,
} from './analysisscores';
import {
    fetchAnalysisCard,
    fetchAnalysisCards,
    createAnalysisCard,
    updateAnalysisCard,
    deleteAnalysisCard,
} from './analysiscards';
import {
    fetchAnalysisCardText,
    fetchAnalysisCardTexts,
    createAnalysisCardText,
    updateAnalysisCardText,
    deleteAnalysisCardText,
} from './analysiscardtexts';
import {
    fetchReportSheetProduct,
    fetchReportSheetProducts,
    createReportSheetProduct,
    updateReportSheetProduct,
    deleteReportSheetProduct,
} from "./reportsheetproducts";

import {
    CREATE_REPORT_SHEET,
    DELETE_REPORT_SHEET,
    FETCH_REPORT_SHEET,
    FETCH_REPORT_SHEETS,
    UPDATE_REPORT_SHEET,
} from "../../actions/report/reportsheets";
import {
    CREATE_RED_FLAG,
    DELETE_RED_FLAG,
    FETCH_RED_FLAG,
    FETCH_RED_FLAGS,
    UPDATE_RED_FLAG,
} from "../../actions/report/redflags";
import {
    CREATE_ANALYSIS_SCORE,
    DELETE_ANALYSIS_SCORE,
    FETCH_ANALYSIS_SCORE,
    FETCH_ANALYSIS_SCORES,
    UPDATE_ANALYSIS_SCORE,
} from "../../actions/report/analysisscores";
import {
    CREATE_ANALYSIS_CARD,
    DELETE_ANALYSIS_CARD,
    FETCH_ANALYSIS_CARD,
    FETCH_ANALYSIS_CARDS,
    UPDATE_ANALYSIS_CARD,
} from "../../actions/report/analysiscards";

import {
    CREATE_ANALYSIS_CARD_TEXT,
    DELETE_ANALYSIS_CARD_TEXT,
    FETCH_ANALYSIS_CARD_TEXT,
    FETCH_ANALYSIS_CARD_TEXTS,
    UPDATE_ANALYSIS_CARD_TEXT,
} from "../../actions/report/analysiscardtexts";

import {
    FETCH_REPORT_SHEET_PRODUCT,
    FETCH_REPORT_SHEET_PRODUCTS,
    CREATE_REPORT_SHEET_PRODUCT,
    UPDATE_REPORT_SHEET_PRODUCT,
    DELETE_REPORT_SHEET_PRODUCT,
} from "../../actions/report/reportsheetproducts";


export default [
    takeEvery(FETCH_REPORT_SHEET, fetchReportSheet),
    takeEvery(FETCH_REPORT_SHEETS, fetchReportSheets),
    takeEvery(CREATE_REPORT_SHEET, createReportSheet),
    takeEvery(UPDATE_REPORT_SHEET, updateReportSheet),
    takeEvery(DELETE_REPORT_SHEET, deleteReportSheet),

    takeEvery(FETCH_RED_FLAG, fetchRedFlag),
    takeEvery(FETCH_RED_FLAGS, fetchRedFlags),
    takeEvery(CREATE_RED_FLAG, createRedFlag),
    takeEvery(UPDATE_RED_FLAG, updateRedFlag),
    takeEvery(DELETE_RED_FLAG, deleteRedFlag),

    takeEvery(FETCH_ANALYSIS_SCORE, fetchAnalysisScore),
    takeEvery(FETCH_ANALYSIS_SCORES, fetchAnalysisScores),
    takeEvery(CREATE_ANALYSIS_SCORE, createAnalysisScore),
    takeEvery(UPDATE_ANALYSIS_SCORE, updateAnalysisScore),
    takeEvery(DELETE_ANALYSIS_SCORE, deleteAnalysisScore),

    takeEvery(FETCH_ANALYSIS_CARD, fetchAnalysisCard),
    takeEvery(FETCH_ANALYSIS_CARDS, fetchAnalysisCards),
    takeEvery(CREATE_ANALYSIS_CARD, createAnalysisCard),
    takeEvery(UPDATE_ANALYSIS_CARD, updateAnalysisCard),
    takeEvery(DELETE_ANALYSIS_CARD, deleteAnalysisCard),

    takeEvery(FETCH_ANALYSIS_CARD_TEXT, fetchAnalysisCardText),
    takeEvery(FETCH_ANALYSIS_CARD_TEXTS, fetchAnalysisCardTexts),
    takeEvery(CREATE_ANALYSIS_CARD_TEXT, createAnalysisCardText),
    takeEvery(UPDATE_ANALYSIS_CARD_TEXT, updateAnalysisCardText),
    takeEvery(DELETE_ANALYSIS_CARD_TEXT, deleteAnalysisCardText),

    takeEvery(FETCH_REPORT_SHEET_PRODUCT, fetchReportSheetProduct),
    takeEvery(FETCH_REPORT_SHEET_PRODUCTS, fetchReportSheetProducts),
    takeEvery(CREATE_REPORT_SHEET_PRODUCT, createReportSheetProduct),
    takeEvery(UPDATE_REPORT_SHEET_PRODUCT, updateReportSheetProduct),
    takeEvery(DELETE_REPORT_SHEET_PRODUCT, deleteReportSheetProduct),
];
