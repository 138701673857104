// External Imports
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// My Imports
import {fieldTypes} from '../../../constants';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";


export default class HeightInput extends Component {


    static propTypes = {
        handleSendClick: PropTypes.func,
    };

    static defaultProps = {
        edit: true,
    };

    state = {
        feet: '',
        inches: '',
    };

    componentDidMount() {
    };

    onChangeText = (value) => {
        // console.warn(value);
        if (/^[0-9]*$/.test(value)) {
            this.setState({value});
        }
    };

    onChangeFeet = (feet) => {
        if (/^[3-7]?$/.test(feet)) {
            this.setState({feet});
        }
    };

    onChangeInches = (inches) => {
        if (/^[0-9]{0,2}$/.test(inches)) {
            this.setState({inches});
        }
    };

    handleSend = () => {
        let {feet, inches} = this.state;
        if (feet.length > 0 && inches.length > 0) {
            feet = parseInt(feet);
            inches = parseInt(inches);
            let cms = parseInt(((feet * 12) + inches) * 2.54);    // converting feet and inches to centimeters.
            this.props.handleSendClick({text: cms});
        }
    };

    render() {
        let {feet, inches} = this.state;
        return (
            <div className='height-input-container'>
                <Form.Group>
                    <Form.Control placeholder={'feet'}
                                  ref={ref => this.feetField = ref}
                                  value={this.state.feet}
                                  onChange={(e) => this.onChangeFeet(e.target.value)}
                                  type={'number'}
                                  onKeyPress={(event) => {
                                      if (event.key === "Enter") {
                                          this.inchesField.focus()
                                      }
                                  }}/>
                </Form.Group>
                <Form.Group>
                    <Form.Control placeholder={'inches'}
                                  ref={ref => this.inchesField = ref}
                                  value={this.state.inches}
                                  onChange={(e) => this.onChangeInches(e.target.value)}
                                  type={'number'}
                                  onKeyPress={(event) => {
                                      if (event.key === "Enter") {
                                          this.handleSend()
                                      }
                                  }}/>
                </Form.Group>
                {
                    (feet.length > 0 && inches.length > 0) &&
                    <div className="field-footer">
                        <div className="container d-flex justify-content-end">
                            <Button variant={'dark'} onClick={this.handleSend}>
                                Submit
                            </Button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
