import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem';
import SidebarHeader from './SidebarHeader';
import './styles.scss';
import {Route, withRouter} from "react-router";
import {checkPermissions, checkRoles} from "../../../permisions";
import {routeList} from "../../../screens/Home/routes";


class MainSidebar extends PureComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultProps = {
        active: false,
    };

    render() {
        const path = this.props.history.location.pathname;

        return (
            <div className={'sidebar ' + (this.props.active ? 'active' : '')}>
                <i onClick={this.props.toggleSidebar}
                   className={'material-icons sidebar-toggle ' + (this.props.active ? 'active' : '')}>keyboard_arrow_right</i>

                <ul className='sidebar-items'>
                    <SidebarHeader type={'Misters'}/>
                    <SidebarItem icon='home'
                                 type='Home'
                                 active={path === '/'}
                                 onClick={() => this.props.history.push('/')}/>

                    {
                        routeList.filter(r => checkPermissions(r.permissions)).filter(r => checkRoles(r.roles)).map(
                            (route, index) => <SidebarItem icon={route.icon} key={index}
                                                           type={route.name}
                                                           active={path === route.url}
                                                           onClick={() => this.props.history.push(route.url)}/>
                        )
                    }
                </ul>
            </div>
        )
    }
}

export default withRouter(MainSidebar);
