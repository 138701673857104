import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
    fetchBotField,
    updateBotField,
    createBotField,
    deleteBotField,
    copyBotField,
} from "../../../actions/quiz/botfields";
import {FIELD_LABELS, FIELD_TYPES} from "../../../constants";
import {getBotFieldFromId} from "../../../utils";
import {createBotFieldFile, deleteBotFieldFile} from "../../../actions/quiz/botfieldfiles";
import BotFieldFileItem from "../../items/BotFieldFileItem";
import ExpressionBuilder from "../ExpressionBuilder";
import {fetchBotForms} from "../../../actions/quiz/botforms";
import './styles.scss';
import ConfirmButton from "../../buttons/ConfirmButton";
import Spinner from "react-bootstrap/Spinner";
import QuillHelper from "../../others/QuillHelper";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";


class BotFieldEditCreateModal extends React.Component {

    static propTypes = {
        botfield: PropTypes.any,
        botforms: PropTypes.object,
        fetchBotForms: PropTypes.func,
        fetchBotField: PropTypes.func,
        createBotField: PropTypes.func,
        updateBotField: PropTypes.func,
        deleteBotField: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
        createBotFieldFile: PropTypes.func,
        deleteBotFieldFile: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            botfield: props.botfield,
            message: undefined,
            field_child: undefined,
            bot_form_id: props.match.params.botFormId,
            copyField: {
                bot_form_id: undefined,
                bot_field_id: props.botfield.id,
            },
            deletingImage: false,
            creatingImage: false,
        }
    }

    componentDidMount() {
        this.props.fetchBotForms();
        if (!this.state.botfield.field_parent_id) {
            let quill = new QuillHelper('#bot-field-text-editor', this.handleContentChange);
            this.setState({quill});
        }
        if (this.props.botfield.id) {
            this.props.fetchBotField(this.props.botfield);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {botfieldfiles, botfields: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;

        if (!isFetching && prevProps.botfields.isFetching && !error && !retry) {
            this.setState({botfield: detail});
            this.state.quill && this.state.quill.setHtmlText(detail[this.getBotFieldTextKey()]);
        }
        if (!isUpdating && prevProps.botfields.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.botfields.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.botfields.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!botfieldfiles.isCreating && prevProps.botfieldfiles.isCreating) {
            this.setState({creatingImage: false});
        }
        if (!botfieldfiles.isCreating && prevProps.botfieldfiles.isCreating && !botfieldfiles.error && !botfieldfiles.retry) {
            this.props.fetchBotField({id: botfieldfiles.detail.bot_fields[0]});
        }
        if (!botfieldfiles.isDeleting && prevProps.botfieldfiles.isDeleting) {
            this.setState({deletingImage: false});
        }
        if (!botfieldfiles.isDeleting && prevProps.botfieldfiles.isDeleting && !botfieldfiles.error && !botfieldfiles.retry) {
            this.props.fetchBotField({id: botfieldfiles.detail.bot_fields[0]});
        }
        if (!isCreating && prevProps.botfields.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.botfields.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleBotFieldChange = (botfield) => {
        this.setState({botfield});
    };

    getBotFieldTextKey = () => {
        let {language} = this.state;
        return  'text' + (language ? language.db_label : '');
    }

    getBotHelpFieldTextKey = () => {
        let {language} = this.state;
        return  'help_text' + (language ? language.db_label : '');
    }

    handleContentChange = () => {
        this.setState(prevState => ({
            botfield: {
                ...prevState.botfield,
                [this.getBotFieldTextKey()]: this.state.quill.getHtmlText()
            }
        }));
        console.warn(this.state.botfield.text, this.state.botfield.text_hi);
    };

    handleLanguageChange = (language) => {
        let {botfield} = this.state;
        this.setState({language}, () => {
            !!this.state.quill && this.state.quill.setHtmlText(botfield[this.getBotFieldTextKey()]);
        });
    };

    handleSaveBotField = () => {
        if (this.state.botfield.id) {
            this.props.updateBotField(this.state.botfield);
        } else {
            this.props.createBotField({...this.state.botfield});
        }
    };

    handleDeleteBotField = () => {
        if (this.state.botfield.id) {
            this.props.deleteBotField(this.state.botfield);
        }
    };

    handleBotFieldFileCreate = (e) => {
        let {botfield} = this.state;
        let {params} = this.props.match;
        if (botfield.id) {
            this.setState({creatingImage: true});
            let file = e.target.files[0];
            this.props.createBotFieldFile({
                file,
                file_type: 'image',
                name: 'bot',
                bot_field: botfield.id,
                bot_form: params.botFormId
            });
        }
    };

    handleBotFieldFileDelete = (file) => {
        this.setState({deletingImage: true});
        this.props.deleteBotFieldFile(file);
    };

    handleBotFieldCopy = () => {
        let {copyField} = this.state;
        this.props.copyBotField(copyField);
        this.props.handleClose();
    };

    render() {
        let {show, botfields, botforms, handleConfirm, handleClose} = this.props;
        let {botfield, bot_form_id, message, language, copyField, deletingImage, creatingImage} = this.state;

        let fieldTypeOption = [...FIELD_TYPES];
        let fieldOptionValue = undefined;
        if (botfield.field_parent_id) {
            let fieldParent = getBotFieldFromId({id: botfield.field_parent_id, fields: botfields.list});
            fieldOptionValue = {label: fieldParent.field_id, value: fieldParent.id};
            fieldTypeOption = fieldTypeOption.filter(t => t.label === 'Boolean');
        }
        let identifiers = botfields.list.map(o => [o.field_id, ...o.field_children.map(i => i.field_id)]);
        identifiers = [].concat(...identifiers) || [];

        let textKey = this.getBotFieldTextKey();
        let helpTextKey = this.getBotHelpFieldTextKey();

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{botfield.field_id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LanguageSelectInput onLanguageChange={this.handleLanguageChange}/>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Field Parent</Form.Label>
                        <Select options={botfields.list.map(o => ({label: o.field_id, value: o.id}))}
                                className="bot-field-item-select"
                                value={[fieldOptionValue]}
                                isClearable={true}
                                onChange={(obj) => {
                                    this.handleBotFieldChange({
                                        ...botfield,
                                        field_parent_id: obj ? obj.value : null,
                                    })
                                }}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.field_parent_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Field Id</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Field Id"
                                      value={botfield.field_id}
                                      isInvalid={!!message && !!message.field_id}
                                      onChange={(e) => this.handleBotFieldChange({
                                          ...botfield,
                                          field_id: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.field_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="row">
                        <div className={`col-12 ${fieldOptionValue && 'col-md-6'}`}>
                            <Form.Group>
                                <Form.Label>Type</Form.Label>
                                <Select options={fieldTypeOption}
                                        className="bot-field-item-select"
                                        value={[{
                                            label: FIELD_LABELS[botfield.type],
                                            value: botfield.type
                                        }]}
                                        onChange={(type) => this.handleBotFieldChange({
                                            ...botfield,
                                            type: type.value,
                                        })}/>
                                <Form.Control type="hidden"
                                              isInvalid={!!message && !!message.type}/>
                                <Form.Control.Feedback type="invalid">
                                    {!!message && message.type}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        {fieldOptionValue &&
                        <div className="col-12 col-md-6">
                            <Form.Group controlId="priority">
                                <Form.Label>Priority</Form.Label>
                                <Form.Control type="number"
                                              placeholder="Enter Priority"
                                              value={botfield.priority}
                                              isInvalid={!!message && !!message.priority}
                                              onChange={(e) => this.handleBotFieldChange({
                                                  ...botfield,
                                                  priority: e.target.value,
                                              })}/>
                                <Form.Control.Feedback type="invalid">
                                    {!!message && message.priority}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        }
                    </div>
                    <Form.Group controlId="fieldText">
                        <Form.Label>Value</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      disabled={true}
                                      value={botfield.value || ""}/>
                        <Button onClick={() => this.setState({showBuilder: true})}>Change Value</Button>
                        <ExpressionBuilder identifiers={identifiers}
                                           expression={botfield.value || ""}
                                           handleExpressionChange={(value => this.handleBotFieldChange({
                                               ...botfield,
                                               value
                                           }))}
                                           show={this.state.showBuilder}
                                           handleClose={() => this.setState({showBuilder: false})}/>
                    </Form.Group>
                    <Form.Group controlId="fieldText">
                        <Form.Label for="show_selection_percentage">Show selection percentage</Form.Label>
                        <Form.Check id="show_selection_percentage"
                                    checked={botfield.show_selection_percentage}
                                    onClick={() => this.handleBotFieldChange({
                                        ...botfield,
                                        show_selection_percentage: !botfield.show_selection_percentage,
                                    })}/>
                    </Form.Group>
                    <Form.Group controlId="fieldText">
                        <Form.Label for="selection_percentage">Selection percentage</Form.Label>
                        <Form.Control id="selection_percentage"
                                      value={botfield.selection_percentage || ''}
                                      onChange={(e) => this.handleBotFieldChange({
                                            ...botfield,
                                            selection_percentage: e.target.value,
                                      })}/>
                    </Form.Group>

                    <Form.Group controlId="fieldText">
                        <Form.Label>Text {!!language && `(${language.label})`}</Form.Label>
                        {
                            !botfield.field_parent_id &&
                            <div id="bot-field-text-editor"/>
                        }
                        {
                            !!botfield.field_parent_id &&
                            <React.Fragment>
                                <Form.Control as="textarea" rows="3"
                                              onChange={e => this.handleBotFieldChange({
                                                  ...botfield,
                                                  [textKey]: e.target.value,
                                              })}
                                              isInvalid={!!message && !!message[textKey]}
                                              value={botfield[textKey] || ''}/>
                                <Form.Control type="hidden"
                                              isInvalid={!!message && !!message[textKey]}/>
                                <Form.Control.Feedback type="invalid">
                                    {!!message && message[textKey]}
                                </Form.Control.Feedback>
                            </React.Fragment>
                        }
                    </Form.Group>
                    {
                        !botfield.field_parent_id &&
                        <Form.Group controlId="fieldText">
                            <Form.Label>Help Text {!!language && `(${language.label})`}</Form.Label>
                            <Form.Control as="textarea" rows="3"
                                          onChange={e => this.handleBotFieldChange({
                                              ...botfield,
                                              [helpTextKey]: e.target.value,
                                          })}
                                          isInvalid={!!message && !!message[helpTextKey]}
                                          value={botfield[helpTextKey] || ''}/>
                            <Form.Control type="hidden"
                                          isInvalid={!!message && !!message[helpTextKey]}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message[helpTextKey]}
                            </Form.Control.Feedback>
                        </Form.Group>
                    }
                    <Form.Group controlId="fieldText">
                        <Form.Label>Action</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      onChange={e => this.handleBotFieldChange({
                                          ...botfield,
                                          actions: e.target.value,
                                      })}
                                      isInvalid={!!message && !!message.actions}
                                      value={botfield.actions || ''}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.actions}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.actions}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldText">
                        <Form.Label>Web actions</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      onChange={e => this.handleBotFieldChange({
                                          ...botfield,
                                          web_actions: e.target.value,
                                      })}
                                      isInvalid={!!message && !!message.web_actions}
                                      value={botfield.web_actions || ''}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.web_actions}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.web_actions}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {
                        !!botfield.id &&
                        <Form.Group controlId="fieldText">
                            <Form.Label>Files</Form.Label>
                            <div>
                                {
                                    !!botfield.bot_field_files &&
                                    botfield.bot_field_files.map(
                                        file => <BotFieldFileItem key={file.id} isDeleting={deletingImage}
                                                                  image={file.file}
                                                                  onDeleteClick={() => this.handleBotFieldFileDelete(file)}/>
                                    )

                                }
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <Form.Control type="file" onChange={this.handleBotFieldFileCreate}/>
                                </div>
                                <div className="col-6">
                                    {creatingImage ? <Spinner size="sm" animation="border"/> : ''}
                                </div>
                            </div>
                        </Form.Group>
                    }
                    {
                        this.state.botfield.id &&
                        <Form.Group controlId="fieldId">
                            <Form.Label>Copy to Quiz</Form.Label>
                            <InputGroup>
                                <Select options={botforms.list.filter(o => o.id !== bot_form_id).map(o => ({
                                    label: o.name,
                                    value: o.id
                                }))}
                                        className="copy-field-bot-forms-list"
                                        value={botforms.list.filter(o => o.id === copyField.bot_form_id).map(o => ({
                                            label: o.name,
                                            value: o.id
                                        }))}
                                        onChange={(value) => {
                                            this.setState({
                                                copyField: {...copyField, bot_form_id: value.value},
                                            })
                                        }}/>
                                <Button onClick={this.handleBotFieldCopy}>Copy</Button>
                            </InputGroup>
                        </Form.Group>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveBotField}>Save</Button>
                    {/*{
                        this.state.botfield.id &&
                        <ConfirmButton onClick={this.handleDeleteBotField}/>
                    }*/}
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    botforms: state.quiz.botforms,
    botfields: state.quiz.botfields,
    botfieldfiles: state.quiz.botfieldfiles,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchBotForms,
    fetchBotField,
    updateBotField,
    createBotField,
    deleteBotField,
    copyBotField,
    createBotFieldFile,
    deleteBotFieldFile,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(BotFieldEditCreateModal));
