import React from 'react';
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import moment from 'moment';

import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import {bindActionCreators} from "redux";
import {fetchCoupons} from "../../actions/cms/coupons";
import Table from "react-bootstrap/Table";
import Icon from "../../components/others/Icon";
import CouponEditCreateModal from "../../components/modals/CouponEditCreateModal";


class Coupons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.props.fetchCoupons();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {coupons: {isCreating, isUpdating, isDeleting, error, retry}} = this.props;

        if ((!isCreating && prevProps.coupons.isCreating && !error && !retry) ||
            (!isUpdating && prevProps.coupons.isUpdating && !error && !retry) ||
            (!isDeleting && prevProps.coupons.isDeleting && !error && !retry)) {
            this.props.fetchCoupons();
        }
    }

    handleCreateCoupon = () => {
        this.setState({
            coupon: {}
        });
    };

    render() {
        let {coupons} = this.props;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Coupons'}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pt-4 text-right">
                            <Button variant="primary" size="sm"
                                    onClick={this.handleCreateCoupon}>Add Coupons</Button>
                        </div>
                        <div className="col-12 pt-3">
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Code</th>
                                    <th>Max Disc</th>
                                    <th>Use Count</th>
                                    <th>Disc Perc</th>
                                    <th>Disc Amount</th>
                                    <th>Expire</th>
                                    <th>Visible</th>
                                    <th>Active</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    coupons.list.map(
                                        (coupon, index) => (
                                            <tr key={coupon.id}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {coupon.code}
                                                </td>
                                                <td>
                                                    {coupon.max_discount}
                                                </td>
                                                <td>
                                                    {coupon.use_count}
                                                </td>
                                                <td>
                                                    {coupon.discount_percentage}
                                                </td>
                                                <td>
                                                    {coupon.discount_amount}
                                                </td>
                                                <td>
                                                    {!!coupon.expiry_date &&
                                                    moment(coupon.expiry_date).format('DD MMM YYYY')
                                                    }
                                                </td>
                                                <td>
                                                    <Icon
                                                        name={coupon.is_visible ? 'check_circle_outline' : 'highlight_off'}
                                                        color={coupon.is_visible ? '#00a700' : '#F00'}/>
                                                </td>
                                                <td>
                                                    <Icon
                                                        name={coupon.active ? 'check_circle_outline' : 'highlight_off'}
                                                        color={coupon.active ? '#00a700' : '#F00'}/>
                                                </td>
                                                <td>
                                                    <Button onClick={() => this.setState({coupon})}
                                                            variant="success" size="sm">
                                                        Edit
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        {
                            !!this.state.coupon &&
                            <CouponEditCreateModal show={true}
                                                   coupon={this.state.coupon}
                                                   handleClose={() => this.setState({coupon: undefined})}/>
                        }
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}


const mapStateToProps = (state) => ({
    coupons: state.cms.coupons,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCoupons,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Coupons);
