import {call, put} from 'redux-saga/effects';
import api from "../api";
import {toast} from "react-toastify";

import {
    CREATE_COUPON_RESULT,
    CREATE_COUPON_ERROR,
    CREATE_COUPON_RETRY,

    FETCH_COUPONS_RESULT,
    FETCH_COUPONS_ERROR,
    FETCH_COUPONS_RETRY,

    FETCH_COUPON_RESULT,
    FETCH_COUPON_ERROR,
    FETCH_COUPON_RETRY,

    UPDATE_COUPON_RESULT,
    UPDATE_COUPON_ERROR,
    UPDATE_COUPON_RETRY,

    DELETE_COUPON_RESULT,
    DELETE_COUPON_ERROR,
    DELETE_COUPON_RETRY,
} from '../../actions/cms/coupons';


export function* createCoupon(action) {
    try {
        const response = yield call(api.cms.coupons.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createCoupon', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_COUPON_RESULT, data: result});
        }
        else {
            if (result.message) {
                toast(result.message, );
            }
            yield put({type: CREATE_COUPON_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_COUPON_RETRY, retry: true, message: e.message});
    }
}


export function* fetchCoupons(action) {
    try {
        const response = yield call(api.cms.coupons.list);
        const result = yield response.json();
        console.warn('SAGA:fetchCoupons', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_COUPONS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_COUPONS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_COUPONS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchCoupon(action) {
    try {
        const response = yield call(api.cms.coupons.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchCoupon', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_COUPON_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_COUPON_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_COUPON_RETRY, retry: true, message: e.message});
    }
}


export function* updateCoupon(action) {
    try {
        const response = yield call(api.cms.coupons.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateCoupon', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_COUPON_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_COUPON_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_COUPON_RETRY, retry: true, message: e.message});
    }
}


export function* deleteCoupon(action) {
    try {
        const response = yield call(api.cms.coupons.remove, action.data);
        console.warn('SAGA:deleteCoupon', action);

        if (response.status === 204) {
            yield put({type: DELETE_COUPON_RESULT});
        }
        else {
            yield put({type: DELETE_COUPON_ERROR, error: true});
        }
    } catch (e) {
        yield put({type: DELETE_COUPON_RETRY, retry: true, message: e.message});
    }
}
