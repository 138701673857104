import React from 'react';
import Button from "react-bootstrap/Button";
import {getHttpsImageUrl} from "../../../utils";
import './styles.scss';
import * as PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";


export default class BotFieldFileItem extends React.Component {

    static propTypes = {
        image: PropTypes.string,
        onDeleteClick: PropTypes.func,
        isDeleting: PropTypes.bool,
    };

    static defaultProps = {
        image: '',
        isDeleting: false,
    };

    render() {
        let {image, onDeleteClick, isDeleting} = this.props;
        return (
            <div className="bot-field-file-item">
                <Button onClick={onDeleteClick}>
                    {isDeleting ?
                        <Spinner size="sm" animation="border"/> :
                        <i className="fa fa-times"/>
                    }
                </Button>
                <img src={getHttpsImageUrl(image)} alt=""/>
            </div>
        );
    }
}
