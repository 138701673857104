import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {
    createProductQuantityOffer,
    fetchProductQuantityOffer,
    updateProductQuantityOffer,
    deleteProductQuantityOffer
} from "../../../actions/cms/productquantityoffers";
import ConfirmButton from "../../buttons/ConfirmButton";
import Select from "react-select";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";


class ProductQuantityOfferEditCreateModal extends React.Component {

    static propTypes = {
        productOffer: PropTypes.any,
        createProductQuantityOffer: PropTypes.func,
        fetchProductQuantityOffer: PropTypes.func,
        updateProductQuantityOffer: PropTypes.func,
        deleteProductQuantityOffer: PropTypes.func,
        products: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            productOffer: props.productOffer,
            message: undefined,
        }
    }

    componentDidMount() {
        if (this.props.productOffer.id) {
            this.props.fetchProductQuantityOffer(this.props.productOffer);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {productquantityoffers: {detail, isFetching, isCreating, isDeleting, isUpdating, message, error, retry}} = this.props;

        if (!isFetching && prevProps.productquantityoffers.isFetching && !error && !retry) {
            this.setState({productOffer: detail});
        }
        if ((!isUpdating && prevProps.productquantityoffers.isUpdating && !error && !retry) ||
            (!isCreating && prevProps.productquantityoffers.isCreating && !error && !retry) ||
            (!isDeleting && prevProps.productquantityoffers.isDeleting && !error && !retry)) {
            this.props.handleClose();
        }

        if ((!isCreating && prevProps.productquantityoffers.isCreating && error && !retry) ||
            (!isUpdating && prevProps.productquantityoffers.isUpdating && error && !retry)) {
            this.setState({message});
        }

    }

    handleSaveProductOffer = () => {
        if (this.state.productOffer.id) {
            this.props.updateProductQuantityOffer(this.state.productOffer);
        } else {
            this.props.createProductQuantityOffer(this.state.productOffer);
        }
    };

    handleDeleteProductOffer = () => {
        if (this.state.productOffer.id) {
            this.props.deleteProductQuantityOffer(this.state.productOffer);
        }
    };

    handleProductOfferChange = (productOffer) => {
        this.setState({productOffer});
    };

    handleLanguageChange = (language) => {
        this.setState({language});
    };

    render() {
        let {show, products, handleClose} = this.props;
        let {productOffer, message, language} = this.state;

        let textKey = 'text' + (language ? language.db_label : '');

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Quantity Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LanguageSelectInput onLanguageChange={this.handleLanguageChange}/>
                    <Form.Group>
                        <Form.Label>Product</Form.Label>
                        <Select options={products.list.map(p => ({label: `${p.product_id} - ${p.title}`, value: p.id}))}
                                className="bot-field-item-select" isDisabled
                                value={products.list.map(p => ({
                                    label: p.product_id,
                                    value: p.id
                                })).filter(p => p.value == productOffer.product)}
                                onChange={(obj) => this.handleProductOfferChange({
                                    ...productOffer,
                                    product: obj.value,
                                })}/>
                        <Form.Control type="hidden"
                                      isInvalid={!!message && !!message.product}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.product}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control type="text"
                                      value={productOffer.quantity || ''}
                                      onChange={(e) => this.handleProductOfferChange({
                                          ...productOffer,
                                          quantity: e.target.value
                                      })}
                                      isInvalid={!!message && !!message.quantity}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.quantity}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Price</Form.Label>
                        <Form.Control type="text"
                                      value={productOffer.price || ''}
                                      onChange={(e) => this.handleProductOfferChange({
                                          ...productOffer,
                                          price: e.target.value
                                      })}
                                      isInvalid={!!message && !!message.price}/>
                        <Form.Text className="text-muted">in paisa</Form.Text>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.price}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Text</Form.Label>
                        <Form.Control as="textarea" rows="2"
                                      value={productOffer[textKey] || ''}
                                      onChange={(e) => this.handleProductOfferChange({
                                          ...productOffer,
                                          [textKey]: e.target.value
                                      })}
                                      isInvalid={!!message && !!message[textKey]}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message[textKey]}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveProductOffer}>Save</Button>
                    {
                        this.state.productOffer.id &&
                        <ConfirmButton onClick={this.handleDeleteProductOffer}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    products: state.quiz.products,
    productquantityoffers: state.cms.productquantityoffers,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createProductQuantityOffer,
    fetchProductQuantityOffer,
    updateProductQuantityOffer,
    deleteProductQuantityOffer,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ProductQuantityOfferEditCreateModal);
