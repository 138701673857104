//External Imports
import {combineReducers} from 'redux';
//My Imports
import reviews from './reviews';
import coupons from './coupons';
import productquantityoffers from './productquantityoffers';


export default combineReducers({
    reviews,
    coupons,
    productquantityoffers,
});
