import {takeEvery} from 'redux-saga/effects';

import {
    createMessageType, fetchMessageTypes, fetchMessageType, updateMessageType, deleteMessageType
} from './messagetypes';

import {
    CREATE_MESSAGE_TYPE,
    DELETE_MESSAGE_TYPE,
    FETCH_MESSAGE_TYPE,
    FETCH_MESSAGE_TYPES,
    UPDATE_MESSAGE_TYPE
} from "../../actions/events/messagetypes";

export default [
    takeEvery(CREATE_MESSAGE_TYPE, createMessageType),
    takeEvery(FETCH_MESSAGE_TYPES, fetchMessageTypes),
    takeEvery(FETCH_MESSAGE_TYPE, fetchMessageType),
    takeEvery(UPDATE_MESSAGE_TYPE, updateMessageType),
    takeEvery(DELETE_MESSAGE_TYPE, deleteMessageType),
];
