import {call, put} from 'redux-saga/effects';
import {
    CREATE_ANALYSIS_CARD_RESULT,
    CREATE_ANALYSIS_CARD_ERROR,
    CREATE_ANALYSIS_CARD_RETRY,

    FETCH_ANALYSIS_CARDS_RESULT,
    FETCH_ANALYSIS_CARDS_ERROR,
    FETCH_ANALYSIS_CARDS_RETRY,

    FETCH_ANALYSIS_CARD_RESULT,
    FETCH_ANALYSIS_CARD_ERROR,
    FETCH_ANALYSIS_CARD_RETRY,

    UPDATE_ANALYSIS_CARD_RESULT,
    UPDATE_ANALYSIS_CARD_ERROR,
    UPDATE_ANALYSIS_CARD_RETRY,

    DELETE_ANALYSIS_CARD_RESULT,
    DELETE_ANALYSIS_CARD_ERROR,
    DELETE_ANALYSIS_CARD_RETRY,
} from '../../actions/report/analysiscards';
import api from "../api";
import {toast} from "react-toastify";


export function* createAnalysisCard(action) {
    try {
        const response = yield call(api.report.analysiscards.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createAnalysisCard', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_ANALYSIS_CARD_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_ANALYSIS_CARD_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_ANALYSIS_CARD_RETRY, retry: true, message: e.message});
    }
}


export function* fetchAnalysisCards(action) {
    try {
        const response = yield call(api.report.analysiscards.list, action.data || {});
        const result = yield response.json();
        console.warn('SAGA:fetchAnalysisCards', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ANALYSIS_CARDS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_ANALYSIS_CARDS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ANALYSIS_CARDS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchAnalysisCard(action) {
    try {
        const response = yield call(api.report.analysiscards.get, action.data);
        const result = yield response.json();
        // console.warn('SAGA:fetchAnalysisCard', action, result);
        if (response.status === 200) {
            yield put({type: FETCH_ANALYSIS_CARD_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_ANALYSIS_CARD_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ANALYSIS_CARD_RETRY, retry: true, message: e.message});
    }
}


export function* updateAnalysisCard(action) {
    try {
        const response = yield call(api.report.analysiscards.update, action.data);
        const result = yield response.json();
        // console.warn('SAGA:updateAnalysisCard', action, result);
        if (response.status === 200) {
            yield put({type: UPDATE_ANALYSIS_CARD_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_ANALYSIS_CARD_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_ANALYSIS_CARD_RETRY, retry: true, message: e.message});
    }
}


export function* deleteAnalysisCard(action) {
    try {
        const response = yield call(api.report.analysiscards.remove, action.data);
        // const result = yield response.json();
        // console.warn('SAGA:deleteAnalysisCard', action);

        if (response.status === 204) {
            yield put({type: DELETE_ANALYSIS_CARD_RESULT, data: action.data});
        }
        else {
            yield put({type: DELETE_ANALYSIS_CARD_ERROR, error: true, message: 'something went wrong'});
        }
    } catch (e) {
        yield put({type: DELETE_ANALYSIS_CARD_RETRY, retry: true, message: e.message});
    }
}
