import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {fetchAnalysisScore, updateAnalysisScore, createAnalysisScore, deleteAnalysisScore} from "../../../actions/report/analysisscores";
import {connect} from "react-redux";
import ExpressionBuilder from "../ExpressionBuilder";
import Select from "react-select";
import {analysisCardType} from "../../../constants";
import ConfirmButton from "../../buttons/ConfirmButton";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";


class AnalysisScoreEditCreateModal extends React.Component {

    static propTypes = {
        analysisscore: PropTypes.any,
        analysisscores: PropTypes.any,
        createAnalysisScore: PropTypes.func,
        fetchAnalysisScore: PropTypes.func,
        updateAnalysisScore: PropTypes.func,
        deleteAnalysisScore: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            analysisscore: props.analysisscore,
            message: undefined,
            field_child: undefined,
            showBuilder: false,
        }
    }

    componentDidMount() {
        if (this.props.analysisscore.id) {
            this.props.fetchAnalysisScore(this.props.analysisscore);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {analysisscores: {detail, isFetching, isCreating, isUpdating, isDeleting, message, error, retry}} = this.props;

        if (!isFetching && prevProps.analysisscores.isFetching && !error && !retry) {
            this.setState({analysisscore: detail});
        }
        if (!isUpdating && prevProps.analysisscores.isUpdating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isDeleting && prevProps.analysisscores.isDeleting && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.analysisscores.isCreating && !error && !retry) {
            this.props.handleClose();
        }
        if (!isCreating && prevProps.analysisscores.isCreating && error && !retry) {
            this.setState({message});
        }
        if (!isUpdating && prevProps.analysisscores.isUpdating && error && !retry) {
            this.setState({message});
        }
    }

    handleAnalysisScoreChange = (analysisscore) => {
        this.setState({analysisscore});
    };

    handleSaveAnalysisScore = () => {
        if (this.state.analysisscore.id) {
            this.props.updateAnalysisScore(this.state.analysisscore);
        } else {
            this.props.createAnalysisScore({...this.state.analysisscore});
        }
    };

    handleDeleteAnalysisScore = () => {
        if (this.state.analysisscore.id) {
            this.props.deleteAnalysisScore(this.state.analysisscore);
        }
    };

    handleLanguageChange = (language) => {
        this.setState({language});
    };

    render() {
        let {show, botfields, reportsheets, handleClose} = this.props;
        let {analysisscore, message, language} = this.state;
        let identifiers = botfields.list.map(o => [o.field_id, ...o.field_children.map(i => i.field_id)]);
        identifiers = [].concat(...identifiers) || [];

        let nameKey = 'name' + (language ? language.db_label: '');

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>{analysisscore.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LanguageSelectInput onLanguageChange={this.handleLanguageChange}/>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Name"
                                      value={analysisscore[nameKey] || ''}
                                      isInvalid={!!message && !!message[nameKey]}
                                      onChange={(e) => this.handleAnalysisScoreChange({
                                          ...analysisscore,
                                          [nameKey]: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message[nameKey]}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Report Sheet</Form.Label>
                        <Select options={reportsheets.list.map(o => ({label: o.name, value: o.id}))}
                                value={reportsheets.list.filter(o => o.id == analysisscore.report_sheet).map(o => ({label: o.name, value: o.id}))}
                                onChange={(o) => {
                                    this.handleAnalysisScoreChange({
                                        ...analysisscore,
                                        report_sheet: o.value,
                                    })
                                }}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.components}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="expression">
                        <Form.Label>Expression</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      disabled={true}
                                      value={analysisscore.expression || ""}/>
                        <Button onClick={() => this.setState({showBuilder: true})}>Change Value</Button>
                        <ExpressionBuilder identifiers={identifiers}
                                           expression={analysisscore.expression || ""}
                                           handleExpressionChange={(expression => this.handleAnalysisScoreChange({
                                               ...analysisscore,
                                               expression
                                           }))}
                                           show={this.state.showBuilder}
                                           handleClose={() => this.setState({showBuilder: false})}/>
                    </Form.Group>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Type</Form.Label>
                        <Select options={analysisCardType}
                                value={[{label: analysisscore.type, value: analysisscore.type}]}
                                onChange={(o) => {
                                    this.handleAnalysisScoreChange({
                                        ...analysisscore,
                                        type: o.value,
                                    })
                                }}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.type}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="fieldId">
                        <Form.Label>Total Score</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Score"
                                      value={analysisscore.total_score}
                                      isInvalid={!!message && !!message.total_score}
                                      onChange={(e) => this.handleAnalysisScoreChange({
                                          ...analysisscore,
                                          total_score: e.target.value,
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.total_score}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={this.handleSaveAnalysisScore}>Save</Button>
                    {
                        this.state.analysisscore.id &&
                        <ConfirmButton onClick={this.handleDeleteAnalysisScore}/>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    analysisscores: state.report.analysisscores,
    reportsheets: state.report.reportsheets,
    botfields: state.quiz.botfields,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createAnalysisScore,
    fetchAnalysisScore,
    updateAnalysisScore,
    deleteAnalysisScore,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(AnalysisScoreEditCreateModal);
