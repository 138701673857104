import {
    CREATE_BOT_FORM,
    CREATE_BOT_FORM_RESULT,
    CREATE_BOT_FORM_ERROR,
    CREATE_BOT_FORM_RETRY,

    FETCH_BOT_FORMS,
    FETCH_BOT_FORMS_RESULT,
    FETCH_BOT_FORMS_ERROR,
    FETCH_BOT_FORMS_RETRY,

    DELETE_BOT_FORM,
    DELETE_BOT_FORM_ERROR,
    DELETE_BOT_FORM_RESULT,
    DELETE_BOT_FORM_RETRY,

    FETCH_BOT_FORM,
    FETCH_BOT_FORM_ERROR,
    FETCH_BOT_FORM_RESULT,
    FETCH_BOT_FORM_RETRY,

    UPDATE_BOT_FORM,
    UPDATE_BOT_FORM_ERROR,
    UPDATE_BOT_FORM_RESULT,
    UPDATE_BOT_FORM_RETRY, CLONE_BOT_FORM, CLONE_BOT_FORM_RESULT, CLONE_BOT_FORM_ERROR, CLONE_BOT_FORM_RETRY
} from '../../actions/quiz/botforms';


const initialState = {
    detail: {},
    list: [],
    isCreating: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLONE_BOT_FORM:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CLONE_BOT_FORM_RESULT:
            return {...state, isCreating: false};
        case CLONE_BOT_FORM_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CLONE_BOT_FORM_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case CREATE_BOT_FORM:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_BOT_FORM_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_BOT_FORM_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_BOT_FORM_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_BOT_FORMS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_BOT_FORMS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_BOT_FORMS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_BOT_FORMS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_BOT_FORM:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_BOT_FORM_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_BOT_FORM_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_BOT_FORM_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_BOT_FORM:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_BOT_FORM_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_BOT_FORM_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_BOT_FORM_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_BOT_FORM:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_BOT_FORM_RESULT:
            return {...state, isDeleting: false};
        case DELETE_BOT_FORM_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_BOT_FORM_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
