import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";

import './styles.scss';
import {fetchReportSheets} from "../../actions/report/reportsheets";
import ReportSheetEditCreateModal from "../../components/modals/ReportSheetEditCreateModal";
import RedFlagEditCreateModal from "../../components/modals/RedFlagEditCreateModal";


class ReportSheets extends React.Component {

    static propTypes = {
        botfields: PropTypes.object,
        reportsheets: PropTypes.object,
        fetchReportSheets: PropTypes.func,
    };

    state = {
        reportsheet: undefined,
        redflag: undefined,
    };

    componentDidMount() {
        let {params} = this.props.match;
        this.props.fetchReportSheets({bot_form_id: params.botFormId});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleCreateExpression = () => {
        let {params} = this.props.match;
        this.setState({
            reportsheet: {
                name: '',
                bot_form: params.botFormId,
                analysiscards: [],
                analysisscores: [],
                redflags: []
            }
        });
    };

    render() {
        let {reportsheets} = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 pt-2 text-right">
                        <Button size={'sm'}
                                onClick={this.handleCreateExpression}>Create Report Sheet</Button>
                    </div>
                    <div className="col-12 pt-3">
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                reportsheets.list.map(
                                    (reportsheet, index) => (
                                        <tr key={reportsheet.id}>
                                            <td>{index + 1}</td>
                                            <td>{reportsheet.name}</td>
                                            <td>
                                                <Button size={'sm'}
                                                        onClick={() => this.setState({reportsheet})}>edit</Button>
                                            </td>
                                        </tr>
                                    )
                                )
                            }
                            </tbody>
                        </Table>
                    </div>
                    {
                        !!this.state.reportsheet &&
                        <ReportSheetEditCreateModal show={true}
                                                    reportsheet={this.state.reportsheet}
                                                    handleClose={() => this.setState({reportsheet: undefined})}/>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportsheets: state.report.reportsheets,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchReportSheets,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(ReportSheets));
