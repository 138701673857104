import React, {PureComponent} from 'react';
import Button from "react-bootstrap/Button";

import './styles.scss';


export default class BotFieldItemHeader extends PureComponent {

    static propTypes = {};

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <div className="bot-field-item bot-field-item-header">
                <div className="bot-field-item-num">#</div>
                <div className="bot-field-item-field-id-container">Field ID</div>
                <div className="bot-field-item-type">Type </div>
                <div className="bot-field-item-text">Text</div>
                {/*<td>{botfield.field_children.length}</td>*/}
                <div className="bot-field-item-predecessors">Predecessors</div>
                <div className="bot-field-item-successors">Successors</div>
                <div className="bot-field-item-height">Height</div>
                <div className="bot-field-item-actions">Actions</div>
            </div>
        );
    }
}
