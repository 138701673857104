import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchBotForms, createBotForm, cloneBotForm} from "../../actions/quiz/botforms";
import './styles.scss';
import Icon from "../../components/others/Icon";
import {NavLink} from "react-router-dom";
import BotFormEditCreateModal from "../../components/modals/BotFormEditCreateModal";


class Bot extends React.Component {

    static propTypes = {
        botforms: PropTypes.object,
        fetchBotForms: PropTypes.func,
        cloneBotForm: PropTypes.func,
    };

    state = {
        botform: undefined,
    };

    componentDidMount() {
        this.props.fetchBotForms();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleCreateQuiz = () => {
        this.setState({botform: {name: ''}});
        // this.props.createBotForm({name: });
    };

    cloneQuiz = (botform_id) => {
        this.props.cloneBotForm({id: botform_id});
    };

    render() {
        let {botforms} = this.props;
        // console.warn(list);

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Quiz Editor'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pt-2 text-right">
                            <Button size={'sm'} onClick={this.handleCreateQuiz}>Create Quiz</Button>
                        </div>
                        <div className="col-12 pt-3">
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Website URL</th>
                                    <th>Active</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    botforms.list.map(
                                        (botform, index) => (
                                            <tr key={botform.id}>
                                                <td>{index + 1}</td>
                                                <td><NavLink to={`/bot/${botform.id}/edit/`}>{botform.name}</NavLink></td>
                                                <td><a target="_blank"
                                                       href={`https://misters.in/en/quiz/custom/?bot_form_id=${botform.bot_form_id}&field_id=${botform.bot_field_id ? botform.bot_field_id: ''}`}>
                                                    {`https://misters.in/en/quiz/custom/?bot_form_id=${botform.bot_form_id}&field_id=${botform.bot_field_id ? botform.bot_field_id: ''}`}
                                                </a></td>
                                                <td>
                                                    <Icon name={botform.active ? 'check_circle_outline': 'highlight_off'}
                                                          color={botform.active ? '#00a700': '#F00'}/>
                                                </td>
                                                <td>
                                                    <Button onClick={() => this.cloneQuiz(botform.id)}  block>
                                                        {botforms.isCreating ? 'Loading': 'clone'}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    botforms: state.quiz.botforms,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchBotForms,
    createBotForm,
    cloneBotForm,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Bot);
